import React, { useState, useEffect, useRef } from "react";
import {
  collection,
  query,
  doc,
  getDoc,
  getDocs,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useParams } from "react-router-dom";
import Player from "../../components/StoryPlayer/Player";

export default function Iframe() {
  const { productId } = useParams();
  const [product, setProduct] = useState(null);
  const [brand, setBrand] = useState(null);
  const [creatorVideos, setCreatorVideos] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [playerOpen, setPlayerOpen] = useState(false);
  const [currentPlay, setCurrentPlay] = useState(0);
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const productRef = doc(db, "products", productId);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) {
          const productItem = productSnap.data();
          setProduct(productItem);

          const brandRef = doc(db, "brands", productItem.brandId);
          const brandSnap = await getDoc(brandRef);

          if (brandSnap.exists()) {
            setBrand({ ...brandSnap.data(), id: brandSnap.id });
          }

          const videoQuery = query(
            collection(db, "videos"),
            where("is_confirmed", "==", true),
            where("productId", "==", productId)
          );
          const videoSnapshot = await getDocs(videoQuery);
          const videoList = [];
          videoSnapshot.forEach((doc) => {
            videoList.push({ ...doc.data(), id: doc.id });
          });
          setCreatorVideos(videoList);
        }
      }
    }
    getData();
  }, []);

  const handleVideoPlay = (item, playList) => {
    setCurrentPlay(item);
    setPlayerList(playList);
    setPlayerOpen(true);
  };

  const CreatorVideos = () => {
    return (
      creatorVideos.length > 0 && (
        <div>
          <div className="flow-root">
            <div>
              <div className="box-content relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl flex space-x-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {creatorVideos.map((item, key) => (
                    <div
                      key={key}
                      className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                      onClick={() => handleVideoPlay(item, creatorVideos)}
                    >
                      <span aria-hidden="true" className="absolute inset-0">
                        <img
                          src={item.media.image.url}
                          className="w-full h-full object-center object-cover"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    product &&
    brand && (
      <>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-8 lg:max-w-none">
            <div>
              <CreatorVideos />

              <Player
                open={playerOpen}
                setOpen={setPlayerOpen}
                playerList={playerList}
                setPlayerList={setPlayerList}
                currentPlay={currentPlay}
                setCurrentPlay={setCurrentPlay}
              />
            </div>

          </div>
        </div>
      </>
    )
  );
}
