import { useState } from "react";
import { classNames } from "../../utils";

export default function ProductScrollable({ products, brands }) {

  const LoaderCard = () => {
    return (
      <div className="max-w-7xl">
        <div>
          <div className="animate-pulse rounded-lg overflow-hidden">
            <div className="space-y-2">
              <div className="h-80 bg-gray-400 rounded"></div>
              <div className="h-4 bg-gray-400 rounded w-3/6"></div>
              <div className="h-4 bg-gray-400 rounded w-6/6"></div>
              <div className="h-4 bg-gray-400 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const fixProductName = (name) => {
    return String(name).length > 50
      ? `${String(name).substring(0, 51)} ...`
      : name;
  };

  const getBrandName = (brandId) => {
    const brand = brands.find((x) => x.id === brandId);
    return brand ? brand.name : "";
  };

  const RenderProductItem = ({ product, imageLoader, setImageLoader }) => {
    return (
      <li
        key={product.id}
        className="w-64 inline-flex flex-col text-center lg:w-auto"
      >
        <div className="group relative">
          {product.media && product.media.images && product.media.images.length > 0 && (
            <div className="w-full bg-gray-200 rounded-md overflow-hidden aspect-w-1 aspect-h-1">
              <img
                src={product.media.images[0].url}
                alt={product.description}
                onLoad={() => setImageLoader(false)}
                className={classNames(
                  "w-full h-full object-center group-hover:opacity-75",
                  imageLoader ? "hidden" : "visible"
                )}
              />
            </div>
          )}
          <div
            className={classNames(
              "animate-pulse rounded-lg overflow-hidden",
              imageLoader ? "visible" : "hidden"
            )}
          >
            <div className="h-80 bg-gray-400 rounded"></div>
          </div>

          <div className="mt-6">
            <a
              href={`brands/${product.brandId}`}
              className="text-sm text-gray-500"
            >
              {getBrandName(product.brandId)}
            </a>
            <h3 className="mt-1 font-semibold text-gray-900">
              <a href={product.baseLink}>
                <span className="absolute inset-0" />
                {fixProductName(product.name)}
              </a>
            </h3>
          </div>
        </div>
      </li>
    );
  };

  const RenderProduct = ({ product }) => {
    const [imageLoader, setImageLoader] = useState(true);

    return (
      <>
        <RenderProductItem
          product={product}
          imageLoader={imageLoader}
          setImageLoader={setImageLoader}
        />
        {imageLoader && <LoaderCard />}
      </>
    );
  };

  const RenderProductList = () => {
    return (
      <div className="mt-8 relative">
        <div className="relative w-full pb-6 -mb-6 overflow-x-auto">
          <ul
            role="list"
            className="mx-4 inline-flex space-x-8 sm:mx-6 lg:mx-0 lg:space-x-0 lg:grid lg:grid-cols-4 lg:gap-x-8"
          >
            {products.map((product, key) => (
              <RenderProduct product={product} key={key} />
            ))}
          </ul>
        </div>
      </div>
    );
  };

  return RenderProductList();
}
