import React, { useEffect, useRef, useState } from "react";
import { collection, query, getDocs, orderBy, where } from "firebase/firestore";
import { db } from "../../../firebase";
import AdminLayout from "../../../layouts/AdminLayout";
import History from "./History";
import Modal from "./Modal";

const Videos = () => {
  const [model, setModel] = useState();
  const [open, setOpen] = useState();
  const [data, setData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [products, setProducts] = useState([]);
  const [users, setUsers] = useState([]);
  const [events, setEvents] = useState([]);
  const [currentFilter, setCurrentFilter] = useState("PENDING");
  const pending = useRef(true);

  const filterOptions = [
    { id: "PENDING", title: "Pendings" },
    { id: "CONFIRMED", title: "Confirmeds" },
    { id: "ALL", title: "All" },
  ];

  const getVideos = async (_filter) => {
    const dynamicQuery = [];
    if (_filter != "ALL") {
      dynamicQuery.push(where("is_confirmed", "==", _filter === "CONFIRMED"));
    }

    const videosQuery = query(
      collection(db, "videos"),
      ...dynamicQuery,
      orderBy("created_at", "desc")
    );
    const videosSnapshot = await getDocs(videosQuery);
    const videosList = [];
    let rowNumber = 0;
    videosSnapshot.forEach((doc) => {
      rowNumber += 1;
      videosList.push({ ...doc.data(), id: doc.id, rowNumber });
    });

    const usersQuery = query(collection(db, "users"));
    const usersSnapshot = await getDocs(usersQuery);
    const usersList = [];
    usersSnapshot.forEach((doc) => {
      usersList.push({ ...doc.data(), id: doc.id });
    });

    const productQuery = query(collection(db, "products"));
    const productSnapshot = await getDocs(productQuery);
    const productList = [];
    productSnapshot.forEach((doc) => {
      productList.push({ ...doc.data(), id: doc.id });
    });

    const brandQuery = query(collection(db, "brands"));
    const brandSnapshot = await getDocs(brandQuery);
    const brandList = [];
    brandSnapshot.forEach((doc) => {
      brandList.push({ ...doc.data(), id: doc.id });
    });

    const eventQuery = query(collection(db, "events"));
    const eventSnapshot = await getDocs(eventQuery);
    const eventList = [];
    eventSnapshot.forEach((doc) => {
      eventList.push({ ...doc.data(), id: doc.id });
    });

    setData(videosList);
    setUsers(usersList);
    setProducts(productList);
    setBrands(brandList);
    setEvents(eventList);
  };

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        getVideos(currentFilter);
      }
    }
    getData();
  }, []);

  const handleCurrentFilterChange = (item) => {
    setCurrentFilter(item);
    getVideos(item);
  };

  return (
    <AdminLayout>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto items-center">
          <h1 className="text-2xl font-semibold text-gray-900">{`Videos (${data.length})`}</h1>
        </div>
        <div className="mt-4">
          <fieldset className="mt-4">
            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
              {filterOptions.map((item) => (
                <div key={item.id} className="flex items-center">
                  <input
                    id={item.id}
                    type="radio"
                    checked={item.id === currentFilter}
                    onChange={() => handleCurrentFilterChange(item.id)}
                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                  />
                  <label
                    htmlFor={item.id}
                    className="ml-3 block text-sm font-medium text-gray-700"
                  >
                    {item.title}
                  </label>
                </div>
              ))}
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                onClick={() => setOpen(!open)}
                disabled
              >
                New
              </button>
            </div>
          </fieldset>
        </div>
      </div>

      <History
        events={events}
        products={products}
        users={users}
        brands={brands}
        data={data}
        model={model}
        setModel={setModel}
        setOpen={setOpen}
      />
      {open && (
        <Modal
          events={events}
          products={products}
          brands={brands}
          users={users}
          model={model}
          setOpen={setOpen}
          data={data}
          setData={setData}
          open={open}
        />
      )}
    </AdminLayout>
  );
};

export default Videos;
