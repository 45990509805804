import { useState, useEffect, useRef } from "react";
import { Dialog } from "@headlessui/react";
import ReactPlayer from "react-player";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  HeartIcon,
  PlayIcon,
  XIcon,
} from "@heroicons/react/outline";
import { motion } from "framer-motion";
import { db } from "../../firebase";
import {
  collection,
  query,
  getDocs,
  doc,
  where,
  orderBy,
  limit,
  startAfter,
  endBefore,
  limitToLast,
  getDoc,
} from "firebase/firestore";

import "./fonts.css";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../utils";

export default function AllVideosPlayer({ ...props }) {
  const [currentVideo, setCurrentVideo] = useState();
  const [lastVisible, setLastVisible] = useState();
  const [user, setUser] = useState();
  const [product, setProduct] = useState();
  const [loader, setLoader] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(false);
  const [noVideo, setNoVideo] = useState(false);
  const pending = useRef(true);
  const navigate = useNavigate();

  const handleGetUser = async (userId) => {
    const userQuery = query(
      collection(db, "users"),
      where("uid", "==", userId),
      limit(1)
    );
    let user = {};
    const userSnapshot = await getDocs(userQuery);
    userSnapshot.forEach((doc) => {
      user = { ...doc.data(), id: doc.id };
      setUser(user);
    });
  };

  const handleGetProduct = async (productId) => {
    if (productId) {
      const productRef = doc(db, "products", productId);
      const productSnap = await getDoc(productRef);

      if (productSnap.exists()) {
        const productInfo = { ...productSnap.data(), id: productSnap.id };
        setProduct(productInfo);
      }
    } else {
      setProduct(null);
    }
  };

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const videosQuery = query(
          collection(db, "videos"),
          where("is_confirmed", "==", true),
          orderBy("created_at", "desc"),
          limit(1)
        );

        let initialVideo = {};
        const videosSnapshot = await getDocs(videosQuery);
        videosSnapshot.forEach((doc) => {
          initialVideo = { ...doc.data(), id: doc.id };
          setCurrentVideo(initialVideo);
        });

        await handleGetUser(initialVideo.uid);
        await handleGetProduct(initialVideo.productId);

        setLastVisible(videosSnapshot.docs[videosSnapshot.docs.length - 1]);
      }
    }
    getData();
  }, []);

  const handleOnClose = () => {
    navigate("/");
  };

  const actionButtonClass =
    "mb-2 p-1 border border-transparent rounded-full text-white bg-[#1a1a1a] hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500";
  const actionButtonIconSize = "w-5 h-5";

  const handleNext = async () => {
    setLoader(true);
    const nextQuery = query(
      collection(db, "videos"),
      where("is_confirmed", "==", true),
      orderBy("created_at", "desc"),
      startAfter(lastVisible),
      limit(1)
    );
    let nextVideo;
    const nextSnapshot = await getDocs(nextQuery);
    nextSnapshot.forEach((doc) => {
      nextVideo = { ...doc.data(), id: doc.id };
    });
    if (nextVideo) {
      await handleGetUser(nextVideo.uid);
      await handleGetProduct(nextVideo.productId);

      setLastVisible(nextSnapshot.docs[nextSnapshot.docs.length - 1]);
      setCurrentVideo(nextVideo);
      setNoVideo(false);
      setLoader(false);
    } else {
      setNoVideo(true);
    }
  };

  const handlePrevious = async () => {
    setLoader(true);

    const previousQuery = query(
      collection(db, "videos"),
      where("is_confirmed", "==", true),
      orderBy("created_at", "desc"),
      endBefore(lastVisible),
      limitToLast(1)
    );
    let previousVideo;
    const previousSnapshot = await getDocs(previousQuery);
    previousSnapshot.forEach((doc) => {
      previousVideo = { ...doc.data(), id: doc.id };
    });
    if (previousVideo) {
      await handleGetUser(previousVideo.uid);
      await handleGetProduct(previousVideo.productId);

      setLastVisible(previousSnapshot.docs[previousSnapshot.docs.length - 1]);
      setCurrentVideo(previousVideo);
      setNoVideo(false);
      setLoader(false);
    } else {
      setNoVideo(true);
    }
  };

  const handleVideoOnEnd = () => handleNext();
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 150;

  const onTouchStart = (e) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e) => setTouchEnd(e.targetTouches[0].clientY);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isUpSwipe = distance > minSwipeDistance;
    const isDownSwipe = distance < -minSwipeDistance;
    if (isUpSwipe || isDownSwipe) {
      isUpSwipe ? handleNext() : handlePrevious();
    }
  };

  return (
    currentVideo && (
      <Dialog open onClose={handleOnClose} className="relative z-50">
        <div
          className="fixed inset-0 h-screen flex mb-10 flex-col bg-[#1a1a1a]"
          aria-hidden="true"
        />

        <div
          className="fixed inset-0 flex items-center justify-center"
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
        >
          <Dialog.Panel className="relative w-screen">
            {loader ? (
              <div>
                <span
                  style={{ fontFamily: "Kaushan Script, cursive" }}
                  className="block text-center text-5xl text-white p-1"
                >
                  supeerlikers
                </span>
                <span className="block font-light text-xl text-center text-white">
                  {"Community"}
                </span>

                {noVideo && (
                  <div className="mt-10">
                    <span className="block text-center text-white font-bold">
                      {"İzlemene sağlık 👀 💜 ✅"}
                    </span>
                    <span className="block text-center text-white font-bold">
                      {"Gösterilecek başka videomuz kalmadı 🥲"}
                    </span>
                    {/* Close Action */}
                    <div className="mt-10 flex justify-center">
                      <button
                        type="button"
                        className={classNames(
                          actionButtonClass,
                          "border-white border-2"
                        )}
                        onClick={handleOnClose}
                      >
                        <XIcon className={actionButtonIconSize} />
                      </button>
                    </div>
                  </div>
                )}
              </div>
            ) : (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{
                  delay: 0.1,
                  x: { duration: 1 },
                  default: { ease: "linear" },
                }}
              >
                <div onClick={() => setPlaying(!playing)}>
                  <ReactPlayer
                    className="top-0 left-0 rounded-xl overflow-hidden"
                    playing={playing}
                    onEnded={handleVideoOnEnd}
                    muted={muted}
                    onReady={() => setLoader(false)}
                    playsinline
                    width="100%"
                    height={"90vh"}
                    url={currentVideo.media.video.url}
                  />
                </div>

                <div className="absolute inset-y-0 left-5 top-5">
                  {user && (
                    <div
                      className="cursor-pointer"
                      onClick={() => navigate(`/creators/${user.username}`)}
                    >
                      <img
                        className="inline-block h-12 w-12 rounded-full object-cover border-white border-2"
                        src={user.media.image.url}
                        alt={user.personal.fullname}
                      />
                      <span className="ml-2 text-white font-extrabold">
                        {user.username}
                      </span>
                    </div>
                  )}
                </div>

                <div className="absolute inset-x-0 bottom-5 bg-[#0000006b]">
                  <div className="flex justify-start items-center">
                    {product && product.media && product.media.images && product.media.images.length > 0 && (
                      <div
                        className="cursor-pointer"
                        onClick={() => navigate(`/product/${product.id}`)}
                      >
                        <img
                          className="inline-block h-24 w-24 rounded-lg object-cover "
                          src={product.media.images[0].url}
                          alt={product.name}
                        />
                      </div>
                    )}
                    <span className="text-white text-sm font-semibold ml-2">
                      {product?.name}
                    </span>
                  </div>
                </div>

                <div className="absolute inset-y-0 right-0 p-5">
                  <div className="absolute right-0 bottom-1/3">
                    {/* Play Action */}
                    <button
                      type="button"
                      className={actionButtonClass}
                      onClick={() => setPlaying(!playing)}
                    >
                      {playing ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          fill="none"
                          stroke="currentColor"
                          className={actionButtonIconSize}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 5.25v13.5m-7.5-13.5v13.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          fill="currentColor"
                          className={actionButtonIconSize}
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.5 5.653c0-1.426 1.529-2.33 2.779-1.643l11.54 6.348c1.295.712 1.295 2.573 0 3.285L7.28 19.991c-1.25.687-2.779-.217-2.779-1.643V5.653z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </button>

                    {/* Next Action */}
                    <button
                      type="button"
                      className={actionButtonClass}
                      onClick={handlePrevious}
                    >
                      <ChevronUpIcon className={actionButtonIconSize} />
                    </button>

                    {/* Back Action */}
                    <button
                      type="button"
                      className={actionButtonClass}
                      onClick={handleNext}
                    >
                      <ChevronDownIcon className={actionButtonIconSize} />
                    </button>

                    {/* Like Action */}
                    {/* <button type="button" className={actionButtonClass}>
                    <HeartIcon className={actionButtonIconSize} />
                  </button> */}

                    {/* Mute Action */}
                    <button
                      type="button"
                      className={actionButtonClass}
                      onClick={() => setMuted(!muted)}
                    >
                      {muted ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className={actionButtonIconSize}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.114 5.636a9 9 0 010 12.728M16.463 8.288a5.25 5.25 0 010 7.424M6.75 8.25l4.72-4.72a.75.75 0 011.28.53v15.88a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.507-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.396C2.806 8.756 3.63 8.25 4.51 8.25H6.75z"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className={actionButtonIconSize}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M17.25 9.75L19.5 12m0 0l2.25 2.25M19.5 12l2.25-2.25M19.5 12l-2.25 2.25m-10.5-6l4.72-4.72a.75.75 0 011.28.531V19.94a.75.75 0 01-1.28.53l-4.72-4.72H4.51c-.88 0-1.704-.506-1.938-1.354A9.01 9.01 0 012.25 12c0-.83.112-1.633.322-2.395C2.806 8.757 3.63 8.25 4.51 8.25H6.75z"
                          />
                        </svg>
                      )}
                    </button>

                    {/* Close Action */}
                    <button
                      type="button"
                      className={actionButtonClass}
                      onClick={handleOnClose}
                    >
                      <XIcon className={actionButtonIconSize} />
                    </button>
                  </div>
                </div>
              </motion.div>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>
    )
  );
}
