import { getStorage, ref, uploadBytes } from "firebase/storage";
import { uuidv4 } from "@firebase/util";

export const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export const friendlyDateTime = (timetamp) => {
  return new Date(timetamp * 1000).toLocaleDateString("tr-TR", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const friendlyDate = (timetamp) => {
  return new Date(timetamp * 1000).toLocaleDateString("tr-TR");
};

export const sortArrayByColumn = (array, column) => {
  return array.sort((a, b) => {
    return b[column] - a[column];
  });
};

export const friendlyInMB = (size) => {
  return (size / (1024 * 1024)).toFixed(2);
};

export const storageUpload = async (file, folderName) => {
  if (file) {
    const bucket = "supeer-like.appspot.com";
    const storage = getStorage();
    const extension = file.name.split(".").pop();
    const objectId = uuidv4();
    const storageRef = ref(storage, `${folderName}/${objectId}`);
    const size = friendlyInMB(file.size);
    const url = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${folderName}%2F${objectId}?alt=media`;

    await uploadBytes(storageRef, file)
      .then((snapshot) => {
        console.log("Uploading File:", file.name);
        console.log(snapshot);
      })
      .catch((error) => console.error(error));

    return {
      extension,
      objectId,
      folderName,
      bucket,
      url,
      size,
    };
  }
};

export const normalizedText = (inputText) => {
  let text = inputText ? inputText.toLocaleLowerCase("tr-TR") : "";

  const trans = [
    { o: "ö", t: "o" },
    { o: "ç", t: "c" },
    { o: "ş", t: "s" },
    { o: "ı", t: "i" },
    { o: "ğ", t: "g" },
    { o: "ü", t: "u" },
  ];
  trans.map((x) => {
    text = text.replaceAll(x.o, x.t);
  });

  return text ? text : "";
};

export const findMultipleValues = (selecteds, options, setFunc) => {
  const temp = [];
  selecteds.map((x) => {
    const found = options.find((y) => y.value === x);
    if (found) {
      temp.push(found);
    }
  });
  setFunc(temp);
};
