import React, { useState, useEffect, useRef } from "react";
import AppLayout from "../../../layouts/AppLayout";
import { useAuth } from "../../../contexts/AuthContext";
import { db } from "../../../firebase";
import { query, collection, orderBy, getDocs, where } from "firebase/firestore";
import { friendlyDateTime } from "../../../utils";
import Modal from "./Modal";

export default function ContentWizard() {
  const { userInfo } = useAuth();
  const [tickets, setTickets] = useState([]);
  const [open, setOpen] = useState(false);
  const [model, setModel] = useState(null);
  const [activity, setActivity] = useState([]);

  const pending = useRef(true);

  useEffect(() => {
    async function loadData() {
      if (pending.current && userInfo) {
        pending.current = false;

        const ticketsQuery = query(
          collection(db, "tickets"),
          where("uid", "==", userInfo.uid),
          orderBy("created_at", "desc")
        );
        const ticketSnapshot = await getDocs(ticketsQuery);
        const ticketList = [];
        ticketSnapshot.forEach((doc) => {
          ticketList.push({ ...doc.data(), id: doc.id });
        });
        setTickets(ticketList);
      } else {
        // navigate("/");
      }
    }
    loadData();
  }, []);

  const statusDescription = {
    1: { description: "Yeni", color: "indigo" },
    2: { description: "İşlemde", color: "yellow" },
    3: { description: "Tamamlandı", color: "green" },
  };

  const getMessageActivity = async (item) => {
    const messageQuery = query(
      collection(db, "ticket_messages"),
      where("ticket_id", "==", item.id),
      orderBy("created_at", "asc")
    );
    const messageSnapshot = await getDocs(messageQuery);
    const messageList = [];
    messageSnapshot.forEach((doc) => {
      messageList.push({ ...doc.data(), id: doc.id });
    });
    setActivity(messageList);
  };

  const handleTicketDetail = async (item) => {
    await getMessageActivity(item);
    setModel(item);
    setOpen(!open);
  };

  return (
    userInfo && (
      <AppLayout>
        <div className="mt-10 max-w-7xl mx-auto pt-4 pb-8 px-4 sm:px-6 lg:pt-4 lg:px-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div>
              <div className="flex justify-between items-center">
                <h2 className="text-4xl font-bold tracking-tight">Destek</h2>
                <button
                  type="button"
                  className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                  onClick={() => setOpen(!open)}
                >
                  Yeni Talep
                </button>
              </div>

              <div className="mt-5">
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
                  {tickets.map((item, key) => (
                    <div
                      key={key}
                      className="border p-3 rounded-lg cursor-pointer"
                      onClick={() => handleTicketDetail(item)}
                    >
                      {item.subject}
                      <div className="mt-2 flex justify-between items-center">
                        <span
                          className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-${
                            statusDescription[item.status].color
                          }-100 text-${
                            statusDescription[item.status].color
                          }-800`}
                        >
                          {statusDescription[item.status].description}
                        </span>
                        <span className="text-xs">
                          {friendlyDateTime(item.created_at)}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <Modal
                model={model}
                setModel={setModel}
                setData={setTickets}
                open={open}
                setOpen={setOpen}
                activity={activity}
                setActivity={setActivity}
              />
            </div>
          </div>
        </div>
      </AppLayout>
    )
  );
}
