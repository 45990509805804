import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/outline";
import { classNames } from "../../utils";

export default function FAQ({ faqs, className = "bg-gray-50" }) {
  return (
    faqs && (
      <div className={className}>
        <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
          <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
            <div className="sm:flex sm:items-baseline sm:justify-between">
              <h2 className="text-4xl font-bold text-black tracking-tight">
                Sıkça Sorulan Sorular
              </h2>
              <a
                href="/faq"
                className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
              >
                Tümünü Gör<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>

            <dl className="mt-6 space-y-6 divide-y divide-gray-200">
              {faqs.map((faq, key) => (
                <Disclosure as="div" key={key} className="pt-6">
                  {({ open }) => (
                    <>
                      <dt className="text-lg">
                        <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                          <span className="font-medium text-gray-900">
                            {faq.question}
                          </span>
                          <span className="ml-6 h-7 flex items-center">
                            <ChevronDownIcon
                              className={classNames(
                                open ? "-rotate-180" : "rotate-0",
                                "h-6 w-6 transform"
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </Disclosure.Button>
                      </dt>
                      <Disclosure.Panel as="dd" className="mt-2 pr-12">
                        <p className="text-base text-gray-500">{faq.answer}</p>
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
              ))}
            </dl>
          </div>
        </div>
      </div>
    )
  );
}
