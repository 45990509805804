import React from "react";
import { NavLink, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Supeerlike from "../Supeerlike/Supeerlike";

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

export default function Desktop({ ...props }) {
  const { routes } = props;
  const navigate = useNavigate();

  return (
    <>
      <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white dark:border-none dark:shadow-xl dark:bg-slate-900">
          <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
            <div
              className="flex items-center flex-shrink-0 mx-auto cursor-pointer"
              onClick={() => navigate("/")}
            >
              <Supeerlike />
            </div>
            <nav className="mt-5 flex-1 px-2 bg-white dark:bg-slate-900 space-y-1">
              {routes.map(
                (item) =>
                  item.active && (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      className={(navData) => {
                        return classNames(
                          navData.isActive
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
                          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
                        );
                      }}
                    >
                      <item.icon
                        className="mr-3 flex-shrink-0 h-6 w-6"
                        aria-hidden="true"
                      />
                      {item.name}
                    </NavLink>
                  )
              )}
            </nav>
          </div>

          {<Footer />}
        </div>
      </div>
    </>
  );
}
