import React from "react";
import logo from "../../assets/logo/supeerlike_new_logo.png";


export default function Supeerlike() {
  return (
    // <span className="text-4xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-700 to-pink-600 mix-blend-multiply">
    //   supeerlike
    // </span>
    <img className="h-9 sm:h-11 w-auto" src={logo} alt="" />
  );
}
