import { useState, useEffect, useRef } from "react";
import AppLayout from "../../layouts/AppLayout";
import { collection, query, getDocs, where, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import { useNavigate } from "react-router-dom";
import { SearchIcon, CursorClickIcon } from "@heroicons/react/outline";

export default function Brands() {
  const [brands, setBrands] = useState([]);
  const navigate = useNavigate();
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const brandQuery = query(
          collection(db, "brands"),
          where("active", "==", true),
          orderBy("name", "asc")
        );
        const brandSnapshot = await getDocs(brandQuery);
        const brandList = [];
        brandSnapshot.forEach((doc) => {
          brandList.push({ ...doc.data(), id: doc.id });
        });
        setBrands(brandList);
      }
    }
    getData();
  }, []);

  return (
    <AppLayout>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 bg-gray-50 rounded-xl mb-10 mt-5">
        <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
          <h2 className="text-5xl font-bold text-black tracking-tight">
            Markalar
          </h2>

          <div>
            <p className="mt-3 text-sm leading-6 font-semibold text-slate-600">
              {"Instagram ve TikTok’ ta viral olmayı kim istemez ki!"}
            </p>
            {/* <a href="/brand-request" className="text-purple-600 cursor-pointer text-sm">
              Marka Başvuru Formu
            </a> */}
          </div>

          <div className="mt-6 grid grid-cols-2 gap-4 md:grid-cols-4 lg:mt-8">
            {brands.map((item, key) => (
              <div
                key={key}
                className="col-span-1 flex justify-center py-8 px-8 rounded-xl shadow-sm bg-white"
              >
                <div>
                  <img
                    className="h-24 object-contain object-center"
                    src={item.media.logo.url}
                    alt={item.name}
                  />
                  <div className="mt-5 flex justify-center">
                    <button
                      onClick={() => navigate(`/brand/${item.id}`)}
                      className="text-purple-900 p-2 rounded-full text-sm bg-purple-100"
                    >
                      <CursorClickIcon className="w-6 h-6" />
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
