import { Fragment, useRef, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XIcon,
  ChatAltIcon,
  TagIcon,
  UserCircleIcon,
} from "@heroicons/react/outline";
import { db } from "../../../firebase";
import {
  collection,
  addDoc,
  query,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import { useAuth } from "../../../contexts/AuthContext";
import { classNames, friendlyDateTime } from "../../../utils";

export default function Modal({ ...props }) {
  const { model, setModel, setData, open, setOpen, activity, setActivity } =
    props;
  const { userInfo } = useAuth();
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState(null);
  const sample = {
    subject: null,
    message: null,
  };
  const [payload, setPayload] = useState(model || sample);
  const pending = useRef(true);

  const handleSubmit = async () => {
    setLoader(!loader);

    try {
      payload.created_at = Math.floor(Date.now() / 1000);
      payload.status = 1; // 1: New, 2: In Progress, 3: Done
      payload.username = userInfo.username;
      payload.uid = userInfo.uid;

      await addDoc(collection(db, "tickets"), payload);
      setLoader(!loader);
      handleOnClose();
      setData((data) => [...data, payload]);
    } catch (e) {
      console.error("Error adding document: ", e);
      setLoader(!loader);
    }
  };

  const handleOnClose = () => {
    setModel(null);
    setOpen(!open);
    setPayload(sample);
    setActivity([]);
  };

  const handleSendMessage = async () => {
    setLoader(true);
    setMessage(null);

    try {
      const newMessage = {
        created_at: Math.floor(Date.now() / 1000),
        ticket_id: model.id,
        is_sender_admin: false,
        message,
      };
      await addDoc(collection(db, "ticket_messages"), newMessage);
      setActivity((data) => [...data, newMessage]);
      setLoader(false);
      // handleOnClose();
    } catch (e) {
      console.error("Error adding document: ", e);
      setLoader(!loader);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={handleOnClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            {"Talep"}
                          </Dialog.Title>
                        </div>
                        {/* <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={handleOnClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div> */}
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="space-y-6 py-1 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-6 px-4 py-5 sm:p-6">
                        <div className="relative">
                          <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                            <input
                              type="text"
                              className="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0"
                              placeholder="*Konu ✉️"
                              disabled={model}
                              defaultValue={
                                model ? model.subject : payload.subject
                              }
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  subject: e.target.value,
                                })
                              }
                            />

                            <div className="border w-full" />

                            <textarea
                              rows={3}
                              className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                              placeholder="*Detay  ✏️"
                              disabled={model}
                              defaultValue={
                                model ? model.message : payload.message
                              }
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  message: e.target.value,
                                })
                              }
                            />

                            {/* Spacer element to match the height of the toolbar */}
                            {!model && (
                              <div className="py-2" aria-hidden="true">
                                {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                <div className="py-px">
                                  <div className="h-9" />
                                </div>
                              </div>
                            )}
                          </div>

                          {!model && (
                            <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-end">
                              <div className="flex-shrink-0">
                                <button
                                  type="submit"
                                  className={classNames(
                                    "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                    loader ||
                                      !(payload.subject && payload.message)
                                      ? "opacity-75"
                                      : ""
                                  )}
                                  onClick={() => handleSubmit()}
                                  disabled={
                                    loader ||
                                    !(payload.subject && payload.message)
                                  }
                                >
                                  Gönder
                                </button>
                              </div>
                            </div>
                          )}
                        </div>

                        {model && (
                          <>
                            <p className="border-b text-gray-500">Mesajlar</p>

                            <div className="flow-root">
                              <ul role="list" className="-mb-8">
                                {activity.map((item, key) => (
                                  <li key={key}>
                                    <div className="relative pb-8">
                                      {key !== activity.length - 1 ? (
                                        <span
                                          className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                          aria-hidden="true"
                                        />
                                      ) : null}
                                      <div className="relative flex items-start space-x-3">
                                        <>
                                          <div className="relative">
                                            <img className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" />

                                            <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                              <ChatAltIcon
                                                className="h-5 w-5 text-gray-400"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          </div>
                                          <div className="min-w-0 flex-1">
                                            <div>
                                              <div className="text-sm">
                                                <span
                                                  className={classNames(
                                                    item.is_sender_admin
                                                      ? "inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                                                      : "hidden"
                                                  )}
                                                >
                                                  {item.is_sender_admin
                                                    ? "Superlike 💜"
                                                    : userInfo.username}
                                                </span>
                                              </div>
                                              <p className="mt-0.5 text-sm text-gray-500">
                                                {friendlyDateTime(
                                                  item.created_at
                                                )}
                                              </p>
                                            </div>
                                            <div className="mt-2 text-sm text-gray-700">
                                              <p>{item.message}</p>
                                            </div>
                                          </div>
                                        </>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                            </div>

                            {model.status != 3 && (
                              <div className="relative max-w-md mx-auto">
                                <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                                  <textarea
                                    rows={2}
                                    className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                                    placeholder="Mesaj ✏️"
                                    onChange={(e) => setMessage(e.target.value)}
                                  />

                                  {/* Spacer element to match the height of the toolbar */}
                                  <div className="py-2" aria-hidden="true">
                                    {/* Matches height of button in toolbar (1px border + 36px content height) */}
                                    <div className="py-px">
                                      <div className="h-9" />
                                    </div>
                                  </div>
                                </div>

                                <div className="absolute bottom-0 inset-x-0 pl-3 pr-2 py-2 flex justify-end">
                                  <div
                                  // className={classNames(
                                  //   model ? "hidden" : "flex-shrink-0"
                                  // )}
                                  >
                                    <button
                                      type="submit"
                                      className={classNames(
                                        "inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                                        loader || !message ? "opacity-75" : ""
                                      )}
                                      disabled={loader || !message}
                                      onClick={() => handleSendMessage()}
                                    >
                                      Gönder
                                    </button>
                                  </div>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
