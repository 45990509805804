import React from "react";

const Brands = ({ ...props }) => {
  const { brands } = props;

  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
        {/* <p className="text-center text-base font-semibold uppercase text-gray-600 tracking-wider">
          Trusted by over 5 very average small businesses
        </p> */}
        <div className="sm:flex sm:items-baseline sm:justify-between">
          <h2 className="text-5xl font-bold text-black tracking-tight">
            Markalar
          </h2>
          <a
            href="/brands"
            className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
          >
            Tümünü Gör<span aria-hidden="true"> &rarr;</span>
          </a>
        </div>

        <div className="mt-6 grid grid-cols-2 gap-5 md:grid-cols-3 lg:mt-8">
          {brands.map((item, key) => (
            <a
              key={key}
              href={`/brand/${item.id}`}
              className="flex justify-center col-span-1 bg-gray-100 p-5 rounded-xl grayscale cursor-pointer"
            >
              <div className="flex justify-center w-60 h-44">
                <img
                  className="object-contain grayscale"
                  width="100%"
                  height="100%"
                  src={item.media.logo.url}
                  alt={item.name}
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Brands;
