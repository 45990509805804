import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";

export default function Footer() {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="flex-shrink-0 flex border-t border-gray-200 p-4">
      <div className="flex-shrink-0 w-full group block">
        <div className="flex items-center">
          {/* { currentUser.photoURL && (
            <div>
                <img
                className="inline-block h-9 w-9 rounded-full"
                src={currentUser.photoURL}
                alt="User photo"
                />
            </div>
            )} */}
          <div className="ml-3">
            {/* <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
              {currentUser && currentUser.personal.fullname}
            </p>
            <p className="text-xs text-gray-600 group-hover:text-gray-900">
              {currentUser && currentUser.email}
            </p> */}

            <p className="text-xs text-gray-600 group-hover:text-gray-900">
              {currentUser && currentUser.email}
            </p>
            <p
              onClick={() => navigate("/logout")}
              className="mt-3 text-xs border-2 p-1 rounded-lg text-center font-medium text-gray-500 group-hover:text-gray-700 cursor-pointer"
            >
              Logout
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
