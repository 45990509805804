import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";

const Final = ({ ...props }) => {
  const { loader } = props;
  const { previousStep, isLastStep } = useWizard();
  const { width, height } = useWindowSize();

  const children = () => {
    return (
      <div className="text-center">
        <h4 className="text-lg font-bold">Harika! İçeriğin revize ediliyor.</h4>
        <h6 className="font-semibold mt-5">
          Yükleme bitene kadar bu ekranı kapatmayın lütfen.
        </h6>
        <Confetti width={width} height={height} />
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Neredeyse bitti"}
        subTitle={""}
        children={children()}
        isLastStep={isLastStep}
      />
    </>
  );
};

export default Final;
