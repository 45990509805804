import React from "react";
import { classNames, friendlyDateTime } from "../../../utils";

export default function History({ ...props }) {
  const { data, setModel, setOpen, users, events, products } = props;

  const getProduct = (productId) => {
    return products.find((x) => x.id === productId);
  };

  const getEvent = (eventId) => {
    return events.find((x) => x.id === eventId);
  };

  const getUser = (userId) => {
    return users.find((x) => x.uid === userId);
  };

  return (
    <div className="overflow-x-auto -mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              #
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Created At
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Event
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              User
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Product
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, key) => (
            <tr
              key={key}
              className="cursor-pointer hover:bg-gray-50"
              // onClick={() => handleOpenModel(item)}
            >
              <td className="whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {key + 1}
              </td>
              <td className="whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                <span>{friendlyDateTime(item.created_at)}</span>
              </td>
              <td className="whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {getEvent(item.eventId).title}
              </td>
              <td className="whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {getUser(item.userId).username}
              </td>
              <td className="whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {getProduct(item.productId).name}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
