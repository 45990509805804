import { Fragment, useState } from "react";
import { Switch, Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { uuidv4 } from "@firebase/util";
import { db } from "../../../firebase";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import {
  sortArrayByColumn,
  classNames,
  storageUpload,
  friendlyInMB,
  normalizedText
} from "../../../utils";

export default function Modal({ ...props }) {
  const { model, data, setData, open, setOpen } = props;
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(
    model ? model.media.logo.url : null
  );
  const [submitActive, setSubmitActive] = useState(true);
  const initPayload = {
    created_at: Math.floor(Date.now() / 1000),
    active: true,
    name: "",
    description: "",
    website: "",
    logo: "",
    tags: [],
    landingOrder: 0,
  };
  const [payload, setPayload] = useState(model || initPayload);

  const handleLogoChange = (e) => {
    const file = e.target.files[0]
    setLogoPreview(URL.createObjectURL(file));
    setLogo(file);
  };

  const handleSubmit = async () => {
    setSubmitActive(!submitActive);

    if (logo) {
      payload.media = {
        logo: await storageUpload(logo, "brand-images"),
      };
    }

    payload.search = {
      name: normalizedText(payload.name),
      description: normalizedText(payload.description),
    };

    try {
      if (model) {
        const docRef = doc(db, "brands", payload.id);
        await setDoc(docRef, payload, { merge: true });
        const tempList = data.filter((x) => x.id !== payload.id);
        tempList.push(payload);
        setData(sortArrayByColumn(tempList, "created_at"));
      } else {
        await addDoc(collection(db, "brands"), payload);
        setData((prev) => [...prev, payload]);
      }

      setOpen(!open);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Brand{" "}
                            <span className="text-gray-500 text-sm">
                              {model ? model.id : ""}
                            </span>
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-6 px-4 py-5 sm:p-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Brand Name
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.name}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={3}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={payload.description}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  description: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="border p-2 rounded-lg">
                          <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                              Logo
                            </label>
                            <div>
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() =>
                                  document.getElementById("logo").click()
                                }
                              >
                                Browse
                              </button>
                              <input
                                id="logo"
                                type="file"
                                className="sr-only"
                                accept="image/*"
                                onChange={(e) => handleLogoChange(e)}
                              />
                            </div>
                          </div>
                          {logoPreview && (
                            <div className="p-4 mt-2">
                              <div className="flex items-center justify-center">
                                <img className="rounded-lg" src={logoPreview} />
                              </div>

                              {logo && (
                                <span className="block mt-1 text-gray-600 text-xs font-bold">
                                  {"Size: "} {friendlyInMB(logo.size)} {"MB"}{" "}
                                </span>
                              )}
                            </div>
                          )}
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Website URL
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.website}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  website: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Landing Order
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.landingOrder}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  landingOrder: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <div>
                            <legend className="text-sm font-medium text-gray-900">
                              {"Inactive / Active"}
                            </legend>
                          </div>

                          <div className="space-y-5">
                            <div className="space-y-5 sm:mt-0">
                              <div className="flex">
                                <Switch.Group
                                  as="div"
                                  className="flex items-center"
                                >
                                  <Switch
                                    defaultChecked={payload.active}
                                    onChange={(e) =>
                                      setPayload({
                                        ...payload,
                                        active: !payload.active,
                                      })
                                    }
                                    className={classNames(
                                      payload.active
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        payload.active
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleSubmit()}
                      >
                        {model ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
