import React from "react";
import { classNames, friendlyDateTime } from "../../../utils";

export default function History({ ...props }) {
  const { data, setModel, setOpen } = props;
  const handleOpenModel = (item) => {
    setModel(item);
    setOpen(true);
  };

  return (
    <div className="overflow-x-auto -mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              #
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Brand Name
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Created At
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Full Name
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Phone
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Is Seen
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, key) => (
            <tr
              key={key}
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => handleOpenModel(item)}
            >
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.rowNumber}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.brand_name}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {friendlyDateTime(item.created_at)}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.full_name}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.phone}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.email}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                <span
                  className={classNames(
                    "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                    item.seen
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                  )}
                >
                  {item.seen ? "Seen" : "Not Seen"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
