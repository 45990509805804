import React, { useEffect, useRef, useState } from "react";
import { collection, query, getDocs, orderBy } from "firebase/firestore";
import { db } from "../../../firebase";
import AdminLayout from "../../../layouts/AdminLayout";
import History from "./History";
import Modal from "./Modal";

const Brands = () => {
  const [model, setModel] = useState();
  const [open, setOpen] = useState();
  const [data, setData] = useState([]);
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const q = query(
          collection(db, "brands"),
          orderBy("search.name", "asc")
        );
        const querySnapshot = await getDocs(q);
        const temp = [];
        let rowNumber = 0;
        querySnapshot.forEach((doc) => {
          rowNumber += 1;
          temp.push({ ...doc.data(), id: doc.id, rowNumber });
        });
        setData(temp);
      }
    }
    getData();
  }, []);

  return (
    <AdminLayout>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">{`Brands (${data.length})`}</h1>
        </div>
        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(!open)}
          >
            New
          </button>
        </div>
      </div>

      <History data={data} setModel={setModel} setOpen={setOpen} />
      {open && (
        <Modal
          model={model}
          setModel={setModel}
          data={data}
          setData={setData}
          setOpen={setOpen}
          open={open}
        />
      )}
    </AdminLayout>
  );
};

export default Brands;
