import React, { Fragment, useState, useEffect, useRef } from "react";
import AppLayout from "../../layouts/AppLayout";
import {
  collection,
  query,
  doc,
  getDoc,
  getDocs,
  orderBy,
  where,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import EventCard from "../../components/EventCard/EventCard";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../contexts/AuthContext";
import { classNames, friendlyDateTime } from "../../utils";
import Player from "../../components/StoryPlayer/Player";
import { Dialog, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ExclamationCircleIcon,
  EmojiSadIcon,
} from "@heroicons/react/outline";

export default function EventDetail() {
  const { eventId } = useParams();
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const [event, setEvent] = useState();
  const [inspiredVideos, setInspiredVideos] = useState([]);
  const [creatorVideos, setCreatorVideos] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [playerOpen, setPlayerOpen] = useState(false);
  const [currentPlay, setCurrentPlay] = useState(0);
  const [usedEventLimit, setUsedEventLimit] = useState(0);
  const [products, setProducts] = useState([]);
  const [joinDisable, setJoinDisable] = useState(false);
  const [joinOpen, setJoinOpen] = useState(false);
  const [orderModalOpen, setOrderModalOpen] = useState(false);
  const [userHasJoined, setUserHasJoined] = useState(false);
  const [showProductBuy, setShowProductBuy] = useState(false);
  const [userScore, setUserScore] = useState(null);
  const [resultType, setResultType] = useState("JOINED");
  const [isClosedEvent, setIsClosedEvent] = useState(false);
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const docRef = doc(db, "events", eventId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const event = docSnap.data();
          event.earningLimit = event.earningLimit || 0;

          setEvent({ ...event, id: docSnap.id });

          const now = Math.floor(Date.now() / 1000);
          setIsClosedEvent(now > event.constraints.videoExpireAt);

          const productList = [];
          event.products.map(async (item) => {
            if (item.productId) {
              const productDocRef = doc(db, "products", item.productId);
              const productDocSnap = await getDoc(productDocRef);

              if (productDocSnap.exists()) {
                productList.push({
                  ...productDocSnap.data(),
                  id: productDocSnap.id,
                  buyItem: item,
                });
              }
            }
            setProducts(productList);
          });

          const inspiredVideosQuery = query(
            collection(db, "inspired_videos"),
            where("eventId", "==", eventId)
          );
          const inspiredVideosSnapshot = await getDocs(inspiredVideosQuery);
          const inspiredVideosList = [];
          inspiredVideosSnapshot.forEach((doc) => {
            inspiredVideosList.push({ ...doc.data(), id: doc.id });
          });
          setInspiredVideos(inspiredVideosList);

          const creatorVideosQuery = query(
            collection(db, "videos"),
            where("eventId", "==", eventId),
            where("is_confirmed", "==", true),
            orderBy("created_at", "desc")
          );
          const creatorVideosSnapshot = await getDocs(creatorVideosQuery);
          const creatorVideosList = [];
          creatorVideosSnapshot.forEach((doc) => {
            creatorVideosList.push({ ...doc.data(), id: doc.id });
          });
          setCreatorVideos(creatorVideosList);

          const eventJoinsQuery = query(
            collection(db, "event_joins"),
            where("eventId", "==", eventId)
          );
          const eventJoinsSnapshot = await getDocs(eventJoinsQuery);
          setUsedEventLimit(eventJoinsSnapshot.size);

          if (userInfo) {
            const uScore = parseInt(
              userInfo.scoring ? userInfo.scoring.score || 0 : 0
            );

            setUserScore(uScore);

            if (event.minScore && event.minScore > 0) {
              if (uScore >= event.minScore) {
                setShowProductBuy(true);
              }
            }
          }
        }
      }
    }
    getData();
  }, []);

  const handleVideoPlay = (item, playList) => {
    setCurrentPlay(item);
    setPlayerList(playList);
    setPlayerOpen(true);
  };

  const handleOrder = async (productId) => {
    const stock = parseInt(
      event.products.find((x) => x.productId === productId).stock
    );
    const stockInProduct = query(
      collection(db, "orders"),
      where("eventId", "==", eventId),
      where("productId", "==", productId)
    );
    const stockInProductSnapshot = await getDocs(stockInProduct);

    const userHasOrder = query(
      collection(db, "orders"),
      where("eventId", "==", eventId),
      where("userId", "==", userInfo.uid)
    );
    const userHasOrderSnapshot = await getDocs(userHasOrder);
    const userFirstOrder = userHasOrderSnapshot.size === 0;
    const remainingStocks = stockInProductSnapshot.size;

    if (userFirstOrder) {
      if (remainingStocks < stock) {
        await addDoc(collection(db, "orders"), {
          created_at: Math.floor(Date.now() / 1000),
          eventId,
          userId: userInfo.uid,
          productId,
        });
        setResultType("SUCCESS");
        setOrderModalOpen(true);
      } else {
        setResultType("NO_STOCK");
        setOrderModalOpen(true);
      }
    } else {
      setResultType("DUPLICATE");
      setOrderModalOpen(true);
    }
  };

  const handleJoinEvent = async () => {
    const joinerQuery = query(
      collection(db, "event_joins"),
      where("eventId", "==", eventId)
    );
    const joinersSnapshot = await getDocs(joinerQuery);
    const remainings = joinersSnapshot.size;

    const userHasJoinedQuery = query(
      collection(db, "event_joins"),
      where("eventId", "==", eventId),
      where("userId", "==", userInfo.uid)
    );
    const userHasJoinedSnapshot = await getDocs(userHasJoinedQuery);
    setUserHasJoined(userHasJoinedSnapshot.size !== 0);

    if (userHasJoinedSnapshot.size) {
      setJoinDisable(true);
      setJoinOpen(true);
      setResultType("ALREADY_JOINED");
    }

    let userScorePermit = true;
    if (event.minScore && event.minScore > 0) {
      if (userScore < event.minScore) {
        userScorePermit = false;
        setJoinDisable(true);
        setJoinOpen(true);
        setResultType("ALREADY_JOINED");
        setResultType("MIN_SCORE");
      }
    }

    if (
      userInfo &&
      userScorePermit &&
      userHasJoinedSnapshot.size == 0 &&
      event.products.length === 0 &&
      (remainings < event.earningLimit || event.earningLimit === 0)
    ) {
      await addDoc(collection(db, "event_joins"), {
        created_at: Math.floor(Date.now() / 1000),
        eventId,
        userId: userInfo.uid,
      });
      setResultType("SUCCESS");
      setJoinDisable(true);
      setJoinOpen(true);
    }
  };

  const JoinModal = () => {
    return (
      <Transition.Root show={joinOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={setJoinOpen}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  {resultType === "ALREADY_JOINED" && (
                    <>
                      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
                        <ExclamationCircleIcon
                          className="h-8 w-8 text-red-600"
                          aria-hidden="true"
                        />
                      </div>

                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-bold text-gray-900"
                        >
                          <span className="block">Oppss!</span>
                        </Dialog.Title>
                        <div className="mt-5">
                          <p className="text-sm text-gray-500">
                            <span className="block">
                              Etkinliğe zaten katıldın! 🙈🙈
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {resultType === "SUCCESS" && (
                    <>
                      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                        <CheckIcon
                          className="h-8 w-8 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-bold text-gray-900"
                        >
                          <span className="block">Tebrikler!</span>
                          <span className="block font-medium">
                            Etkinliğe kaydın gerçekleşti 🥳
                          </span>
                        </Dialog.Title>
                        <div className="mt-5">
                          <p className="text-sm text-gray-500">
                            <span className="block">
                              Video bitiş tarihine kadar içeriğini yüklemelisin.
                            </span>
                            <span className="block">
                              Aksi halde etkinlik puanı kadar puanın düşecek.
                            </span>
                            <span className="mt-5 block font-semibold">
                              Hadi acele et, içeriğini yükle 😍
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {resultType === "MIN_SCORE" && (
                    <>
                      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
                        <EmojiSadIcon
                          className="h-8 w-8 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-bold text-gray-900"
                        >
                          <span className="block">Oppss!</span>
                        </Dialog.Title>
                        <div className="mt-5">
                          <p className="text-sm text-gray-500">
                            <span className="bg-purple-500 font-bold text-sm p-0.5 rounded text-white shadow shadow-purple-800">
                              XP: {userScore}
                            </span>
                            <span className="block mt-2">
                              Bu etkinlik için puanın yeterli değil! 🥲
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const OrderModal = () => {
    return (
      <Transition.Root show={orderModalOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={setOrderModalOpen}
        >
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  {resultType === "NO_STOCK" && (
                    <>
                      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
                        <ExclamationCircleIcon
                          className="h-8 w-8 text-red-600"
                          aria-hidden="true"
                        />
                      </div>

                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-bold text-gray-900"
                        >
                          <span className="block">Oppss!</span>
                        </Dialog.Title>
                        <div className="mt-5">
                          <p className="text-sm text-gray-500">
                            <span className="block">
                              Maalesef stoklarımız tükendi! 🙈🙈
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {resultType === "DUPLICATE" && (
                    <>
                      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-red-100">
                        <ExclamationCircleIcon
                          className="h-8 w-8 text-red-600"
                          aria-hidden="true"
                        />
                      </div>

                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-bold text-gray-900"
                        >
                          <span className="block">Oppss!</span>
                        </Dialog.Title>
                        <div className="mt-5">
                          <p className="text-sm text-gray-500">
                            <span className="block">
                              Zaten bir siparişin var! Bir etkinlikte yalnızca
                              bir kez ürün siparişi verebilirsin.
                            </span>
                          </p>
                        </div>
                      </div>
                    </>
                  )}

                  {resultType === "SUCCESS" && (
                    <>
                      <div className="mx-auto flex items-center justify-center h-16 w-16 rounded-full bg-green-100">
                        <CheckIcon
                          className="h-8 w-8 text-green-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:mt-5">
                        <Dialog.Title
                          as="h3"
                          className="text-lg leading-6 font-bold text-gray-900"
                        >
                          <span className="block">Tebrikler!</span>
                          <span className="block font-medium">
                            Siparişin başarıyla alındı 🥳
                          </span>
                        </Dialog.Title>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const EventConstraints = () => {
    return (
      <div>
        <div className="mb-4 border-b border-gray-200 pb-8">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-3">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Etkinlik Başlangıç
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {friendlyDateTime(event.constraints.startAt)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Satışa Kapanma
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {friendlyDateTime(event.constraints.salesExpireAt)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                İçerik Yükleme Bitiş
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {friendlyDateTime(event.constraints.videoExpireAt)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">Kontenjan</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {event.earningLimit ? event.earningLimit : "Sınırsız"}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Gerekli Puan
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {event.minScore === 0
                  ? "Herkese Açık"
                  : `${event.minScore} Puan ve üzeri`}
              </dd>
            </div>

            {userInfo && (
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">Kazanç</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {event.earningValue}{" "}
                  {event.earningType === "MONEY" ? "TL" : "Puan"}
                </dd>
              </div>
            )}
          </dl>
        </div>
      </div>
    );
  };

  const Products = () => {
    return (
      products.length > 0 && (
        <div>
          <h2 className="text-4xl font-bold tracking-tight mb-10">Ürünler</h2>

          <div className="grid grid-cols-1 gap-y-4 sm:grid-cols-2 sm:gap-x-6 sm:gap-y-10 lg:grid-cols-4 lg:gap-x-8">
            {products.map((product) => (
              <div
                key={product.id}
                className="group relative rounded-lg flex flex-col overflow-hidden"
              >
                {product.media && product.media.images.length > 0 && (
                  <div className="relative w-full h-72 rounded-lg overflow-hidden">
                    <img
                      src={product.media.images[0].url}
                      alt={product.name}
                      className="w-full h-full object-center object-cover"
                    />
                  </div>
                )}
                <div className="flex-1 p-2 space-y-2 flex flex-col">
                  <h3 className="text-sm font-medium text-gray-900">
                    <a href={product.href}>
                      <span aria-hidden="true" className="absolute inset-0" />
                      {product.name}
                    </a>
                  </h3>

                  {userInfo && showProductBuy && (
                    <div className="flex-1 flex flex-col justify-end">
                      <button
                        type="button"
                        onClick={() => handleOrder(product.id)}
                        className="relative flex bg-gray-100 border border-transparent rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-200"
                      >
                        Ön Sipariş
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  const InspiredVideos = () => {
    return (
      inspiredVideos.length > 0 && (
        <div>
          <h2 className="text-4xl font-bold tracking-tight mb-10">
            İlham Veren Videolar
          </h2>

          <div className="flow-root">
            <div>
              <div className="box-content py-2 relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {inspiredVideos.map((item, key) => (
                    <div
                      key={key}
                      className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                      onClick={() => handleVideoPlay(item, inspiredVideos)}
                    >
                      <span aria-hidden="true" className="absolute inset-0">
                        <img
                          src={item.media.image.url}
                          className="w-full h-full object-center object-cover"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const CreatorVideos = () => {
    return (
      creatorVideos.length > 0 && (
        <div>
          <h2 className="text-4xl font-bold tracking-tight mb-10">İçerikler</h2>

          <div className="flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {creatorVideos.map((item, key) => (
                    <div
                      key={key}
                      className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                      onClick={() => handleVideoPlay(item, creatorVideos)}
                    >
                      <span aria-hidden="true" className="absolute inset-0">
                        <img
                          src={item.media.image.url}
                          className="w-full h-full object-center object-cover"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const EventJoinButton = () => {
    console.log('userInfo', userInfo)
    console.log('isClosedEvent', isClosedEvent)
    console.log('event.products.length', event.products.length)
    console.log('usedEventLimit', usedEventLimit)
    console.log('event.earningLimit', event.earningLimit)

    return (
      userInfo &&
      !isClosedEvent &&
      event.products.length === 0 &&
      (usedEventLimit < event.earningLimit || event.earningLimit === 0) && (
        <div className="mt-10 flex justify-center">
          <button
            type="button"
            disabled={joinDisable}
            className={classNames(
              "w-screen max-w-md font-bold cursor-pointer text-xl whitespace-nowrap inline-flex items-center justify-center bg-origin-border px-4 py-2 border border-transparent rounded-md shadow-sm text-white",
              joinDisable
                ? "bg-gray-400 pointer-events-none"
                : "bg-gradient-to-r from-pink-600 to-indigo-500  hover:from-purple-700 hover:to-indigo-700"
            )}
            onClick={() => handleJoinEvent()}
          >
            Etkinliğe Katıl
          </button>
        </div>
      )
    );
  };

  return (
    event && (
      <AppLayout>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
            <div className="sm:flex sm:items-baseline sm:justify-between">
              <h2 className="text-5xl font-bold text-black tracking-tight">
                {event.title}
              </h2>
              <a
                href="/events"
                className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
              >
                Tüm Etkinlikleri Gör<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>

            <div className="mt-6 space-y-12 lg:grid lg:grid-cols-3 lg:gap-x-6 lg:space-y-0 items-center">
              <div>
                <EventCard event={event} short={false} />
              </div>

              <div className="col-span-2">
                {/* <h2 className="text-5xl font-bold text-black tracking-tight">
                  {event.title}
                </h2> */}

                <EventConstraints />

                <p className="text-gray-600 font-medium text-sm">
                  {event.content}
                </p>

                <EventJoinButton />
              </div>
            </div>

            <div className="mt-16">
              <InspiredVideos />
              <div className="mt-16" />
              <Products />
              <div className="mt-16" />
              <CreatorVideos />

              <Player
                open={playerOpen}
                setOpen={setPlayerOpen}
                playerList={playerList}
                setPlayerList={setPlayerList}
                currentPlay={currentPlay}
                setCurrentPlay={setCurrentPlay}
              />

              <JoinModal />
              <OrderModal />
            </div>
          </div>
        </div>
      </AppLayout>
    )
  );
}
