import { Fragment, useState } from "react";
import { Switch, Dialog, Transition } from "@headlessui/react";
import { XIcon, ChatAltIcon } from "@heroicons/react/outline";
import { db } from "../../../firebase";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { classNames, friendlyDateTime } from "../../../utils";

export default function Modal({ ...props }) {
  const {
    model,
    setModel,
    data,
    setData,
    open,
    setOpen,
    activity,
    setActivity,
  } = props;
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState(null);
  const [status, setStatus] = useState(model.status);

  const handleClose = () => {
    setOpen(!open);
    setModel(null);
    setMessage(null);
    setLoader(false);
  };

  const handleSubmit = async () => {
    setLoader(true);

    const docRef = doc(db, "tickets", model.id);
    await setDoc(
      docRef,
      { status, updated_at: Math.floor(Date.now() / 1000) },
      { merge: true }
    );

    if (message) {
      try {
        const newMessage = {
          created_at: Math.floor(Date.now() / 1000),
          ticket_id: model.id,
          is_sender_admin: true,
          message,
        };
        await addDoc(collection(db, "ticket_messages"), newMessage);
        setActivity((data) => [...data, newMessage]);
        setLoader(false);
        // handleOnClose();
      } catch (e) {
        console.error("Error adding document: ", e);
        setLoader(!loader);
      }
    }

    handleClose()
  };

  const statuses = [
    { status: 1, description: "New" },
    { status: 2, description: "In Progress" },
    { status: 3, description: "Done" },
  ];

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={handleClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Ticket{" "}
                            <span className="text-gray-500 text-sm">
                              {model ? model.id : ""}
                            </span>
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => handleClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="space-y-6 py-1 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-6 px-4 py-5 sm:p-6">
                        <div className="relative">
                          <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                            <input
                              type="text"
                              className="block w-full border-0 pt-2.5 text-lg font-medium placeholder-gray-500 focus:ring-0"
                              disabled
                              defaultValue={model.subject}
                            />

                            <div className="border w-full" />

                            <textarea
                              rows={3}
                              className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                              placeholder="*Detay  ✏️"
                              disabled
                              defaultValue={model.message}
                            />
                          </div>
                        </div>

                        <p className="border-b text-gray-500">Conversations</p>

                        <div className="flow-root">
                          <ul role="list" className="-mb-8">
                            {activity.map((item, key) => (
                              <li key={key}>
                                <div className="relative pb-8">
                                  {key !== activity.length - 1 ? (
                                    <span
                                      className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                                      aria-hidden="true"
                                    />
                                  ) : null}
                                  <div className="relative flex items-start space-x-3">
                                    <>
                                      <div className="relative">
                                        <img className="h-10 w-10 rounded-full bg-gray-400 flex items-center justify-center ring-8 ring-white" />

                                        <span className="absolute -bottom-0.5 -right-1 bg-white rounded-tl px-0.5 py-px">
                                          <ChatAltIcon
                                            className="h-5 w-5 text-gray-400"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      </div>
                                      <div className="min-w-0 flex-1">
                                        <div>
                                          <div className="text-sm">
                                            <span
                                              className={classNames(
                                                item.is_sender_admin
                                                  ? "inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800"
                                                  : "hidden"
                                              )}
                                            >
                                              {item.is_sender_admin &&
                                                "Superlike 💜"}
                                            </span>
                                          </div>
                                          <p className="mt-0.5 text-sm text-gray-500">
                                            {friendlyDateTime(item.created_at)}
                                          </p>
                                        </div>
                                        <div className="mt-2 text-sm text-gray-700">
                                          <p>{item.message}</p>
                                        </div>
                                      </div>
                                    </>
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>

                        <div className="border-t" />

                        <div className="relative">
                          <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
                            <textarea
                              rows={3}
                              className="block w-full py-3 border-0 resize-none focus:ring-0 sm:text-sm"
                              placeholder="Send a message ✏️"
                              onChange={(e) => setMessage(e.target.value)}
                            />
                          </div>
                        </div>

                        {/* Earnings */}
                        <div className="mt-4">
                          <label className="block text-sm font-medium text-gray-700">
                            Status
                          </label>
                          <fieldset className="mt-4">
                            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                              {statuses.map((item, key) => (
                                <div key={key} className="flex items-center">
                                  <input
                                    id={item.status}
                                    name="notification-method"
                                    type="radio"
                                    defaultChecked={
                                      item.status === model.status
                                    }
                                    onChange={(e) =>
                                      setStatus(
                                        e.target.checked
                                          ? item.status
                                          : model.status
                                      )
                                    }
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                  />
                                  <label
                                    htmlFor={item.status}
                                    className="ml-3 block text-sm font-medium text-gray-700"
                                  >
                                    {item.description}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleSubmit()}
                      >
                        {"Save"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
