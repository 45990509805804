import { Fragment, useState } from "react";
import { Switch, Dialog, Transition, Listbox } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import ReactPlayer from "react-player";
import { db } from "../../../firebase";
import { collection, addDoc } from "firebase/firestore";
import Select from "react-select";
import { classNames, friendlyInMB, storageUpload } from "../../../utils";

export default function Modal({ ...props }) {
  const { model, setModel, events, setData, open, setOpen } = props;
  const eventOptions = () => {
    return events.map((x) => ({
      value: x.id,
      label: x.title,
    }));
  };
  const [submitActive, setSubmitActive] = useState(true);
  const [selectedEvent, setSelectedEvent] = useState(
    model ? eventOptions().find((x) => x.value === model.eventId) : null
  );
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(
    model ? model.media.image.url : null
  );
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(
    model ? model.media.video.url : null
  );
  const initPayload = {
    created_at: Math.floor(Date.now() / 1000),
    active: true,
    eventId: "",
  };
  const [payload, setPayload] = useState(model || initPayload);

  const handleClose = () => {
    setModel(null);
    setOpen(!open);
  };

  const handleCoverImageChange = (e) => {
    setCoverImagePreview(URL.createObjectURL(e.target.files[0]));
    setCoverImage(e.target.files[0]);
  };

  const handleVideoChange = (e) => {
    setVideoPreview(URL.createObjectURL(e.target.files[0]));
    setVideo(e.target.files[0]);
  };

  const handleSubmit = async () => {
    setSubmitActive(!submitActive);

    try {
      payload.media = {
        image: await storageUpload(coverImage, "cover-images"),
        video: await storageUpload(video, "inspired-videos"),
      };
      payload.eventId = selectedEvent.value;
      await addDoc(collection(db, "inspired_videos"), payload);
      setData((prev) => [...prev, payload]);
      setOpen(!open);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={handleClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Inspired Videos <span className="text-gray-500 text-sm">{model ? model.id : ""}</span>
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => handleClose()}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}

                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-6 px-4 py-5 sm:p-6">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Event
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <Select
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={selectedEvent}
                              onChange={setSelectedEvent}
                              options={eventOptions()}
                            />
                          </div>
                        </div>

                        {/* Cover Image */}
                        <div className="border p-2 rounded-lg">
                          <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                              Cover Image
                            </label>
                            <div>
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() =>
                                  document.getElementById("coverImage").click()
                                }
                              >
                                Browse
                              </button>
                              <input
                                id="coverImage"
                                type="file"
                                className="sr-only"
                                accept="image/*"
                                onChange={(e) => handleCoverImageChange(e)}
                              />
                            </div>
                          </div>
                          {coverImagePreview && (
                            <div className="p-4 mt-2">
                              <div className="flex items-center justify-center">
                                <img
                                  className="rounded-lg"
                                  src={coverImagePreview}
                                />
                              </div>

                              {coverImage && (
                                <span className="block mt-1 text-gray-600 text-xs font-bold">
                                  {"Boyut: "} {friendlyInMB(coverImage.size)}{" "}
                                  {"MB"}{" "}
                                </span>
                              )}
                            </div>
                          )}
                        </div>

                        {/* Video */}
                        <div className="border p-2 rounded-lg">
                          <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                              Video
                            </label>
                            <div>
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() =>
                                  document.getElementById("video").click()
                                }
                              >
                                Browse
                              </button>
                              <input
                                id="video"
                                type="file"
                                className="sr-only"
                                accept="video/*"
                                onChange={(e) => handleVideoChange(e)}
                              />
                            </div>
                          </div>
                          {videoPreview && (
                            <div className="p-4 mt-2">
                              <div className="flex items-center justify-center">
                                <ReactPlayer
                                  className="m-auto"
                                  width={290}
                                  // height={380}
                                  playing
                                  muted
                                  controls
                                  loop
                                  url={videoPreview}
                                />
                              </div>

                              {video && (
                                <span className="block mt-1 text-gray-600 text-xs font-bold">
                                  {"Boyut: "} {friendlyInMB(video.size)} {"MB"}{" "}
                                </span>
                              )}
                            </div>
                          )}
                        </div>

                        <div>
                          <div>
                            <legend className="text-sm font-medium text-gray-900">
                              {"Hidden / active"}
                            </legend>
                          </div>

                          <div className="space-y-5">
                            <div className="space-y-5 sm:mt-0">
                              <div className="flex">
                                <Switch.Group
                                  as="div"
                                  className="flex items-center"
                                >
                                  <Switch
                                    defaultChecked={payload.active}
                                    onChange={(e) =>
                                      setPayload({
                                        ...payload,
                                        active: !payload.active,
                                      })
                                    }
                                    className={classNames(
                                      payload.active
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        payload.active
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleSubmit()}
                      >
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
