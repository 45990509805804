import React, { useState } from "react";
import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";

const Personal = ({ ...props }) => {
  const { file, setFile, imgPreview, setImgPreview, payload, setPayload } =
    props;
  const { previousStep, nextStep, isLastStep } = useWizard();
  const handleImageChange = (e) => {
    setImgPreview(URL.createObjectURL(e.target.files[0]));
    setFile(e.target.files[0]);
  };

  const children = () => {
    return (
      <div className="max-w-xs mx-auto">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Ad Soyad
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              minLength={4}
              defaultValue={payload.personal.fullname}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  personal: {
                    ...payload.personal,
                    fullname: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="mt-3">
          <label className="block text-sm font-medium text-gray-700">
            Şehir
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="text"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              defaultValue={payload.personal.city}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  personal: {
                    ...payload.personal,
                    city: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>

        {/* <div className="mt-3">
          <label className="block text-sm font-medium text-gray-700">Bio</label>
          <div className="mt-1">
            <textarea
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={payload.personal.description}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  personal: {
                    ...payload.personal,
                    description: e.target.value,
                  },
                })
              }
            />
          </div>
        </div> */}

        <div className="mt-3">
          <label className="block text-sm font-medium text-gray-700">
            Profil Fotoğrafı
          </label>
          <div className="mt-1 flex justify-between items-center">
            {imgPreview ? (
              <img
                className="w-48 h-48 mx-auto rounded-full object-cover"
                src={imgPreview}
                alt=""
              />
            ) : (
              <span className="inline-block h-48 w-48 overflow-hidden rounded-full bg-gray-100">
                <svg
                  className="h-full w-full text-gray-300"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
              </span>
            )}

            <button
              type="button"
              className="ml-5 rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => document.getElementById("photo").click()}
            >
              Değiştir
            </button>
            <input
              id="photo"
              type="file"
              className="sr-only"
              accept="image/*"
              onChange={(e) => handleImageChange(e)}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Kullanıcı Bilgileri"}
        subTitle={""}
        previousStep={previousStep}
        nextStep={nextStep}
        children={children()}
        isLastStep={isLastStep}
        disableNextStep={!payload.personal.fullname && !payload.personal.city}
      />
    </>
  );
};

export default Personal;
