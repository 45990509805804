import { useState } from "react";
import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import { db } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";
import { useAuth } from "../../../contexts/AuthContext";
import { storageUpload, normalizedText } from "../../../utils";

const Password = ({ ...props }) => {
  const { file, payload, setPayload } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();
  const [passw, setPassw] = useState("");
  const [loader, setLoader] = useState(false);
  const { signUp, login } = useAuth();

  const handleSubmit = async () => {
    setLoader(true);

    payload.media = { image: await storageUpload(file, "creator-images") };
    const search = {
      username: normalizedText(payload.username),
      fullname: normalizedText(payload.personal.fullname),
      description: normalizedText(payload.personal.description),
    };
    search.all = Object.values(search).join(" ");
    payload.search = search;

    await signUp(payload.email, payload.password)
      .then((userCredential) => {
        const user = userCredential.user;
        payload.uid = user.uid;

        setDoc(doc(db, "users", payload.username), payload);
        nextStep()
      })
      .then(() => setLoader(!loader))
      .catch((error) => {
        console.error(error);
        setLoader(!loader);
      });

    await login(payload.email, payload.password);
  };

  const children = () => {
    return (
      <>
        <div className="max-w-xs mx-auto">
          <div>
            <label className="block text-sm font-medium text-gray-700">
              Parola
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="password"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Minimum 6 hane olmalıdır"
                onChange={(e) =>
                  setPayload({ ...payload, password: e.target.value })
                }
              />
            </div>
          </div>

          <div className="mt-3">
            <label className="block text-sm font-medium text-gray-700">
              Parola Tekrar
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="password"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                placeholder="Minimum 6 hane olmalıdır"
                onChange={(e) => setPassw(e.target.value)}
              />
            </div>
            {passw && payload.password && passw !== payload.password && (
              <p className="mt-2 text-sm text-red-600">
                {"Parolalar eşleşmiyor"}
              </p>
            )}
            {passw && payload.password && passw.length < 6 && (
              <p className="mt-2 text-sm text-red-600">
                {"Parolanız minimum 6 hane olmalıdır"}
              </p>
            )}
          </div>

          <p className="mt-5 text-xs text-gray-500">
            Kayıt olduğunuz takdirde{" "}
            <a
              href="/kvkk"
              className="text-indigo-800"
              target="_blank"
              rel="noreferrer"
            >
              KVKK Aydınlatma Metnini
            </a>{" "}
            ve{" "}
            <a
              href="/uyelik-sozlesmesi"
              className="text-indigo-800"
              target="_blank"
              rel="noreferrer"
            >
              Üyelik Sözleşmesi
            </a>{" "}
            ni kabul etmiş olacaksınız.
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <StepBase
        title={"Parola Belirleyin"}
        subTitle={"Neredeyse bitti!"}
        previousStep={previousStep}
        nextStep={handleSubmit}
        children={children()}
        isLastStep={isLastStep}
        disableNextStep={loader}
      />
    </>
  );
};

export default Password;
