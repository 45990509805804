import { Fragment, useState } from "react";
import { Switch, Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { db } from "../../../firebase";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { sortArrayByColumn, classNames, normalizedText } from "../../../utils";
import { uuidv4 } from "@firebase/util";

export default function Modal({ ...props }) {
  const { model, setModel, data, setData, open, setOpen, brands } = props;
  const initPayload = {
    created_at: Math.floor(Date.now() / 1000),
    active: true,
    isConfirmed: true,
    title: "",
    email: "",
    phone: "",
    brands: [],
    userId: "",
    origin: "",
    selector: "",
    infrastructure: "",
  };
  const [payload, setPayload] = useState(model || initPayload);
  const [brandItems, setBrandItems] = useState(model ? model.brands : []);

  const handleClose = () => {
    setOpen(!open);
    setModel(null);
  };

  const handleAddBrand = () => {
    setBrandItems((prev) => [
      ...prev,
      {
        uid: uuidv4(),
        brandId: null
      },
    ]);
  };

  const handleRemoveBrand = (uid) => {
    setBrandItems(brandItems.filter((x) => x.uid !== uid));
  };

  const handleBrandSelected = (item, value) => {
    const brand = brandItems.find((x) => x.uid === item.uid);
    brand.brandId = value;
    const temp = brandItems.filter((x) => x.uid !== item.uid);
    temp.push(brand);
    setBrandItems(temp);
  };

  const handleSubmit = async () => {
    payload.search = {
      title: normalizedText(payload.title),
    };
    payload.brands = brandItems;

    try {
      if (model) {
        const docRef = doc(db, "customers", payload.id);
        await setDoc(docRef, payload, { merge: true });
        const tempList = data.filter((x) => x.id !== payload.id);
        tempList.push(payload);
        setData(sortArrayByColumn(tempList, "created_at"));
      } else {
        await addDoc(collection(db, "customers"), payload);
        setData((prev) => [...prev, payload]);
      }
      handleClose();
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={handleClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Customer{" "}
                            <span className="text-gray-500 text-sm">
                              {model ? model.id : ""}
                            </span>
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => handleClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-6 px-4 py-5 sm:p-6">
                        {/* Title */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Title
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.title}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Email */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            E-Mail
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.email}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  email: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Phone */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Phone
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.phone}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  phone: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* UserId */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            UserId
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.userId}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  userId: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Infrastructure */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Infrastructure
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.infrastructure}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  infrastructure: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Origin */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Origin
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.origin}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  origin: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Selector */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Selector
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.selector}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  selector: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Brands */}
                        <div className="border p-5 rounded-lg">
                          <div className="flex justify-between items-center border-b">
                            <h3 className="mb-2 text-lg">Brands</h3>
                            <button
                              type="button"
                              className="mb-2 rounded-md border border-green-300 bg-green py-2 px-4 text-sm font-medium text-green-700 shadow-sm hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                              onClick={() => handleAddBrand()}
                            >
                              + New
                            </button>
                          </div>
                          <div className="mt-4">
                            {brandItems.map((brand, key) => (
                              <div key={key}>
                                <div className="flex justify-between items-center gap-6">
                                  <div className="w-full">
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                      <select
                                        className="w-full border border-gray-600 rounded-lg"
                                        defaultValue={brand.brandId}
                                        onChange={(e) => {
                                          handleBrandSelected(
                                            brand,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {brands.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  <div
                                    className="text-red-600 mt-1 cursor-pointer font-bold"
                                    onClick={() =>
                                      handleRemoveBrand(brand.uid)
                                    }
                                  >
                                    Delete
                                  </div>
                                </div>
                                <div className="mt-1 border-b-2 border-dashed p-1" />
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Inactive/Active */}
                        <div>
                          <div>
                            <legend className="text-sm font-medium text-gray-900">
                              {"Inactive / Active"}
                            </legend>
                          </div>

                          <div className="space-y-5">
                            <div className="space-y-5 sm:mt-0">
                              <div className="flex">
                                <Switch.Group
                                  as="div"
                                  className="flex items-center"
                                >
                                  <Switch
                                    defaultChecked={payload.active}
                                    onChange={(e) =>
                                      setPayload({
                                        ...payload,
                                        active: !payload.active,
                                      })
                                    }
                                    className={classNames(
                                      payload.active
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        payload.active
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        disabled={!payload.title}
                        onClick={() => handleSubmit()}
                      >
                        {model ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
