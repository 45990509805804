import { Fragment, useEffect, useState } from "react";
import { Switch, Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { uuidv4 } from "@firebase/util";
import { db } from "../../../firebase";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import Flatpickr from "react-flatpickr";
import { Turkish } from "flatpickr/dist/l10n/tr";
import "flatpickr/dist/themes/light.css";
import Select from "react-select";
import {
  classNames,
  friendlyInMB,
  storageUpload,
  normalizedText,
  findMultipleValues,
} from "../../../utils";
import { SketchPicker } from "react-color";

const earningMethods = [
  { id: "MONEY", title: "Money" },
  { id: "SCORE", title: "Score" },
];
const eventTypes = [
  { value: "REELS", label: "Reels" },
  { value: "POST", label: "Post" },
  { value: "STORY", label: "Story" },
  { value: "LIVE", label: "Live" },
];
const eventTargets = [
  { value: "INSTAGRAM", label: "Instagram" },
  { value: "TIKTOK", label: "Tiktok" },
  { value: "OTHER", label: "Other" },
];

export default function Modal({ ...props }) {
  const { model, data, setData, open, setOpen, setModel, products } = props;
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(
    model && model.media ? model.media.image.url : null
  );
  const [submitActive, setSubmitActive] = useState(true);
  const [productItems, setProductItems] = useState(model ? model.products : []);
  const [creators, setCreators] = useState(model ? model.creators || [] : []);
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [selectedEventTargets, setSelectedEventTargets] = useState([]);
  const [cardColor, setCardColor] = useState({
    hex: model && model.colors ? model.colors.card : "#FFF",
  });
  const [textColor, setTextColor] = useState({
    hex: model && model.colors ? model.colors.text : "#000",
  });
  const [shortTextColor, setShortTextColor] = useState({
    hex: model && model.colors ? model.colors.short : "#000",
  });
  const presetColors = ["#64748b", "#FFF", "#000"];

  const flatPickerOptions = {
    // dateFormat: "d.m.Y H:m",
    // locale: "tr",
  };
  const initPayload = {
    created_at: Math.floor(Date.now() / 1000),
    uid: uuidv4(),
    active: true, // Aktif / Pasif
    eventTypes: ["REELS"],
    eventTargets: ["INSTAGRAM"],
    title: "", //  Url de kullanilabilir mi? Dinamik page yapisi
    short: "", //
    content: "", // long description
    earningType: "MONEY",
    earningValue: 0,
    earningLimit: null,
    minScore: 0,
    constraints: {
      startAt: null,
      salesExpireAt: null,
      videoExpireAt: null,
      rateExpireAt: null, // Optional
      endAt: null,
    },
    products: [],
    creators: [],
    colors: {
      card: "",
      text: "",
      short: "",
    },
  };
  const [payload, setPayload] = useState(model || initPayload);

  const handleModalOnClose = () => {
    setOpen(!open);
    setModel(null);
  };

  const handleCoverImageChange = (e) => {
    setCoverImagePreview(URL.createObjectURL(e.target.files[0]));
    setCoverImage(e.target.files[0]);
  };

  const handleDateTimePicker = (dateProp) => {
    return dateProp ? new Date(dateProp * 1000).toISOString() : null;
  };

  const handleAddProduct = () => {
    setProductItems((prev) => [
      ...prev,
      {
        uid: uuidv4(),
        productId: products.lenght > 0 ? products[0].id : null,
        stock: 1,
      },
    ]);
  };

  const handleRemoveProduct = (uid) => {
    const temp = productItems.filter((x) => x.uid !== uid);
    setProductItems(temp);
  };

  const handleAddCreator = () => {
    setCreators((prev) => [
      ...prev,
      {
        uid: uuidv4(),
        username: null,
      },
    ]);
  };

  const handleRemoveCreator = (uid) => {
    const temp = creators.filter((x) => x.uid !== uid);
    setCreators(temp);
  };

  const handleSubmit = async () => {
    setSubmitActive(!submitActive);

    if (coverImage) {
      payload.media = {
        image: await storageUpload(coverImage, "event-images"),
      };
    }

    const handleSavedTime = (time) => {
      if (time) {
        return !Number.isInteger(time)
          ? Math.floor(new Date(time).getTime() / 1000)
          : time;
      }

      return null;
    };

    try {
      payload.constraints.startAt = handleSavedTime(
        payload.constraints.startAt
      );
      payload.constraints.salesExpireAt = handleSavedTime(
        payload.constraints.salesExpireAt
      );
      payload.constraints.videoExpireAt = handleSavedTime(
        payload.constraints.videoExpireAt
      );
      payload.constraints.rateExpireAt = handleSavedTime(
        payload.constraints.rateExpireAt
      );
      payload.constraints.endAt = handleSavedTime(payload.constraints.endAt);
      payload.products = productItems;
      payload.creators = creators;
      payload.eventTypes = selectedEventTypes.map((x) => {
        return x.value;
      });
      payload.eventTargets = selectedEventTargets.map((x) => {
        return x.value;
      });

      payload.colors = {
        card: cardColor.hex,
        text: textColor.hex,
        short: shortTextColor.hex,
      };

      payload.search = {
        title: normalizedText(payload.title),
        short: normalizedText(payload.short),
        content: normalizedText(payload.content),
      };

      if (model) {
        const docRef = doc(db, "events", model.id);
        await setDoc(docRef, payload, { merge: true });
        const tempList = data.filter((x) => x.id !== model.id);
        tempList.push(payload);
        setData(tempList);
      } else {
        await addDoc(collection(db, "events"), payload);
        setData((prev) => [...prev, payload]);
      }
      setOpen(!open);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  const handleProductSelected = (item, value) => {
    const product = productItems.find((x) => x.uid === item.uid);
    product.productId = value;
    const temp = productItems.filter((x) => x.uid !== item.uid);
    temp.push(product);
    setProductItems(temp);
  };

  const handleProductStock = (item, value) => {
    const product = productItems.find((x) => x.uid === item.uid);
    product.stock = value;
    const temp = productItems.filter((x) => x.uid !== item.uid);
    temp.push(product);
    setProductItems(temp);
  };

  const handleCreatorUsername = (item, value) => {
    const creator = creators.find((x) => x.uid === item.uid);
    creator.username = value;
    const temp = creators.filter((x) => x.uid !== item.uid);
    temp.push(creator);
    setCreators(temp);
  };

  useEffect(() => {
    findMultipleValues(payload.eventTypes, eventTypes, setSelectedEventTypes);
    findMultipleValues(
      payload.eventTargets,
      eventTargets,
      setSelectedEventTargets
    );
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={handleModalOnClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Event{" "}
                            <span className="text-gray-500 text-sm">
                              {model ? model.id : ""}
                            </span>
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => handleModalOnClose}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}
                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-6 px-4 py-5 sm:p-6">
                        {/* Hidden / Visible */}
                        <div>
                          <div>
                            <legend className="text-sm font-medium text-gray-900">
                              {"Hidden / Visible"}
                            </legend>
                          </div>

                          <div className="space-y-5">
                            <div className="space-y-5 sm:mt-0">
                              <div className="flex">
                                <Switch.Group
                                  as="div"
                                  className="flex items-center"
                                >
                                  <Switch
                                    defaultChecked={payload.active}
                                    onChange={(e) =>
                                      setPayload({
                                        ...payload,
                                        active: !payload.active,
                                      })
                                    }
                                    className={classNames(
                                      payload.active
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        payload.active
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Event Types */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Event Types
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <Select
                              isMulti
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              value={selectedEventTypes}
                              onChange={setSelectedEventTypes}
                              options={eventTypes}
                            />
                          </div>
                        </div>

                        {/* Event Targets */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Event Targets
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <Select
                              isMulti
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              value={selectedEventTargets}
                              onChange={setSelectedEventTargets}
                              options={eventTargets}
                            />
                          </div>
                        </div>

                        {/* Minimum Score (>) */}
                        <div className="mt-2">
                          <label className="block text-sm font-medium text-gray-700">
                            {"Minimum Score to Join"}
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.minScore}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  minScore: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Title */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Title
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.title}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  title: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Short Description */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Short Description
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.short}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  short: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Full Description */}
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Full Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={3}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={payload.content}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  content: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        {/* Cover Image */}
                        <div className="border p-2 rounded-lg">
                          <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                              Cover Image
                            </label>
                            <div>
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() =>
                                  document.getElementById("coverImage").click()
                                }
                              >
                                Browse
                              </button>
                              <input
                                id="coverImage"
                                type="file"
                                className="sr-only"
                                accept="image/*"
                                onChange={(e) => handleCoverImageChange(e)}
                              />
                            </div>
                          </div>
                          {coverImagePreview && (
                            <div className="p-4 mt-2">
                              <div className="flex items-center justify-center">
                                <img
                                  className="rounded-lg"
                                  src={coverImagePreview}
                                />
                              </div>

                              {coverImage && (
                                <span className="block mt-1 text-gray-600 text-xs font-bold">
                                  {"Boyut: "} {friendlyInMB(coverImage.size)}{" "}
                                  {"MB"}{" "}
                                </span>
                              )}
                            </div>
                          )}
                        </div>

                        {/* Card Colors */}
                        <div className="border p-2 rounded-lg">
                          <label className="block text-sm font-medium text-gray-700">
                            Card Designer
                          </label>
                          <div className="mt-3 flex justify-between items-center gap-5">
                            <div>
                              <div>
                                <small className="text-gray-600 font-medium block">
                                  Card Color
                                </small>
                                <SketchPicker
                                  className="mb-3"
                                  color={cardColor}
                                  presetColors={presetColors}
                                  onChangeComplete={(color, event) => {
                                    setCardColor(color);
                                  }}
                                />
                              </div>
                              <div>
                                <small className="text-gray-600 font-medium block">
                                  Text Color
                                </small>
                                <SketchPicker
                                  className="mb-3"
                                  presetColors={presetColors}
                                  color={textColor}
                                  onChangeComplete={(color, event) => {
                                    setTextColor(color);
                                  }}
                                />
                              </div>
                              <div>
                                <small className="text-gray-600 font-medium block">
                                  Short Color
                                </small>
                                <SketchPicker
                                  color={shortTextColor}
                                  presetColors={presetColors}
                                  onChangeComplete={(color, event) => {
                                    setShortTextColor(color);
                                  }}
                                />
                              </div>
                            </div>
                            <div className="max-w-xs rounded-xl border overflow-hidden mx-auto">
                              <div className="md:shrink-0">
                                <img
                                  className="h-72 w-full object-cover"
                                  src={coverImagePreview}
                                />
                              </div>
                              <div
                                className="p-8"
                                style={{ backgroundColor: cardColor.hex }}
                              >
                                <p
                                  className="block mt-1 text-lg leading-tight font-bold"
                                  style={{ color: textColor.hex }}
                                >
                                  {payload.title}
                                </p>
                                <p
                                  className="mt-4"
                                  style={{ color: shortTextColor.hex }}
                                >
                                  {payload.short}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>

                        {/* Earnings */}
                        <div className="border p-5 rounded-lg">
                          <h3 className="text-lg border-b">Earnings</h3>
                          <div className="mt-4">
                            <label className="block text-sm font-medium text-gray-700">
                              Earning Type
                            </label>
                            <fieldset className="mt-4">
                              <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                                {earningMethods.map((method) => (
                                  <div
                                    key={method.id}
                                    className="flex items-center"
                                  >
                                    <input
                                      id={method.id}
                                      name="notification-method"
                                      type="radio"
                                      defaultChecked={
                                        method.id === payload.earningType
                                      }
                                      onChange={(e) =>
                                        setPayload({
                                          ...payload,
                                          earningType: e.target.checked
                                            ? method.id
                                            : payload.earningType,
                                        })
                                      }
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                                    />
                                    <label
                                      htmlFor={method.id}
                                      className="ml-3 block text-sm font-medium text-gray-700"
                                    >
                                      {method.title}
                                    </label>
                                  </div>
                                ))}
                              </div>
                            </fieldset>
                          </div>

                          {/* Earning Value */}
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Earning Value
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <input
                                type="number"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={payload.earningValue}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    earningValue: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>

                          {/* Earning Limit */}
                          <div className="mt-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Earning Limit
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <input
                                type="number"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={payload.earningLimit}
                                onChange={(e) =>
                                  setPayload({
                                    ...payload,
                                    earningLimit: e.target.value,
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        {/* Constraints */}
                        <div className="border p-5 rounded-lg">
                          <h3 className="mb-2 text-lg border-b">Constraints</h3>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Start Date
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <Flatpickr
                                data-enable-time
                                options={flatPickerOptions}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={handleDateTimePicker(
                                  payload.constraints.startAt
                                )}
                                onChange={(date) =>
                                  setPayload({
                                    ...payload,
                                    constraints: {
                                      ...payload.constraints,
                                      startAt: date[0],
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Sales Expire Date
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <Flatpickr
                                data-enable-time
                                options={flatPickerOptions}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={handleDateTimePicker(
                                  payload.constraints.salesExpireAt
                                )}
                                onChange={(date) =>
                                  setPayload({
                                    ...payload,
                                    constraints: {
                                      ...payload.constraints,
                                      salesExpireAt: date[0],
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Video Upload Expire Date
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <Flatpickr
                                data-enable-time
                                options={flatPickerOptions}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={handleDateTimePicker(
                                  payload.constraints.videoExpireAt
                                )}
                                onChange={(date) =>
                                  setPayload({
                                    ...payload,
                                    constraints: {
                                      ...payload.constraints,
                                      videoExpireAt: date[0],
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Rating Expire Date
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <Flatpickr
                                data-enable-time
                                options={flatPickerOptions}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={handleDateTimePicker(
                                  payload.constraints.rateExpireAt
                                )}
                                onChange={(date) =>
                                  setPayload({
                                    ...payload,
                                    constraints: {
                                      ...payload.constraints,
                                      rateExpireAt: date[0],
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              End Date
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <Flatpickr
                                data-enable-time
                                options={flatPickerOptions}
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={handleDateTimePicker(
                                  payload.constraints.endAt
                                )}
                                onChange={(date) =>
                                  setPayload({
                                    ...payload,
                                    constraints: {
                                      ...payload.constraints,
                                      endAt: date[0],
                                    },
                                  })
                                }
                              />
                            </div>
                          </div>
                        </div>

                        {/* Products */}
                        <div className="border p-5 rounded-lg">
                          <div className="flex justify-between items-center border-b">
                            <h3 className="mb-2 text-lg">Products</h3>
                            <button
                              type="button"
                              className="mb-2 rounded-md border border-green-300 bg-green py-2 px-4 text-sm font-medium text-green-700 shadow-sm hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                              onClick={() => handleAddProduct()}
                            >
                              + New
                            </button>
                          </div>
                          <div className="mt-4">
                            {productItems.map((product, key) => (
                              <div key={key}>
                                <div className="flex justify-between items-center gap-6">
                                  <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                      Product
                                    </label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                      <select
                                        className="w-full border border-gray-600 rounded-lg"
                                        defaultValue={product.productId}
                                        onChange={(e) => {
                                          handleProductSelected(
                                            product,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {products.map((item) => (
                                          <option key={item.id} value={item.id}>
                                            {item.name}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>

                                  <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                      Stock
                                    </label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                      <input
                                        type="number"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-20 sm:text-sm border-gray-300 rounded-md"
                                        defaultValue={product.stock}
                                        min="1"
                                        onChange={(e) =>
                                          handleProductStock(
                                            product,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="text-red-600 mt-1 cursor-pointer font-bold"
                                    onClick={() =>
                                      handleRemoveProduct(product.uid)
                                    }
                                  >
                                    Delete
                                  </div>
                                </div>
                                <div className="mt-1 border-b-2 border-dashed p-1" />
                              </div>
                            ))}
                          </div>
                        </div>

                        {/* Creators */}
                        <div className="border p-5 rounded-lg">
                          <div className="flex justify-between items-center border-b">
                            <h3 className="mb-2 text-lg">Creators</h3>
                            <button
                              type="button"
                              className="mb-2 rounded-md border border-green-300 bg-green py-2 px-4 text-sm font-medium text-green-700 shadow-sm hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                              onClick={() => handleAddCreator()}
                            >
                              + New
                            </button>
                          </div>
                          <div className="mt-4">
                            {creators.map((creator, key) => (
                              <div key={key}>
                                <div className="flex justify-between items-center">
                                  <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                      Username
                                    </label>
                                    <div className="mt-1 relative rounded-md shadow-sm">
                                      <input
                                        type="text"
                                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                        defaultValue={creator.username}
                                        onChange={(e) =>
                                          handleCreatorUsername(
                                            creator,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>

                                  <div
                                    className="text-red-600 mt-1 cursor-pointer font-bold"
                                    onClick={() =>
                                      handleRemoveCreator(creator.uid)
                                    }
                                  >
                                    Delete
                                  </div>
                                </div>
                                <div className="mt-1 border-b-2 border-dashed p-1" />
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleModalOnClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className={classNames(
                          "inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          // !handleFormValidation() ? "opacity-60" : ""
                        )}
                        // disabled={!handleFormValidation()}
                        onClick={() => handleSubmit()}
                        // onClick={() => console.log(selectedEventTypes)}
                      >
                        {model ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
