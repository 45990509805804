import Product from "../Cards/Product/Product";

export default function Products({ ...props }) {
  const { products, brands } = props;

  return (
    products && brands && (
      <div className="bg-[#B7E5DD]">
        <div className="mx-auto max-w-2xl py-8 px-4 sm:py-8 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="px-4 sm:px-6 sm:flex sm:items-center sm:justify-between lg:px-8 xl:px-0">
            <h2 className="text-2xl font-extrabold tracking-tight text-black">
              En Çok İncelenen Ürünleri Keşfedin
            </h2>
            <a
              href="/products"
              className="hidden text-sm font-semibold text-black sm:block"
            >
              Tümünü Gör<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>

          <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-5 xl:gap-x-8">
            {products
              .filter((x) => x.media && x.media.images && x.media.images.length > 0)
              .map((product, key) => (
                <div key={key} className="w-56 mx-auto">
                  <Product
                    brand={brands.find((x) => x.id === product.brandId)}
                    product={product}
                  />
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  );
}
