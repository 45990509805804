import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { auth } from "./firebase";

export default function RequireAuth() {
  const navigate = useNavigate();
  const admins = [
    "Ycfglg9SoRNNJ306sVrNfRbOHxz2", // hello@supeerlike.com
    "aQ01vE1whOMB8O6VnjNwlo6zQsl2", // alihan@supeerlike.com
    "tCbPHYTqYHNkuJU0Qdw285dAznj1", // guvencliezgi@gmail.com
    // "aMy5djPlJEWUfSTkEswybuj4YdC3", // kberfk@gmail.com
    "0Ieg9qdUOjae2fRiKnwOmADU7VE3", // efsuncalis@gmail.com
  ];

  useEffect(() => {
    auth.onAuthStateChanged((user) => {
      const admin = admins.includes(user.uid) && user;

      if (!admin) {
        navigate("/");
      }
    });
    // eslint-disable-next-line
  }, []);

  return <Outlet />;
}
