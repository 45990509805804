import {
  HomeIcon,
  UserIcon,
  PhotographIcon,
  CubeIcon,
  BriefcaseIcon,
  VideoCameraIcon,
  TagIcon,
  CursorClickIcon,
  BellIcon,
  ShoppingCartIcon,
  LightBulbIcon,
  CollectionIcon
} from "@heroicons/react/outline";
import Dashboard from "./views/Admin/Dashboard/Dashboard";
import Events from "./views/Admin/Events/Events";
import Users from "./views/Admin/Users/Users";
import Products from "./views/Admin/Products/Products";
import Brands from "./views/Admin/Brands/Brands";
import Videos from "./views/Admin/Videos/Videos";
import Tags from "./views/Admin/Tags/Tags";
import BrandApplicant from "./views/Admin/BrandApplicant/BrandApplicant";
import InspiredVideos from "./views/Admin/InspiredVideos/InspiredVideos";
import Orders from "./views/Admin/Orders/Orders";
import Tickets from "./views/Admin/Tickets/Tickets";
import Customers from "./views/Admin/Customers/Customers";

export const routes = [
  {
    active: true,
    name: "Dashboard",
    href: "/admin/dashboard",
    icon: HomeIcon,
    component: <Dashboard />,
  },
  {
    active: true,
    name: "Events",
    href: "/admin/events",
    icon: CursorClickIcon,
    component: <Events />,
  },
  {
    active: true,
    name: "Inspired Videos",
    href: "/admin/inspired-videos",
    icon: VideoCameraIcon,
    component: <InspiredVideos />,
  },
  {
    active: true,
    name: "Users",
    href: "/admin/users",
    icon: UserIcon,
    component: <Users />,
  },
  {
    active: true,
    name: "Products",
    href: "/admin/products",
    icon: CubeIcon,
    component: <Products />,
  },
  {
    active: true,
    name: "Brands",
    href: "/admin/brands",
    icon: BriefcaseIcon,
    component: <Brands />,
  },
  {
    active: true,
    name: "Videos",
    href: "/admin/videos",
    icon: VideoCameraIcon,
    component: <Videos />,
  },
  {
    active: true,
    name: "Tags",
    href: "/admin/tags",
    icon: TagIcon,
    component: <Tags />,
  },
  {
    active: true,
    name: "Brand Applicants",
    href: "/admin/brand-applicants",
    icon: BellIcon,
    component: <BrandApplicant />,
  },
  {
    active: true,
    name: "Orders",
    href: "/admin/orders",
    icon: ShoppingCartIcon,
    component: <Orders />,
  },
  {
    active: true,
    name: "Tickets",
    href: "/admin/tickets",
    icon: LightBulbIcon,
    component: <Tickets />,
  },
  {
    active: true,
    name: "Customers",
    href: "/admin/customers",
    icon: CollectionIcon,
    component: <Customers />,
  },
];
