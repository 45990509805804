import { useState, useEffect, useRef } from "react";
import AppLayout from "../../layouts/AppLayout";
import { collection, query, getDocs, orderBy, where } from "firebase/firestore";
import { db } from "../../firebase";
import Product from "../../components/Cards/Product/Product";

export default function Products() {
  const [productsByTag, setProductsByTag] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const productQuery = query(
          collection(db, "products"),
          where("visible", "==", true),
          orderBy("created_at", "desc")
        );

        const productsnapshot = await getDocs(productQuery);
        const productList = [];
        productsnapshot.forEach((doc) => {
          productList.push({ ...doc.data(), id: doc.id });
        });

        const brandQuery = query(collection(db, "brands"));
        const brandSnapshot = await getDocs(brandQuery);
        const brandList = [];
        brandSnapshot.forEach((doc) => {
          brandList.push({ ...doc.data(), id: doc.id });
        });

        const tagQuery = query(
          collection(db, "tags"),
          where("active", "==", true)
        );
        const tagSnapshot = await getDocs(tagQuery);
        const tagList = [];
        tagSnapshot.forEach((doc) => {
          tagList.push({ ...doc.data(), id: doc.id });
        });

        const groupedProducts = [];
        tagList.map((tag) => {
          groupedProducts.push({
            ...tag,
            products: productList.filter((product) =>
              product.tags.includes(tag.id)
            ),
          });
        });
        setProductsByTag(groupedProducts);
        setTags(tagList);
        setBrands(brandList);
      }
    }
    getData();
  }, []);

  return (
    <AppLayout>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
          <h2 className="text-5xl font-bold text-black tracking-tight">
            Ürünler
          </h2>

          <div>
            {productsByTag.map((tag, key) => (
              <div key={key} className="mt-10">
                <h2 className="text-3xl font-bold text-black tracking-tight">
                  {tag.name}
                </h2>

                <div className="p-5 mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                  {tag.products.map((product, productKey) => (
                    <Product
                      brands={brands.find((x) => x.id === product.brandId)}
                      product={product}
                      key={productKey}
                    />
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
