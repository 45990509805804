import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";

const DescriptionAndLink = ({ ...props }) => {
  const { payload, setPayload, handleSubmit } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();

  const handleNext = () => {
    handleSubmit();
    nextStep();
  };

  const children = () => {
    return (
      <div>
        <div>
          <label className="block text-sm font-medium text-gray-700">
            {"Video Açıklaması *"}
            <span className="block text-xs text-gray-500">
              {"Video' da görünmesini istediğiniz açıklamayı giriniz"}
            </span>
          </label>
          <div className="mt-1">
            <textarea
              rows={3}
              defaultValue={payload.videoDescription}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={(e) =>
                setPayload({ ...payload, videoDescription: e.target.value })
              }
            />
          </div>
        </div>

        <div className="mt-5">
          <label className="block text-sm font-medium text-gray-700">
            {"Influencer Instagram Koleksiyon Linki (Varsa)"}
            {/* <span className="block text-xs text-gray-500">
              {
                "Trendyol, Hepsiburada veya istediğin koleksiyon linkini ekleyebilirsin."
              }
            </span> */}
          </label>
          <div className="mt-1">
            <input
              type="text"
              defaultValue={payload.productLink}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={(e) =>
                setPayload({ ...payload, productLink: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Açıklama ve Link"}
        subTitle={""}
        previousStep={previousStep}
        nextStep={handleNext}
        children={children()}
        isLastStep={isLastStep}
        disableNextStep={!payload.videoDescription}
      />
    </>
  );
};

export default DescriptionAndLink;
