import { useEffect, useState } from "react";
import { classNames } from "../../../utils";
import { PhotographIcon } from "@heroicons/react/outline/";

export default function Product({ ...props }) {
  const { product, brand } = props;
  const [imageLoader, setImageLoader] = useState(true);

  useEffect(() => {
    if (product.media && product.media.images && product.media.images.length === 0) {
      setImageLoader(false);
    }
  }, []);

  const LoaderCard = () => {
    return (
      <div className="max-w-7xl">
        <div className="">
          <div className="animate-pulse rounded-lg overflow-hidden">
            <div className="space-y-2">
              <div className="h-80 bg-gray-400 rounded"></div>
              <div className="h-4 bg-gray-400 rounded w-3/6"></div>
              <div className="h-4 bg-gray-400 rounded w-6/6"></div>
              <div className="h-4 bg-gray-400 rounded w-5/6"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    product && (
      <>
        <div
          className={classNames(
            "group relative",
            imageLoader ? "hidden" : "visible"
          )}
        >
          {product.media && product.media.images && product.media.images.length > 0 && (
            <div className="min-h-80 aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75 lg:aspect-none lg:h-80">
              <img
                src={product.media.images[0].url}
                alt={product.description}
                onLoad={() => setImageLoader(false)}
                className="h-full w-full object-cover object-center lg:h-full lg:w-full"
              />
            </div>
          )}
          {product.media && product.media.images && product.media.images.length === 0 && (
            <div className="flex justify-center items-center h-80 rounded-md bg-gray-200 group-hover:opacity-75">
              <PhotographIcon className="w-16 h-16 text-gray-700" />
            </div>
          )}
          <div className="mt-4">
            <p className="text-sm font-bold text-gray-500">
              {brand ? brand.name : ""}
            </p>

            <div className="mt-4">
              <h3 className="text-sm text-gray-700">
                <a href={`/product/${product.id}`}>
                  <span aria-hidden="true" className="absolute inset-0" />
                  {product.name}
                </a>
              </h3>
            </div>
          </div>
        </div>

        {imageLoader && <LoaderCard />}
      </>
    )
  );
}
