import ExampleTheme from "./themes/ExampleTheme";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";

export default function Content({ ...props }) {
  const { title, content } = props;

  const editorConfig = {
    // The editor theme
    theme: ExampleTheme,
    // Handling of errors during update
    onError(error) {
      throw error;
    },
    // Load Saved Data
    editorState: content,
    readOnly: true,
  };

  return (
    content && (
      <div className="relative py-16 bg-white overflow-hidden">
        <div className="hidden lg:block lg:absolute lg:inset-y-0 lg:h-full lg:w-full">
          <div
            className="relative h-full text-lg max-w-prose mx-auto"
            aria-hidden="true"
          >
            <svg
              className="absolute top-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="74b3fd99-0a6f-4271-bef2-e80eeafdf357"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#74b3fd99-0a6f-4271-bef2-e80eeafdf357)"
              />
            </svg>
            <svg
              className="absolute top-1/2 right-full transform -translate-y-1/2 -translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="f210dbf6-a58d-4871-961e-36d5016a0f49"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#f210dbf6-a58d-4871-961e-36d5016a0f49)"
              />
            </svg>
            <svg
              className="absolute bottom-12 left-full transform translate-x-32"
              width={404}
              height={384}
              fill="none"
              viewBox="0 0 404 384"
            >
              <defs>
                <pattern
                  id="d3eb07ae-5182-43e6-857d-35c643af9034"
                  x={0}
                  y={0}
                  width={20}
                  height={20}
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x={0}
                    y={0}
                    width={4}
                    height={4}
                    className="text-gray-200"
                    fill="currentColor"
                  />
                </pattern>
              </defs>
              <rect
                width={404}
                height={384}
                fill="url(#d3eb07ae-5182-43e6-857d-35c643af9034)"
              />
            </svg>
          </div>
        </div>
        <div className="relative px-4 sm:px-6 lg:px-8">
          <div className="text-lg max-w-prose mx-auto">
            <h1>
              <span className="block text-base text-center bg-indigo-50 p-5 rounded-lg text-indigo-600 font-semibold tracking-wide">
                Önizleme
              </span>

              {/* <img
              className="w-full rounded-lg mt-5"
              src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&auto=format&fit=facearea&w=1310&h=873&q=80&facepad=3"
              alt=""
              width={1310}
              height={873}
            /> */}

              <span className="mt-2 block text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
                {title || "Blog Title"}
              </span>
            </h1>
            <div className="mt-8 text-gray-500 leading-8">
              <LexicalComposer initialConfig={editorConfig}>
                <div className="">
                  <div className="editor-inner">
                    <RichTextPlugin
                      contentEditable={
                        <ContentEditable
                          conditEditable={false}
                          readOnly={true}
                          className="editor-input"
                        />
                      }
                    />
                  </div>
                </div>
              </LexicalComposer>
            </div>
          </div>
          {/* <div className="mt-6 max-w-prose text-gray-500 mx-auto"></div> */}
        </div>
      </div>

      // <div className="max-w-5xl mb-10 mt-10 m-auto border-2 border-purple-700 rounded-lg p-5 pointer-events-none">
      //   <LexicalComposer initialConfig={editorConfig}>
      //     <div className="">
      //       <div className="editor-inner">
      //         <RichTextPlugin
      //           contentEditable={
      //             <ContentEditable
      //               conditEditable={false}
      //               readOnly={true}
      //               className="editor-input"
      //             />
      //           }
      //         />
      //       </div>
      //     </div>
      //   </LexicalComposer>
      // </div>
    )
  );
}
