import React, { useState, useEffect, useRef } from "react";
import { Wizard } from "react-use-wizard";
import AppLayout from "../../../layouts/AppLayout";
import Header from "./Header";
import { useAuth } from "../../../contexts/AuthContext";
import { db } from "../../../firebase";
import {
  addDoc,
  query,
  collection,
  where,
  orderBy,
  getDocs,
} from "firebase/firestore";
import ContentType from "./Steps/ContentType";
import ContentOptions from "./Steps/ContentOptions";
import CoverImage from "./Steps/CoverImage";
import Video from "./Steps/Video";
import DescriptionAndLink from "./Steps/DescriptionAndLink";
import Final from "./Steps/Final";
import { storageUpload, normalizedText } from "../../../utils";
import { useNavigate } from "react-router-dom";

export default function ContentWizard() {
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(null);
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(null);
  const [loader, setLoader] = useState(false);
  const [openEvents, setOpenEvents] = useState([]);

  const initPayload = {
    created_at: Math.floor(Date.now() / 1000),
    is_confirmed: false,
    productDescription: null,
    brand: null,
    contentType: "COLLECTION", // {EVENT, COLLECTION}
    eventId: null,
    videoDescription: null,
    productLink: null,
  };
  const [payload, setPayload] = useState(initPayload);
  const pending = useRef(true);

  useEffect(() => {
    async function loadData() {
      setLoader(true);
      if (pending.current && userInfo) {
        pending.current = false;
        const openEventList = [];

        const now = Math.floor(Date.now() / 1000);
        const openEventQuery = query(
          collection(db, "events"),
          where("active", "==", true),
          where("constraints.startAt", "<=", now),
          orderBy("constraints.startAt", "desc")
        );
        const openEventQuerySnapshot = await getDocs(openEventQuery);
        openEventQuerySnapshot.forEach((doc) => {
          const openEvent = { ...doc.data(), id: doc.id };
          if (
            openEvent.constraints.endAt > now &&
            openEvent.constraints.videoExpireAt > now
          ) {
            openEventList.push(openEvent);
          }
        });

        if (openEventList.length > 0) {
          await Promise.all(
            openEventList.map(async (event) => {
              const joinCheckList = [];
              let usedLimit = 0;
              const userScore = parseInt(
                userInfo.scoring ? userInfo.scoring.score : 0
              );
              const minScore = parseInt(event.minScore);
              const earningType = event.earningType;

              // Check the min score
              if (minScore && minScore > 0) {
                joinCheckList.push({
                  permit: userScore > minScore,
                  label: "minScore",
                });
              }

              // Money Event
              if (earningType === "MONEY" && event.products.length > 0) {
                const userInList = event.creators.filter(
                  (x) => x.username === userInfo.username
                );
                joinCheckList.push({
                  permit: userInList.length > 0 ? true : false,
                  label: "creators",
                });
              } else {
                const joinerQuery = query(
                  collection(db, "event_joins"),
                  where("eventId", "==", event.id),
                  where("userId", "==", userInfo.uid)
                );
                const joinersSnapshot = await getDocs(joinerQuery);
                joinCheckList.push({
                  permit: joinersSnapshot.size > 0 ? true : false,
                  label: "creators",
                });
              }

              const info = {
                title: event.title,
                joinCheckList,
                permit: !joinCheckList.some((x) => !x.permit),
                usedLimit,
                userScore,
                minScore,
                earningType,
              };
              console.log("Join Info", info);

              const permit = !joinCheckList.some((x) => !x.permit);

              if (permit) {
                setOpenEvents((prev) => [...prev, event]);
              }
            })
          );

          setLoader(false);
        }
      } else {
        navigate("/");
      }
    }
    loadData();
  }, []);

  const handleSubmit = async () => {
    setLoader(!loader);

    try {
      payload.username = userInfo.username;
      payload.uid = userInfo.uid;
      payload.media = {
        image: await storageUpload(coverImage, "cover-images"),
        video: await storageUpload(video, "videos"),
      };

      const search = {
        brand: normalizedText(payload.brand),
        productDescription: normalizedText(payload.productDescription),
        username: normalizedText(payload.username),
        videoDescription: normalizedText(payload.videoDescription),
      };
      search.all = Object.values(search).join(" ");
      payload.search = search;
      await addDoc(collection(db, "videos"), payload);
      setLoader(!loader);
      navigate(`/creators/${userInfo.username}`);
    } catch (e) {
      console.error("Error adding document: ", e);
      setLoader(!loader);
    }
  };

  return (
    userInfo && (
      <AppLayout>
        <div className="max-w-screen-md mx-auto">
          <div className="mt-5 mb-10 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Wizard header={<Header />}>
              <ContentType
                payload={payload}
                setPayload={setPayload}
                openEvents={openEvents}
              />
              <Video
                video={video}
                setVideo={setVideo}
                videoPreview={videoPreview}
                setVideoPreview={setVideoPreview}
              />
              <CoverImage
                coverImage={coverImage}
                setCoverImage={setCoverImage}
                coverImagePreview={coverImagePreview}
                setCoverImagePreview={setCoverImagePreview}
              />
              <ContentOptions
                payload={payload}
                setPayload={setPayload}
                loader={loader}
                handleSubmit={handleSubmit}
              />
              {/* <DescriptionAndLink
                payload={payload}
                setPayload={setPayload}
                loader={loader}
                handleSubmit={handleSubmit}
              /> */}
              <Final loader={loader} />
            </Wizard>
          </div>
        </div>
      </AppLayout>
    )
  );
}
