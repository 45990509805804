import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import { db } from "../../../../firebase";
import { collection, query, where, getDocs } from "firebase/firestore";
import { useState } from "react";

const Communication = ({ ...props }) => {
  const { payload, setPayload } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();
  const [emailExist, setEmailExist] = useState(false);

  const checkEmailExist = async () => {
    const q = query(
      collection(db, "users"),
      where("email", "==", payload.email),
      where("uid", "!=", payload.uid),
    );
    const querySnapshot = await getDocs(q);
    let exist = false;
    querySnapshot.forEach((doc) => {
      exist = true;
    });
    setEmailExist(exist);
    return exist;
  };

  const isEmailValid = () => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(payload.email)
  }

  const children = () => {
    return (
      <div className="max-w-xs mx-auto">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Cep Telefonu
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="number"
              maxLength={10}
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="10 haneli cep telefon numarınızı giriniz"
              defaultValue={payload.phone}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  phone: e.target.value,
                })
              }
            />
          </div>
          {payload.phone && payload.phone.length != 10 && (
            <p className="mt-2 text-sm text-red-600">
              Numaranızı 10 haneli olacak şekilde düzenleyiniz.
            </p>
          )}
        </div>
        <div className="mt-3">
          <label className="block text-sm font-medium text-gray-700">
            Email
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <input
              type="email"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Email adresinizi giriniz"
              defaultValue={payload.email}
              onChange={(e) =>
                setPayload({ ...payload, email: e.target.value })
              }
              onBlur={checkEmailExist}
            />
          </div>
          {emailExist && (
            <p className="mt-2 text-sm text-red-600">
              Email zaten kayıtlı, lütfen farklı bir email adresi giriniz.
            </p>
          )}
        </div>
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"İletişim"}
        subTitle={""}
        previousStep={previousStep}
        nextStep={nextStep}
        children={children()}
        disableNextStep={
          emailExist ||
          !payload.email ||
          !payload.phone ||
          payload.phone.length != 10 ||
          !isEmailValid()
        }
        isLastStep={isLastStep}
      />
    </>
  );
};

export default Communication;
