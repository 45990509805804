import React, { useState } from "react";
import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";

const Payment = ({ ...props }) => {
  const { payload, setPayload } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();

  const children = () => {
    return (
      <div className="max-w-md mx-auto">
        <div className="mt-5">
          <h3 className="text-lg border-b">Adres Bilgileri</h3>

          <div className="mt-3">
            <div className="mt-1 relative rounded-md shadow-sm">
              <textarea
                rows={5}
                placeholder="Kargo vb konular için bu bilgi gereklidir."
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                defaultValue={payload.personal.address}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    personal: {
                      ...payload.personal,
                      address: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>

        <div className="mt-5">
          <h3 className="text-lg border-b">Banka Bilgileri</h3>

          <div className="mt-3">
            <label className="block text-sm font-medium text-gray-700">
              TC Kimlik No
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                minLength={4}
                defaultValue={payload.personal.identityNumber}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    personal: {
                      ...payload.personal,
                      identityNumber: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="mt-3">
            <label className="block text-sm font-medium text-gray-700">
              Ad Soyad
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                minLength={4}
                defaultValue={payload.personal.bankAccountName}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    personal: {
                      ...payload.personal,
                      bankAccountName: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="mt-3">
            <label className="block text-sm font-medium text-gray-700">
              Banka Adı
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                minLength={4}
                defaultValue={payload.personal.bankName}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    personal: {
                      ...payload.personal,
                      bankName: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>

          <div className="mt-3">
            <label className="block text-sm font-medium text-gray-700">
              IBAN
            </label>
            <div className="mt-1 relative rounded-md shadow-sm">
              <input
                type="text"
                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                defaultValue={payload.personal.iban}
                onChange={(e) =>
                  setPayload({
                    ...payload,
                    personal: {
                      ...payload.personal,
                      iban: e.target.value,
                    },
                  })
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Kişisel Bilgiler"}
        subTitle={
          "Hakediş tutarlarını banka hesabına gönderebilmemiz için gereklidir."
        }
        previousStep={previousStep}
        nextStep={nextStep}
        children={children()}
        isLastStep={isLastStep}
      />
    </>
  );
};

export default Payment;
