import React, { useEffect } from "react";
import { useAuth } from "../../contexts/AuthContext";
import { Navigate } from "react-router-dom";

const Logout = () => {
  const { logout } = useAuth();
  useEffect(() => {
    localStorage.removeItem("user");
    logout();
  }, []);
  return <Navigate to="/" />;
};

export default Logout;
