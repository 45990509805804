import React, { useState } from "react";
import Content from "../../components/BlogEditor/Content";
import AppLayout from "../../layouts/AppLayout";

const ShowContent = () => {
  return (
    <AppLayout>
      <Content />
    </AppLayout>
  );
};

export default ShowContent;
