import React, { useEffect, useRef, useState } from "react";
import { collection, query, getDocs, orderBy, where } from "firebase/firestore";
import { db } from "../../../firebase";
import AdminLayout from "../../../layouts/AdminLayout";

const Dashboard = () => {
  const pending = useRef(true);
  const [data, setData] = useState([])

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        let tempData = []
        const brandRequestsQuery = query(
          collection(db, "brand_requests"),
          where('seen', '==', false)
        );
        const brandRequestsQueryResult = await getDocs(brandRequestsQuery);

        const videosQuery = query(
          collection(db, "videos"),
          where('is_confirmed', '==', false)
        );
        const videosQueryResult = await getDocs(videosQuery);

        tempData.push({name: 'New Brand Requests', value: brandRequestsQueryResult.size})
        tempData.push({name: 'Contents to Pending Approval', value: videosQueryResult.size})
        setData(tempData)
      }
    }
    getData();
  }, []);

  return (
    <AdminLayout>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
        </div>
      </div>

      <div>
        <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
          {data.map((item, key) => (
            <div
              key={item.key}
              className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6"
            >
              <dt className="text-sm font-medium text-gray-500 truncate">
                {item.name}
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {item.value}
              </dd>
            </div>
          ))}
        </dl>
      </div>
    </AdminLayout>
  );
};

export default Dashboard;
