import React, { useState } from "react";
import { Wizard } from "react-use-wizard";
import Username from "./Steps/Username";
import AppLayout from "../../layouts/AppLayout";
import Personal from "./Steps/Personal";
import Links from "./Steps/Links";
import Password from "./Steps/Password";
import Final from "./Steps/Final";
import Header from "./Header";

export default function Register() {
  const [imgPreview, setImgPreview] = useState(null);
  const [file, setFile] = useState(null);
  const initPayload = {
    referral: "",
    created_at: Math.floor(Date.now() / 1000),
    role: "pending_user",
    username: "",
    phone: "",
    email: "",
    password: "",
    personal: {
      fullname: "",
      description: "",
      city: "",
    },
    social: {
      instagram: "",
      tiktok: "",
    },
  };
  const [payload, setPayload] = useState(initPayload);

  return (
    <>
      <AppLayout>
        <div className="max-w-screen-md mx-auto">
          <div className="mt-5 mb-10 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
            <Wizard header={<Header />}>
              <Username payload={payload} setPayload={setPayload} />
              <Personal
                file={file}
                setFile={setFile}
                imgPreview={imgPreview}
                setImgPreview={setImgPreview}
                payload={payload}
                setPayload={setPayload}
              />
              <Links payload={payload} setPayload={setPayload} />
              <Password file={file} payload={payload} setPayload={setPayload} />
              <Final payload={payload} />
            </Wizard>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
