import { useWizard } from "react-use-wizard";

const Header = () => {
  const { activeStep } = useWizard();
  const steps = [
    { name: "İletişim", percentage: "0%", icon: "" },
    { name: "Kullanıcı Bilgileri", percentage: "25%", icon: "" },
    { name: "Linkler", percentage: "55.5%", icon: "" },
    { name: "Ödeme Bilgileri", percentage: "75.5%", icon: "" },
    { name: "Önizleme", percentage: "100%", icon: "" },
  ];

  return (
    <div className="mb-5">
      <div>
        <div className="bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-1 bg-indigo-600 rounded-full"
            style={{ width: steps[activeStep].percentage }}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
