import React, { useEffect, useRef, useState } from "react";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../../firebase";
import History from "./History";
import Modal from "./Modal";
import Excel from "exceljs";
import { saveAs } from "file-saver";
import InfiniteScroll from "react-infinite-scroll-component";

const Users = () => {
  const [model, setModel] = useState();
  const [open, setOpen] = useState();
  const [data, setData] = useState([]);
  const pending = useRef(true);
  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState();

  const buildQuery = () => {
    const q = [];
    q.push(orderBy("created_at", "desc"));
    q.push(limit(20));
    if (lastVisible) {
      q.push(startAfter(lastVisible));
    }
    return query(collection(db, "users"), ...q);
  };

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const querySnapshot = await getDocs(buildQuery());
        const temp = [];
        querySnapshot.forEach((doc) => {
          temp.push({ ...doc.data(), id: doc.id });
        });
        setData(temp);
        setLoader(false);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
      }
    }
    getData();
  }, []);

  const exportToExcel = async () => {
    const workSheetName = "Book1";

    const workbook = new Excel.Workbook();

    try {
      const fileName = "Users";

      // creating one worksheet in workbook
      const worksheet = workbook.addWorksheet(workSheetName);

      // add worksheet columns
      // each columns contains header and its mapping key from data
      const columns = [
        { header: "Username", key: "username" },
        { header: "Fullname", key: "fullname" },
        { header: "Score", key: "score" },
        { header: "Group", key: "group" },
        { header: "Extra Coin", key: "extra_coin" },
        { header: "Phone", key: "phone" },
        { header: "Email", key: "email" },
        { header: "IBAN", key: "iban" },
        { header: "Bank Account Name", key: "bankAccountName" },
        { header: "Bank Name", key: "bankName" },
        { header: "City", key: "city" },
        { header: "Address", key: "address" },
      ];
      worksheet.columns = columns;

      // updated the font for first row.
      worksheet.getRow(1).font = { bold: true };

      // loop through all of the columns and set the alignment with width.
      // worksheet.columns.forEach((column) => {
      //   // column.width = column.header.length + 5
      //   column.alignment = { horizontal: "center" };
      // });

      // loop through data and add each one to worksheet
      data.forEach((x) => {
        worksheet.addRow({
          username: x.username,
          fullname: x.personal.fullname,
          score: x.scoring ? x.scoring.score : 0,
          group: x.scoring ? x.scoring.group : "",
          extra_coin: x.extra_coin,
          phone: x.phone,
          email: x.email,
          iban: x.personal.iban,
          bankAccountName: x.personal.bankAccountName,
          bankName: x.personal.bankName,
          city: x.personal.city,
          address: x.personal.address,
        });
      });

      // write the content using writeBuffer
      const buf = await workbook.xlsx.writeBuffer();

      // download the processed file
      saveAs(new Blob([buf]), `${fileName}.xlsx`);
    } catch (error) {
      console.error("<<<ERRROR>>>", error);
      console.error("Something Went Wrong", error.message);
    } finally {
      // removing worksheet's instance to create new one
      workbook.removeWorksheet(workSheetName);
    }
  };

  const fetchData = async () => {
    const querySnapshot = await getDocs(buildQuery());
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        setData((prev) => [...prev, { ...doc.data(), id: doc.id }]);
      } else {
        setHasMore(false);
      }
    });
  };

  return (
    <AdminLayout>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">{`Users (${data.length})`}</h1>
        </div>
        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={exportToExcel}
          >
            {"Download"}
          </button>
        </div>
      </div>

      <InfiniteScroll
        className="overflow-x-auto -mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 rounded-lg"
        dataLength={data.length}
        next={fetchData}
        hasMore={hasMore}
        loader={loader && <h4 className="text-center">Loading...</h4>}
        endMessage={
          <p style={{ textAlign: "center" }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <History data={data} setModel={setModel} setOpen={setOpen} />
      </InfiniteScroll>

      {/* <History data={data} setModel={setModel} setOpen={setOpen} /> */}

      {open && (
        <Modal
          model={model}
          data={data}
          setData={setData}
          setOpen={setOpen}
          open={open}
        />
      )}
    </AdminLayout>
  );
};

export default Users;
