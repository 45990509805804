import { useEffect, useState, useRef } from "react";
import { db } from "../../firebase";
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  limit,
} from "firebase/firestore";
import Hero from "./Hero";
import Hero2 from "./Hero2";
import FAQ from "../../components/FAQ/FAQ";
import BlogContents from "../../components/BlogContents/BlogContents";
import Supeerlikers from "../../components/Supeerlikers/Supeerlikers";
import AppLayout from "../../layouts/AppLayout";
import Hashtags from "./Hashtags";
import OpenEvents from "./OpenEvents";
import ClosedEvents from "./ClosedEvents";
import Products from "../../components/Products/Products";
import Brands from "./Brands";
import TrendVideos from "./TrendVideos";
import FAQList from "../../FAQList";
import Leaderboard from "./LeaderBoard";
import Extensions from "./Extensions";

export default function Landing() {
  const [openEvents, setOpenEvents] = useState([]);
  const [closedEvents, setClosedEvents] = useState([]);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const now = Math.floor(Date.now() / 1000);
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const openEventQuery = query(
          collection(db, "events"),
          where("active", "==", true),
          where("constraints.startAt", "<=", now),
          orderBy("constraints.startAt", "desc")
        );
        const openEventQuerySnapshot = await getDocs(openEventQuery);
        const openEventList = [];
        openEventQuerySnapshot.forEach((doc) => {
          const openEvent = { ...doc.data(), id: doc.id };
          if (openEvent.constraints.endAt >= now && openEventList.length < 3) {
            openEventList.push(openEvent);
          }
        });
        setOpenEvents(openEventList);

        const closedEventQuery = query(
          collection(db, "events"),
          where("active", "==", true),
          where("constraints.endAt", "<=", now),
          orderBy("constraints.endAt", "desc"),
          limit(3)
        );
        const closedEventQuerySnapshot = await getDocs(closedEventQuery);
        const closedEventList = [];
        closedEventQuerySnapshot.forEach((doc) => {
          closedEventList.push({ ...doc.data(), id: doc.id });
        });
        setClosedEvents(closedEventList);

        const productQuery = query(
          collection(db, "products"),
          where("visible", "==", true),
          orderBy("landingOrder", "desc"),
          limit(5)
        );

        const productQuerySnapshot = await getDocs(productQuery);
        const productList = [];
        productQuerySnapshot.forEach((doc) => {
          productList.push({ ...doc.data(), id: doc.id });
        });
        setProducts(productList);

        const brandQuery = query(
          collection(db, "brands"),
          where("active", "==", true),
          orderBy("landingOrder", "desc"),
          limit(6)
        );
        const brandSnapshot = await getDocs(brandQuery);
        const brandList = [];
        brandSnapshot.forEach((doc) => {
          brandList.push({ ...doc.data(), id: doc.id });
        });
        setBrands(brandList);
      }
    }
    getData();
  }, []);

  return (
    <>
      <AppLayout
      // bgColor={"#571041"}
      >
        <main className="mt-5">
          {/* bg-[#571041] */}
          <Hero />
          {/* <Hashtags /> */}
          <TrendVideos />
          <Brands brands={brands} />
          {/* <Leaderboard topCreators={topCreators} /> */}
          <OpenEvents events={openEvents} />
          <Products products={products} brands={brands} />
          <ClosedEvents events={closedEvents} />
          {/* <Extensions /> */}
          <FAQ faqs={FAQList.shared} />
          {/* <BlogContents /> */}
          {/* <Supeerlikers /> */}
        </main>
      </AppLayout>
    </>
  );
}
