import React from "react";
import { classNames } from "../../../utils";
import { motion, AnimatePresence } from "framer-motion";

export default function StepBase({ ...props }) {
  const {
    title,
    subTitle,
    children,
    nextStep,
    previousStep,
    disablePreviousStep,
    disableNextStep,
    isLastStep,
  } = props;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ y: 10, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        exit={{ y: -10, opacity: 0 }}
        transition={{ duration: 0.2 }}
      >
        <h1 className="text-2xl">{title}</h1>

        {subTitle && (
          <p className="block mt-2 text-sm font-medium text-gray-500">
            {subTitle}
          </p>
        )}

        <div className="px-6 py-5">{children}</div>

        <div
          className={classNames(
            "flex mt-5",
            disablePreviousStep ? "justify-end" : "justify-between"
          )}
        >
          {previousStep && (
            <button
              type="button"
              className={classNames(
                "inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                disablePreviousStep ? "hidden" : ""
              )}
              onClick={() => previousStep()}
              disabled={disablePreviousStep}
            >
              Geri
            </button>
          )}

          {nextStep && (
            <button
              type="button"
              className={classNames(
                disableNextStep
                  ? "inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  : "inline-flex items-center px-3 py-2 border border-transparent text-base font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              )}
              onClick={() => nextStep()}
              disabled={disableNextStep}
            >
              {isLastStep ? "Tamamla" : "İleri"}
            </button>
          )}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}
