import { useState, useEffect, useRef } from "react";
import AppLayout from "../../layouts/AppLayout";
import { collection, query, getDocs, where, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
import EventCard from "../../components/EventCard/EventCard";

export default function Events() {
  const [events, setEvents] = useState([]);
  const [openEvents, setOpenEvents] = useState([]);
  const [closedEvents, setClosedEvents] = useState([]);
  const now = Math.floor(Date.now() / 1000);
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const openEventQuery = query(
          collection(db, "events"),
          where("active", "==", true),
          where("constraints.startAt", "<=", now),
          orderBy("constraints.startAt", "desc")
        );
        const openEventQuerySnapshot = await getDocs(openEventQuery);
        const openEventList = [];
        openEventQuerySnapshot.forEach((doc) => {
          const openEvent = { ...doc.data(), id: doc.id };
          if (openEvent.constraints.endAt >= now) {
            openEventList.push(openEvent);
          }
        });
        setOpenEvents(openEventList);

        const closedEventQuery = query(
          collection(db, "events"),
          where("active", "==", true),
          where("constraints.endAt", "<=", Math.floor(Date.now() / 1000)),
          orderBy("constraints.endAt", "desc")
        );
        const closedEventQuerySnapshot = await getDocs(closedEventQuery);
        const closedEventList = [];
        closedEventQuerySnapshot.forEach((doc) => {
          closedEventList.push({ ...doc.data(), id: doc.id });
        });
        setClosedEvents(closedEventList);
      }
    }
    getData();
  }, []);

  return (
    <AppLayout>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
          <h2 className="text-5xl font-bold text-black tracking-tight">
            Etkinlikler
          </h2>

          <div className="mt-10">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
              Devam Edenler
            </h2>

            <div className="mt-6 gap-4 space-y-5 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0">
              {openEvents.map((event, key) => (
                <EventCard event={event} key={key} />
              ))}
            </div>
          </div>

          {closedEvents.length > 0 && (
            <div className="mt-20">
              <h2 className="text-3xl font-extrabold tracking-tight text-gray-900">
                Tamamlananlar
              </h2>

              <div className="mt-6 gap-4 space-y-5 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0">
                {closedEvents.map((event, key) => (
                  <EventCard event={event} key={key} />
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </AppLayout>
  );
}
