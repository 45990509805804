import React, { Fragment, useEffect, useState, useRef } from "react";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  query,
  collection,
  getDocs,
  where,
} from "firebase/firestore";
import { useNavigate, useParams } from "react-router-dom";
import AppLayout from "../../layouts/AppLayout";
import Profile from "./Profile";
import { useAuth } from "../../contexts/AuthContext";
import Loader from "../../components/Loader/Loader";
import { motion } from "framer-motion";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import EventCard from "../../components/EventCard/EventCard";
import Player from "../../components/StoryPlayer/Player";
import ProductScrollable from "../../components/Product/ProductScrollable";
import UpdateContentWizard from "./UpdateContentWizard/ContentWizard";
import { sortArrayByColumn } from "../../utils";

export default function Creator() {
  const { username } = useParams();
  const { currentUser, userInfo } = useAuth();
  const [user, setUser] = useState(null);
  const [loader, setLoader] = useState(true);
  const [openContent, setOpenContent] = useState(false);
  const [events, setEvents] = useState([]);
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]);
  const [usedBrands, setUsedBrands] = useState([]);
  const [creatorVideos, setCreatorVideos] = useState([]);
  const [revisedModel, setRevisedModel] = useState();
  const [revisedOpen, setRevisedOpen] = useState(false);
  // Player
  const [playerList, setPlayerList] = useState([]);
  const [playerOpen, setPlayerOpen] = useState(false);
  const [currentPlay, setCurrentPlay] = useState(0);
  // Player
  const navigate = useNavigate();
  const pending = useRef(true);

  const isMe = () => {
    if (currentUser && userInfo) {
      return currentUser.uid === userInfo.uid;
    }
  };

  useEffect(() => {
    async function loadData() {
      setLoader(true);

      if (pending.current) {
        pending.current = false;
        const docRef = doc(db, "users", username);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setUser(data);

          if (data.is_blocked) {
            navigate("/");
          }

          const queryConstraints = [];
          queryConstraints.push(where("username", "==", username));
          if (!isMe()) {
            queryConstraints.push(where("is_confirmed", "==", true));
          }
          const videoQuery = query(
            collection(db, "videos"),
            ...queryConstraints
          );

          const videoQuerySnapshot = await getDocs(videoQuery);
          const videoList = [];
          videoQuerySnapshot.forEach(async (doc) => {
            videoList.push({ ...doc.data(), id: doc.id });
          });
          setCreatorVideos(sortArrayByColumn(videoList, 'created_at'));

          const brandList = [];
          const brandQuery = query(collection(db, "brands"));
          const brandSnapshot = await getDocs(brandQuery);
          brandSnapshot.forEach((doc) => {
            brandList.push({ ...doc.data(), id: doc.id });
          });
          setBrands(brandList);

          const eventList = [];
          const productList = [];
          const usedBrandList = [];

          await Promise.all(
            videoList.map(async (video) => {
              if (video.productId) {
                const productRef = doc(db, "products", video.productId);
                const productSnap = await getDoc(productRef);

                if (productSnap.exists()) {
                  const usedProduct = productSnap.data();
                  productList.push({
                    ...usedProduct,
                    id: productSnap.id,
                    baseLink:
                      video.productLink || `/product/${video.productId}`,
                  });

                  if (
                    !usedBrandList.some((x) => x.id === usedProduct.brandId)
                  ) {
                    const _brand = brandList.find(
                      (x) => x.id === usedProduct.brandId
                    );
                    if (_brand) {
                      usedBrandList.push(_brand);
                    }
                  }
                }
              }
            })
          );

          const uniqueEvents = [];

          videoList.map((x) => {
            if (!uniqueEvents.includes(x.eventId)) {
              uniqueEvents.push(x.eventId);
            }
          });

          await Promise.all(
            uniqueEvents.map(async (eventId) => {
              if (eventId) {
                const eventRef = doc(db, "events", eventId);
                const eventSnap = await getDoc(eventRef);

                if (eventSnap.exists()) {
                  eventList.push({ ...eventSnap.data(), id: eventSnap.id });
                }
              }
            })
          );

          setEvents(eventList);
          setProducts(productList);
          setUsedBrands(usedBrandList);

          console.log(usedBrandList);
        } else {
          console.error("No such document");
        }
        setLoader(false);
        // setTimeout(() => setLoader(false), 400);
      }
    }
    loadData();
  }, []);

  const handleVideoPlay = (item, playList) => {
    setCurrentPlay(item);
    setPlayerList(playList);
    setPlayerOpen(true);
  };

  const CreatorBrands = () => {
    return (
      usedBrands.length > 0 && (
        <div>
          <h2 className="text-4xl font-bold tracking-tight mb-10">Markalar</h2>

          {/* <div className="flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-40 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {usedBrands.map((item, key) => (
                    <div key={key} className="relative">
                      <a
                        href={`/brand/${item.id}`}
                        className="relative w-56 h-40 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                      >
                        <div className="flex justify-center w-40 h-24">
                          <img
                            className="object-contain grayscale"
                            width="100%"
                            height="100%"
                            src={item.media.logo.url}
                            alt={item.name}
                          />
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div> */}

          <div className="flex items-center justify-center overflow-x-auto">
            <div className="w-[100%] h-36 relative">
              <div className="absolute flex items-center justify-around left-0 gap-5">
                {usedBrands.map((i) => {
                  return (
                    <div
                      key={i.id}
                      className="flex justify-center items-center w-40 h-28"
                      onClick={() => navigate(`/brand/${i.id}`)}
                    >
                      <img
                        className="object-cover grayscale hover:grayscale-0"
                        src={i.media.logo.url}
                        width="100%"
                        height="100%"
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const CreatorVideos = () => {
    return (
      creatorVideos.filter((x) => x.is_confirmed).length > 0 && (
        <div>
          <h2 className="text-4xl font-bold tracking-tight mb-10">İçerikler</h2>

          <div className="flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {creatorVideos
                    .filter((x) => x.is_confirmed)
                    .map((item, key) => (
                      <div key={key} className="relative">
                        <div
                          className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                          onClick={() => handleVideoPlay(item, creatorVideos)}
                        >
                          <span aria-hidden="true" className="absolute inset-0">
                            <img
                              src={item.media.image.url}
                              className="w-full h-full object-center object-cover"
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const RejectedVideos = () => {
    return (
      creatorVideos.filter((x) => x.is_rejected).length > 0 && (
        <div>
          <div className="mb-10">
            <h2 className="text-4xl font-bold tracking-tight">
              Revize Edilmesi Gerekenler
            </h2>
          </div>

          <div className="flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {creatorVideos
                    .filter((x) => x.is_rejected)
                    .map((item, key) => (
                      <div key={key} className="relative">
                        <div
                          className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                          onClick={() => {
                            setRevisedModel(item);
                            setRevisedOpen(true);
                          }}
                        >
                          <span aria-hidden="true" className="absolute inset-0">
                            <img
                              src={item.media.image.url}
                              className="w-full h-full object-center object-cover"
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const PendingVideos = () => {
    return (
      creatorVideos.filter((x) => !x.is_confirmed && !x.is_rejected).length >
        0 && (
        <div className="mb-20">
          <h2 className="text-4xl font-bold tracking-tight mb-10">
            Onay Bekleyenler
          </h2>

          <div className="flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {creatorVideos
                    .filter((x) => !x.is_confirmed && !x.is_rejected)
                    .map((item, key) => (
                      <div key={key} className="relative">
                        <div
                          className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                          onClick={() => handleVideoPlay(item, creatorVideos)}
                        >
                          <span aria-hidden="true" className="absolute inset-0">
                            <img
                              src={item.media.image.url}
                              className="w-full h-full object-center object-cover"
                            />
                          </span>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  const RevisedSidebar = () => {
    return (
      <Transition.Root show={revisedOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={() => setRevisedOpen(!revisedOpen)}
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity">
            <Dialog.Overlay className="absolute inset-0" />

            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto w-screen max-w-4xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="px-4 py-6 sm:px-6">
                      <div className="flex items-end justify-end">
                        <div className="ml-3 flex h-7 items-center">
                          <button
                            type="button"
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:ring-2 focus:ring-indigo-500"
                            onClick={() => setRevisedOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {revisedModel && (
                      <UpdateContentWizard
                        model={revisedModel}
                        setRevisedOpen={setRevisedOpen}
                      />
                    )}
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    );
  };

  const renderUser = () => {
    return (
      <AppLayout>
        <div className="mt-5 mb-20 max-w-7xl m-auto">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="flex justify-center">
              <Profile
                user={user}
                events={events}
                openContent={openContent}
                setOpenContent={setOpenContent}
              />
            </div>

            <div className="mt-20" />
            <CreatorBrands />
            <div className="mt-20" />
            <RejectedVideos />
            <PendingVideos />
            <CreatorVideos />

            <Player
              open={playerOpen}
              setOpen={setPlayerOpen}
              playerList={playerList}
              setPlayerList={setPlayerList}
              currentPlay={currentPlay}
              setCurrentPlay={setCurrentPlay}
            />

            <RevisedSidebar />

            {products.length > 0 && (
              <div className="mt-20">
                <h2 className="text-5xl font-bold text-black tracking-tight">
                  Ürünler
                </h2>

                <ProductScrollable products={products} brands={brands} />

                {/* <div className="mt-8 relative">
                  <div className="relative w-full pb-6 -mb-6 overflow-x-auto">
                    <ul
                      role="list"
                      className="mx-4 inline-flex"
                    >
                      {products.map((product, key) => (
                        <Product
                          brand={brands.find((x) => x.id === product.brandId)}
                          product={product}
                          key={key}
                        />
                      ))}
                    </ul>
                  </div>
                </div> */}

                {/* <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                  {products.map((product, key) => (
                    <Product
                      brand={brands.find((x) => x.id === product.brandId)}
                      product={product}
                      key={key}
                    />
                  ))}
                </div> */}
              </div>
            )}

            {events.length > 0 && (
              <div className="mt-20">
                <h2 className="text-4xl font-bold tracking-tight mb-10">
                  Etkinlikler
                </h2>

                <div className="mt-6 gap-4 space-y-5 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0">
                  {events.map((event, key) => (
                    <EventCard event={event} key={key} />
                  ))}
                </div>
              </div>
            )}

            <div className="grid grid-cols-1 gap-4 items-start lg:grid-cols-3 lg:gap-8">
              <div className="grid grid-cols-1 gap-4"></div>

              <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                {/* <Products /> */}
              </div>

              {/* <div className="grid grid-cols-1 gap-4 lg:col-span-2">
                <h2 className="text-2xl font-semibold tracking-tight">{`Blog`}</h2>
                <div className="mt-5 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
                  <Blog />
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </AppLayout>
    );
  };

  return loader ? <Loader /> : renderUser();
}
