import React, { useContext, useState, useEffect } from "react";
import { auth, db } from "../firebase";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import Loader from "../components/Loader/Loader";

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState();
  const [userInfo, setUserInfo] = useState();
  const [loading, setLoading] = useState(true);

  const signUp = (email, password) => {
    return createUserWithEmailAndPassword(auth, email, password);
  };

  const userQuery = async (user) => {
    const q = query(collection(db, "users"), where("uid", "==", user.uid));
    const querySnapshot = await getDocs(q);
    let profileData;
    let creator;
    querySnapshot.forEach((doc) => {
      const data = doc.data();
      setUserInfo(data);
      const snapshot = JSON.parse(JSON.stringify({
        media: data.media,
        social: data.social,
        email: data.email,
        uid: data.uid,
        username: data.username,
      }));
      creator = JSON.stringify(snapshot);
    });
    // save locally
    localStorage.setItem("user", creator);
    return profileData;
  };

  const login = async (email, password) => {
    let succeed = false;
    let message = "";
    let data = {};
    let user
    await signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        user = userCredential.user;
        succeed = true;
      })
      .catch((error) => {
        console.error("code", error.code);
        console.error("message", error.message);

        if (error.code === "auth/wrong-password") {
          message = "Parola yanlış";
        }
        if (error.code === "auth/user-not-found") {
          message = "Kullanıcı bulunamadı";
        }
      });

      data = user ? await userQuery(user) : null;
      return { succeed, message, data };
  };

  const logout = () => {
    localStorage.clear();
    return auth.signOut();
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
      if (user) {
        userQuery(user);
      }
      setLoading(false);
    });
    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    userInfo,
    loading,
    setLoading,
    signUp,
    login,
    logout,
  };

  return (
    <AuthContext.Provider value={value}>
      {loading ? <Loader /> : children}
    </AuthContext.Provider>
  );
}
