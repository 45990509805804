import React, { useState, useEffect, useRef } from "react";
import { Wizard } from "react-use-wizard";
import AppLayout from "../../../layouts/AppLayout";
import Communication from "./Steps/Communication";
import Personal from "./Steps/Personal";
import Links from "./Steps/Links";
import Preview from "./Steps/Preview";
import Payment from "./Steps/Payment";
import Header from "./Header";
import { db } from "../../../firebase";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../contexts/AuthContext";
import { storageUpload, normalizedText } from "../../../utils";

export default function InfoWizard() {
  const { userInfo } = useAuth();
  const [file, setFile] = useState(null);
  const [imgPreview, setImgPreview] = useState(null);
  const [loader, setLoader] = useState(true);
  const [payload, setPayload] = useState();
  const navigate = useNavigate();
  const pending = useRef(true);

  useEffect(() => {
    async function loadData() {
      setLoader(true);

      if (pending.current) {
        pending.current = false;
        const docRef = doc(db, "users", userInfo.username);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const data = docSnap.data();
          setPayload(data);
          setImgPreview(data.media.image.url);
          if (data.is_blocked) {
            navigate("/");
          }
        } else {
          console.error("No such document");
        }
        setLoader(false);
      }
    }
    loadData();
  }, []);

  const handleUpdate = async () => {
    setLoader(true);

    setTimeout(async () => {
      if (file) {
        // Profile Image Updated
        payload.media = { image: await storageUpload(file, "creator-images") };
      }
      const search = {
        username: normalizedText(payload.username),
        fullname: normalizedText(payload.personal.fullname),
        description: normalizedText(payload.personal.description),
      };
      search.all = Object.values(search).join(" ");
      payload.search = search;
      const docRef = doc(db, "users", userInfo.username);
      await setDoc(docRef, payload, { merge: true });
      setLoader(false);
      navigate(`/creators/${userInfo.username}`);
    }, 400);
  };

  return (
    !loader && (
      <>
        <AppLayout>
          <div className="max-w-screen-md mx-auto">
            <div className="mt-5 mb-10 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <Wizard header={<Header />}>
                <Communication payload={payload} setPayload={setPayload} />
                <Personal
                  file={file}
                  setFile={setFile}
                  imgPreview={imgPreview}
                  setImgPreview={setImgPreview}
                  payload={payload}
                  setPayload={setPayload}
                />
                <Links payload={payload} setPayload={setPayload} />
                <Payment payload={payload} setPayload={setPayload} />
                <Preview
                  imgPreview={imgPreview}
                  setImgPreview={setImgPreview}
                  payload={payload}
                  setPayload={setPayload}
                  handleUpdate={handleUpdate}
                />
              </Wizard>
            </div>
          </div>
        </AppLayout>
      </>
    )
  );
}
