import { Fragment, useState } from "react";
import { Switch, Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { db } from "../../../firebase";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { sortArrayByColumn, classNames } from "../../../utils";
import { useNavigate } from "react-router-dom";

export default function Modal({ ...props }) {
  const { model, data, setData, open, setOpen } = props;
  const navigate = useNavigate();
  const [isBlocked, setIsBlocked] = useState(model.is_blocked || false);
  const [extraCoin, setExtraCoin] = useState(model.extra_coin || 0);
  const [verified, setVerified] = useState(model.verified || false);
  const handleSubmit = async () => {
    try {
      const docRef = doc(db, "users", model.id);
      await setDoc(
        docRef,
        { is_blocked: isBlocked, extra_coin: extraCoin, verified },
        { merge: true }
      );
      const tempList = data.filter((x) => x.id !== model.id);
      tempList.push({
        ...model,
        is_blocked: isBlocked,
        extra_coin: extraCoin,
        verified,
      });
      setData(sortArrayByColumn(tempList, "created_at"));
      setOpen(!open);
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  return (
    model && (
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 overflow-hidden"
          onClose={setOpen}
        >
          <div className="absolute inset-0 overflow-hidden">
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <div className="pointer-events-auto w-screen max-w-2xl">
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      <div className="bg-gray-50 px-4 py-6 sm:px-6">
                        <div className="flex items-start justify-between space-x-3">
                          <div className="space-y-1">
                            <Dialog.Title className="text-lg font-medium text-gray-900">
                              User{" "}
                              <span className="text-gray-500 text-sm">
                                {model ? `${model.id} - ${model.uid}` : ""}
                              </span>
                            </Dialog.Title>
                          </div>
                          <div className="flex h-7 items-center">
                            <button
                              type="button"
                              className="text-gray-400 hover:text-gray-500"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XIcon className="h-6 w-6" aria-hidden="true" />
                            </button>
                          </div>
                        </div>
                      </div>

                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        <div className="space-y-6 px-4 py-5 sm:p-6">
                          <div className="p-4 mt-2">
                            <div className="flex items-center justify-center">
                              <img
                                className="rounded-lg"
                                width={150}
                                height={150}
                                src={model.media.image.url}
                              />
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Username
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <a
                                className="text-indigo-800 font-bold"
                                onClick={() => navigate(`/creators/${model.username}`)}
                                target="blank"
                              >
                                {"@"}
                                {model.username}
                              </a>
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Fullname
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.personal.fullname}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Description
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.personal.description}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Email
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.email}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Phone
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.phone}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Password
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.password}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Referral
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.referral}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Address
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.personal.address}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Bank Name
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.personal.bankName}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Bank Account Name
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.personal.bankAccountName}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              IBAN
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.personal.iban}
                            </div>
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Identity Number
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              {model.personal.identityNumber}
                            </div>
                          </div>

                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Extra Coin
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                              <input
                                type="number"
                                className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                                defaultValue={extraCoin}
                                onChange={(e) => setExtraCoin(e.target.value)}
                              />
                            </div>
                          </div>

                          {model.scoring && (
                            <div>
                              <label className="block text-sm font-medium text-gray-700">
                                Scoring Detail
                              </label>
                              <div className="mt-1 relative rounded-md shadow-sm">
                                <ul className="ml-5" role="list">
                                  {model.scoring.scoreHistory.map(
                                    (item, key) => (
                                      <li key={key}>
                                        <span className="text-sm font-medium text-gray-900">
                                          {item.score}{" "}
                                          <span className="text-gray-500">
                                            {item.title}
                                          </span>
                                        </span>
                                      </li>
                                    )
                                  )}
                                </ul>
                                <div className="border-dashed border-t mb-5">
                                  <div className="mt-2"></div>
                                  <span className="bg-purple-600 text-white p-1 rounded-lg">
                                    Total Score: {model.scoring.score}
                                  </span>
                                </div>
                              </div>
                            </div>
                          )}

                          <div>
                            <div>
                              <legend className="text-sm font-medium text-gray-900">
                                {"Unverified / Verified"}
                              </legend>
                            </div>

                            <div className="space-y-5">
                              <div className="space-y-5 sm:mt-0">
                                <div className="flex">
                                  <Switch.Group
                                    as="div"
                                    className="flex items-center"
                                  >
                                    <Switch
                                      defaultValue={verified}
                                      onChange={() => setVerified(!verified)}
                                      className={classNames(
                                        verified
                                          ? "bg-indigo-600"
                                          : "bg-gray-200",
                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      )}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          verified
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                        )}
                                      />
                                    </Switch>
                                  </Switch.Group>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div>
                            <div>
                              <legend className="text-sm font-medium text-gray-900">
                                {"Unblocked / Blocked"}
                              </legend>
                            </div>

                            <div className="space-y-5">
                              <div className="space-y-5 sm:mt-0">
                                <div className="flex">
                                  <Switch.Group
                                    as="div"
                                    className="flex items-center"
                                  >
                                    <Switch
                                      defaultValue={isBlocked}
                                      onChange={() => setIsBlocked(!isBlocked)}
                                      className={classNames(
                                        isBlocked
                                          ? "bg-indigo-600"
                                          : "bg-gray-200",
                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      )}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          isBlocked
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                        )}
                                      />
                                    </Switch>
                                  </Switch.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    {/* Action buttons */}
                    <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                      <div className="flex justify-end space-x-3">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                          onClick={() => handleSubmit()}
                        >
                          Update
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    )
  );
}
