import EventCard from "../../components/EventCard/EventCard";

export default function OpenEvents({ ...props }) {
  const { events } = props;

  return (
    events && (
      <section className="bg-white" id="events">
        <div className="max-w-4xl mx-auto px-4 py-8 sm:px-6 sm:pt-8 sm:pb-24 lg:max-w-7xl lg:pt-8 lg:px-8">
          <div className="sm:flex sm:items-baseline sm:justify-between">
            <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">
              Devam Eden
            </h2>
            <a
              href="/events"
              className="hidden text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
            >
              Tümünü Gör<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
          <div className="flex justify-items-start items-center">
            <span className="text-5xl font-bold text-black tracking-tight">
              Etkinlikler
            </span>
          </div>
          {/* <p className="mt-4 max-w-3xl text-lg font-medium text-gray-600">
            Her zorluğun üstesinden gelen topluluk üyelerinin meydan okumalarını{" "}
            <span className="font-bold text-gray-600">keşfet</span>
          </p> */}

          <div className="mt-6 gap-4 space-y-5 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0">
            {events.map((event, key) => (
              <EventCard key={key} event={event} />
            ))}
          </div>
        </div>
      </section>
    )
  );
}
