import { useState } from "react";
import AppLayout from "../../layouts/AppLayout";
import FAQList from "../../FAQList";
import FAQ from "../../components/FAQ/FAQ";
import { classNames } from "../../utils";

export default function FAQs() {
  const [selected, setSelected] = useState("influencer");
  const [selecteds, setSelecteds] = useState(FAQList.influencer);

  return (
    <AppLayout>
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
          <div className="flex justify-center items-center gap-5">
            <div
              onClick={() => {
                setSelected("influencer");
                setSelecteds(FAQList.influencer);
              }}
              className={classNames(
                "border rounded-lg bg-indigo-100 p-3 cursor-pointer",
                selected === "influencer"
                  ? "text-indigo-700"
                  : "text-indigo-300"
              )}
            >
              <h2 className="text-3xl">Influencer</h2>
            </div>
            <div
              onClick={() => {
                setSelected("brand");
                setSelecteds(FAQList.brand);
              }}
              className={classNames(
                "border rounded-lg bg-indigo-100 p-3 cursor-pointer",
                selected === "brand" ? "text-indigo-700" : "text-indigo-300"
              )}
            >
              <h2 className="text-3xl">Marka</h2>
            </div>
          </div>
          <FAQ faqs={selecteds} className="bg-white" />
        </div>
      </div>
    </AppLayout>
  );
}
