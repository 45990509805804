import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { classNames } from "../../../../utils";

const ContentType = ({ ...props }) => {
  const { payload, setPayload, openEvents } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();
  const handleChecked = (item) => {
    setPayload({
      ...payload,
      eventId: item.contentType === "EVENT" ? item.id : null,
      contentType: item.contentType,
    });
  };

  const options = [
    { contentType: "COLLECTION", description: "İçerik Yükle" },
    { contentType: "EVENT", description: "Etkinlik İçeriği Yükle" },
  ];

  const isFormValid = () => {
    return !(payload.contentType === "EVENT" && !payload.eventId);
  };

  const eventOptions = () => {
    const handleChecked = (item) => {
      setPayload({
        ...payload,
        eventId: payload.contentType === "EVENT" ? item.id : null,
      });
    };

    return openEvents && openEvents.length > 0 ? (
      <div>
        <label className="block text-sm font-medium text-gray-700">
          {"Etkinlikler *"}
        </label>
        <span className="text-xs text-gray-500">
          Etkinlik seçimi yapmak için aşağıdaki etkinliklere tıklayınız
        </span>

        <div className="mt-1 grid grid-cols-1 gap-6 sm:grid-cols-3 sm:gap-x-4">
          {openEvents.map((item) => (
            <div
              key={item.id}
              value={item.id}
              onClick={() => handleChecked(item)}
              className={classNames(
                item.id === payload.eventId
                  ? "border-transparent border-indigo-500 ring-2 ring-indigo-500"
                  : "border-gray-300",
                "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none"
              )}
            >
              <div className="flex-1">
                <div className="flex">
                  <div className="block text-sm font-medium text-gray-900">
                    {item.title}
                    <span className="block text-xs text-gray-500 mt-2">
                      {item.short}
                    </span>
                  </div>
                </div>
              </div>
              <CheckCircleIcon
                className={classNames(
                  item.id !== payload.eventId
                    ? "text-gray-300"
                    : "text-indigo-600",
                  "h-5 w-5"
                )}
                aria-hidden="true"
              />
            </div>
          ))}
        </div>
      </div>
    ) : (
      <div>
        <p className="font-bold text-purple-800">
          Aradığın etkinliği bulamadın mı?
        </p>
        <ul className="mt-2 text-xs text-slate-700 font-normal">
          <li>
            {
              '* İlgili etkinliğin detay sayfasında "Etkinliğe Katıl" düğmesine tıklamamış olabilirsin'
            }
          </li>
          <li className="mt-2">
            {
              "* Etklinlik video yükleme tarihi bitmiş olabilir, bunu ilgili etkinliğin detaylarından öğrenebilirsin"
            }
          </li>
          <li className="mt-2">
            {
              '* Etklinliğe katılmak için gerekli puana sahip olmayabilirsin, istesen "İçerik Yükle" bölümünü seçerek devam edebilirsin.'
            }
          </li>
        </ul>
      </div>
    );
  };

  const children = () => {
    return (
      <>
        <div className="mt-4 grid grid-cols-2 gap-6 sm:grid-cols-2 sm:gap-x-4">
          {options.map((item, key) => (
            <div
              key={key}
              onClick={() => handleChecked(item)}
              className={classNames(
                item.contentType === payload.contentType
                  ? "border-transparent border-indigo-500 ring-2 ring-indigo-500"
                  : "border-gray-300",
                "relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none h-24 items-center"
              )}
            >
              <div className="flex-1">
                <div className="flex">
                  <div className="block text-sm font-medium text-gray-900">
                    {item.description}
                  </div>
                </div>
              </div>
              <CheckCircleIcon
                className={classNames(
                  item.contentType !== payload.contentType ? "invisible" : "",
                  "h-5 w-5 text-indigo-600"
                )}
                aria-hidden="true"
              />
            </div>
          ))}
        </div>

        {payload.contentType === "EVENT" && (
          <div className="mt-10 border border-dashed rounded-lg p-5">
            {eventOptions()}
          </div>
        )}
      </>
    );
  };

  return (
    <>
      <StepBase
        title={"İçerik Tipi"}
        subTitle={""}
        previousStep={previousStep}
        disablePreviousStep={true}
        disableNextStep={!isFormValid()}
        nextStep={nextStep}
        children={children()}
        isLastStep={isLastStep}
      />
    </>
  );
};

export default ContentType;
