import { useState } from "react";
import { StarIcon } from "@heroicons/react/solid";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../utils";

export default function EventCard({ ...props }) {
  const { event, short = true } = props;
  const navigate = useNavigate();
  const [imageLoader, setImageLoader] = useState(true);

  const EarnMoney = () => {
    return (
      <span className="inline-flex items-center py-1 pl-2 pr-2 text-xs font-black">
        PARA
        <span className="ml-1 h-6 w-6">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
            />
          </svg>
        </span>
      </span>
    );
  };

  const EarnScore = () => {
    return (
      <span className="inline-flex items-center py-1 pl-2 pr-2 text-xs font-black">
        PUAN
        <StarIcon className="ml-1 h-4 w-4" />
      </span>
    );
  };

  const LoaderCard = () => {
    return (
      <div className="max-w-7xl">
        <div className="mt-6 gap-4 space-y-5 lg:gap-6 lg:space-y-0">
          <div className="animate-pulse px-4 py-5 rounded-lg overflow-hidden sm:p-6">
            <div className="space-y-2">
              <div className="h-72 bg-gray-400 rounded"></div>
              <div className="h-4 bg-gray-400 rounded w-3/6"></div>
              <div className="h-4 bg-gray-400 rounded w-6/6"></div>
              <div className="h-4 bg-gray-400 rounded w-3/6"></div>
              <div className="h-4 bg-gray-400 rounded w-4/6"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    event && (
      <>
        <div
          className={classNames(
            // transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300
            "relative flex items-stretch cursor-pointer hover:opacity-80",
            imageLoader ? "hidden" : "visible"
          )}
          onClick={() => navigate(`/event/${event.id}`)}
        >
          <span
            className="absolute top-5 left-5 font-bold rounded-full"
            style={{
              color: event.colors.card,
              backgroundColor: event.colors.text,
            }}
          >
            {event.earningType === "MONEY" ? <EarnMoney /> : <EarnScore />}
          </span>

          <div
            className="max-w-md rounded-xl border overflow-hidden mx-auto"
            style={{ backgroundColor: event.colors.card }}
          >
            <div className="md:shrink-0">
              <img
                className="h-72 w-full object-cover"
                src={event.media.image.url}
                onLoad={() => setImageLoader(false)}
                alt="Modern building architecture"
              />
            </div>
            <div className="p-8">
              {short && (
                <span
                  className="block mt-1 text-xl leading-tight font-extrabold"
                  style={{ color: event.colors.text }}
                >
                  {event.title}
                </span>
              )}
              <p className="mt-2" style={{ color: event.colors.short }}>
                {event.short}
              </p>

              {/* <div className="mt-3 flex justify-end">
                <div className="flex -space-x-1">
                  <img
                    className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80"
                    alt=""
                  />
                  <img
                    className="inline-block h-6 w-6 rounded-full ring-2 ring-white"
                    src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>

        {imageLoader && <LoaderCard />}
      </>
    )
  );
}
