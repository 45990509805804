import { Fragment, useState, useEffect } from "react";
import { Switch, Dialog, Transition, Listbox } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import { db } from "../../../firebase";
import { collection, addDoc, doc, setDoc, deleteDoc } from "firebase/firestore";
import Select from "react-select";
import {
  classNames,
  storageUpload,
  friendlyInMB,
  normalizedText,
  findMultipleValues,
} from "../../../utils";

export default function Modal({ ...props }) {
  const { model, setModel, tags, brands, data, setData, open, setOpen } = props;
  const tagOptions = () => {
    return tags
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((x) => ({
        value: x.id,
        label: x.name,
      }));
  };
  const [images, setImages] = useState([]);
  const [imagesPreview, setImagesPreview] = useState(
    model && model.media ? model.media.images : []
  );
  const [selectedBrand, setSelectedBrand] = useState(
    model ? brands.find((x) => x.id === model.brandId) : null
  );
  const [selectedTags, setSelectedTags] = useState([]);

  const initPayload = {
    created_at: Math.floor(Date.now() / 1000),
    visible: true,
    name: "",
    description: "",
    brandId: "",
    price: 0,
    discount: 0,
    netPrice: 0,
    stock: 0,
    comissionRate: "",
    barcode: "",
    links: {
      shopier: "",
      brand: "",
      permanent: "",
    },
    tags: [],
    landingOrder: 0,
  };
  const [payload, setPayload] = useState(model || initPayload);
  const [loader, setLoader] = useState(false);

  const handleImagesChange = (e) => {
    setImagesPreview([]);
    setImages([]);
    for (let index = 0; index < e.target.files.length; index++) {
      const file = e.target.files[index];
      setImagesPreview((prev) => [
        ...prev,
        { url: URL.createObjectURL(file), size: file.size },
      ]);
      setImages((prev) => [...prev, file]);
    }
  };

  const handleClose = () => {
    setModel(null);
    setOpen(!open);
  };

  const deleteProduct = async () => {
    setLoader(true);
    if (model) {
      await deleteDoc(doc(db, "products", model.id));
      setData(data.filter((x) => x.id !== model.id));
      setLoader(true);
      setOpen(false)
    }
  };

  const handleSubmit = async () => {
    setLoader(true);
    const uploads = [];

    if (images.length > 0) {
      for await (const file of images) {
        const media = await storageUpload(file, "product-images");
        uploads.push(media);
      }
      payload.media = { images: uploads };
    }

    payload.search = {
      name: normalizedText(payload.name),
      description: normalizedText(payload.description),
    };

    try {
      if (model) {
        const docRef = doc(db, "products", model.id);
        await setDoc(docRef, payload, { merge: true });
      } else {
        payload.brandId = selectedBrand.id;
        payload.tags = selectedTags ? selectedTags.map((x) => x.value) : [];
        await addDoc(collection(db, "products"), payload);
      }

      const tempData = model ? data.filter((x) => x.id !== model.id) : data;
      setData([payload, ...tempData]);
      setOpen(false);
      setLoader(false);
    } catch (e) {
      setLoader(false);
      setOpen(false);
      window.alert(`Error adding document: ${e}`);
    }
  };

  useEffect(() => {
    findMultipleValues(payload.tags, tagOptions(), setSelectedTags);
  }, []);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={handleClose}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Product{" "}
                            <span className="text-gray-500 text-sm">
                              {model ? model.id : ""}
                            </span>
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => handleClose()}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}

                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-6 px-4 py-5 sm:p-6">
                        {/* Brand Selection */}
                        <Listbox
                          value={selectedBrand}
                          onChange={setSelectedBrand}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-medium text-gray-700">
                                Brand
                              </Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedBrand
                                      ? selectedBrand.name
                                      : "Select"}
                                  </span>
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {brands
                                      .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                      )
                                      .map((item) => (
                                        <Listbox.Option
                                          key={item.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-indigo-600"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={item}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {item.name}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-indigo-600",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                        {/* Brand Selection */}

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Tags
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <Select
                              isMulti
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              value={selectedTags}
                              onChange={setSelectedTags}
                              options={tagOptions()}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Product Name
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.name}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  name: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              rows={3}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={payload.description}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  description: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Price
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.price}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  price: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Discount
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.discount}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  discount: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Net Price
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.netPrice}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  netPrice: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Stock
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.stock}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  stock: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Comission Rate
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.comissionRate}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  comissionRate: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Barcode
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.barcode}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  barcode: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Shopier Link
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.links.shopier}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  links: {
                                    ...payload.links,
                                    shopier: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Brand Link
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.links.brand}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  links: {
                                    ...payload.links,
                                    brand: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Permanent Link
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.links.permanent}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  links: {
                                    ...payload.links,
                                    permanent: e.target.value,
                                  },
                                })
                              }
                            />
                          </div>
                        </div>

                        <div className="border p-2 rounded-lg">
                          <div className="flex items-center justify-between">
                            <label className="block text-sm font-medium text-gray-700">
                              Images
                            </label>
                            <div>
                              <button
                                type="button"
                                className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                onClick={() =>
                                  document.getElementById("images").click()
                                }
                              >
                                Browse
                              </button>
                              <input
                                id="images"
                                type="file"
                                className="sr-only"
                                multiple
                                accept="image/*"
                                onChange={(e) => handleImagesChange(e)}
                              />
                            </div>
                          </div>
                          {imagesPreview &&
                            imagesPreview.map((image, key) => (
                              <div key={key} className="p-4 mt-2">
                                <div className="flex items-center justify-center">
                                  <img className="rounded-lg" src={image.url} />
                                </div>

                                <span className="block mt-1 text-gray-600 text-xs font-bold">
                                  {"Boyut: "} {friendlyInMB(image.size)} {"MB"}{" "}
                                </span>
                              </div>
                            ))}
                        </div>

                        <div>
                          <div>
                            <legend className="text-sm font-medium text-gray-900">
                              {"Hidden / Visible"}
                            </legend>
                          </div>

                          <div className="space-y-5">
                            <div className="space-y-5 sm:mt-0">
                              <div className="flex">
                                <Switch.Group
                                  as="div"
                                  className="flex items-center"
                                >
                                  <Switch
                                    defaultChecked={payload.visible}
                                    onChange={(e) =>
                                      setPayload({
                                        ...payload,
                                        visible: !payload.visible,
                                      })
                                    }
                                    className={classNames(
                                      payload.visible
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        payload.visible
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                      )}
                                    />
                                  </Switch>
                                </Switch.Group>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Landing Order
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={payload.landingOrder || 0}
                              onChange={(e) =>
                                setPayload({
                                  ...payload,
                                  landingOrder: e.target.value,
                                })
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => handleClose()}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-red-300 bg-white py-2 px-4 text-sm font-medium text-red-700 shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={() => deleteProduct()}
                      >
                        Delete
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        disabled={loader}
                        onClick={() => handleSubmit()}
                      >
                        {model ? "Update" : "Create"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
