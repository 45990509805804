import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { AuthProvider } from "./contexts/AuthContext";
import RequireAuth from "./RequireAuth";

import { routes } from "./Routes";
import Landing from "./views/Landing/index";
import Creator from "./views/Creator/Creator";
import Logout from "./views/Logout/Logout";
import Blog from "./views/Blog/Blog";
import ShowContent from "./views/Blog/ShowContent";
import NotFound from "./views/NotFound/index";
import Register from "./views/Register/Register";
import KVKK from "./views/Legal/KVKK";
import Membership from "./views/Legal/Membership";
import BrandRequest from './views/BrandRequest/BrandRequest';
import AllVideosPlayer from './components/StoryPlayer/AllVideosPlayer';
import Events from "./views/Events/Events";
import EventDetail from "./views/Events/EventDetail";
import Products from "./views/Products/Products";
import ProductDetail from "./views/Products/ProductDetail";
import Iframe from "./views/Iframe/Iframe";
import ProductTest from "./views/Products/Test";
import Brands from "./views/Brands/Brands";
import BrandDetail from "./views/Brands/BrandDetail";
import Example from "./views/Example/Example";
import FAQs from "./views/FAQs/FAQs";
import Test from "./views/Test";
import Video from "./views/Video/Video";
import ContentWizard from "./views/Creator/ContentWizard/ContentWizard";
import InfoWizard from './views/Creator/InfoWizard/InfoWizard';
import Leaderboard from "./views/Leaderboard/Leaderboard";
import Ticket from './views/Creator/Ticket/Ticket';


function App() {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
          {/* <Route path={"@:username"} element={<Creator />} /> */}
          <Route path="/creators/:username" element={<Creator />} />
          <Route path="/faq" element={<FAQs />} />
          <Route path="/videos" element={<AllVideosPlayer />} />
          <Route path="/video/:videoId" element={<Video />} />
          <Route path="/content-wizard" element={<ContentWizard />} />
          <Route path="/update-profile" element={<InfoWizard />} />
          <Route path="/leaderboard" element={<Leaderboard />} />
          <Route path="/events" element={<Events />} />
          <Route path="/event/:eventId" element={<EventDetail />} />
          <Route path="/products" element={<Products />} />
          <Route path="/product-test" element={<ProductTest />} />
          <Route path="/product/:productId" element={<ProductDetail />} />
          <Route path="/iframe/:productId" element={<Iframe />} />
          <Route path="/brands" element={<Brands />} />
          <Route path="/brand/:brandId" element={<BrandDetail />} />
          <Route path="/content" element={<ShowContent />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/brand-request" element={<BrandRequest />} /> */}
          <Route path="/kvkk" element={<KVKK />} />
          <Route path="/uyelik-sozlesmesi" element={<Membership />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="/support" element={<Ticket />} />
          {/* <Route path="/example" element={<Example />} /> */}
          {/* <Route path="/hls-test" element={<Test />} /> */}
          <Route element={<RequireAuth />}>
            {routes.map((item, key) => (
              <Route
                key={key}
                exact
                path={item.href}
                element={item.component}
              />
            ))}
          </Route>
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
