import React, { useRef, useState, useEffect } from "react";
import { db } from "../../firebase";
import {
  doc,
  getDoc,
  query,
  collection,
  getDocs,
  where,
  limit,
  orderBy,
} from "firebase/firestore";
import Player from "../../components/StoryPlayer/Player";

export default function TrendVideos() {
  const [creatorVideos, setCreatorVideos] = useState([]);
  // Player
  const [playerList, setPlayerList] = useState([]);
  const [playerOpen, setPlayerOpen] = useState(false);
  const [currentPlay, setCurrentPlay] = useState(0);
  // Player
  const pending = useRef(true);

  useEffect(() => {
    async function loadData() {
      if (pending.current) {
        pending.current = false;

        const videosList = [];
        const videosQuery = query(
          collection(db, "videos"),
          where("is_confirmed", "==", true),
          orderBy("landingOrder", "desc"),
          limit(5)
        );
        const videosSnapshot = await getDocs(videosQuery);
        videosSnapshot.forEach((doc) => {
          videosList.push({ ...doc.data(), id: doc.id });
        });
        setCreatorVideos(videosList);
      }
    }
    loadData();
  }, []);

  const handleVideoPlay = (item, playList) => {
    setCurrentPlay(item);
    setPlayerList(playList);
    setPlayerOpen(true);
  };

  const CreatorVideos = () => {
    return (
      creatorVideos.length > 0 && (
        <div className="mt-5">
          <div className="flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {creatorVideos.map((item, key) => (
                    <div
                      key={key}
                      className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                      onClick={() => handleVideoPlay(item, creatorVideos)}
                    >
                      <span aria-hidden="true" className="absolute inset-0">
                        <img
                          src={item.media.image.url}
                          className="w-full h-full object-center object-cover"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    <section id="trending">
      {/* bg-gradient-to-r from-purple-600 to-pink-600 */}
      <div className="m-auto shadow bg-[#2B192E]">
        <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:max-w-7xl lg:px-8">
          <div className="sm:flex sm:items-baseline sm:justify-between">
            <h2 className="text-4xl font-extrabold text-white tracking-tight">
              Trend İçerikler
            </h2>
            <a
              href="/videos"
              className="text-sm font-semibold text-white sm:block"
            >
              Tümünü Gör<span aria-hidden="true"> &rarr;</span>
            </a>
          </div>
          <p className="mt-2 max-w-3xl text-lg font-medium text-white">
            Influencerların ürün incelemelerini izleyin!
          </p>
          <CreatorVideos />
          <Player
            open={playerOpen}
            setOpen={setPlayerOpen}
            playerList={playerList}
            setPlayerList={setPlayerList}
            currentPlay={currentPlay}
            setCurrentPlay={setCurrentPlay}
          />

          {/* <div className="mt-8 flex items-start gap-3 scroll-smooth overflow-x-auto overflow-visible">
            {contents.map((item, idx) => (
              <div key={item.id}>
              </div>
            ))}
          </div> */}
        </div>
      </div>
    </section>
  );
}
