import React, { useEffect, useRef, useState } from "react";
import {
  collection,
  query,
  getDocs,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";
import { db } from "../../../firebase";
import AdminLayout from "../../../layouts/AdminLayout";
import History from "./History";
import Modal from "./Modal";
import InfiniteScroll from "react-infinite-scroll-component";

const Products = () => {
  const [model, setModel] = useState();
  const [open, setOpen] = useState();
  const [data, setData] = useState([]);
  const [brands, setBrands] = useState([]);
  const [tags, setTags] = useState([]);
  const pending = useRef(true);
  const [loader, setLoader] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [lastVisible, setLastVisible] = useState();

  const buildQuery = () => {
    const q = [];
    q.push(orderBy("created_at", "desc"));
    q.push(limit(20));
    if (lastVisible) {
      q.push(startAfter(lastVisible));
    }
    return query(collection(db, "products"), ...q);
  };

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const productSnapshot = await getDocs(buildQuery());
        const productList = [];
        productSnapshot.forEach((doc) => {
          productList.push({ ...doc.data(), id: doc.id });
        });

        const brandQuery = query(collection(db, "brands"));
        const brandSnapshot = await getDocs(brandQuery);
        const brandList = [];
        brandSnapshot.forEach((doc) => {
          brandList.push({ ...doc.data(), id: doc.id });
        });

        const tagQuery = query(collection(db, "tags"));
        const tagSnapshot = await getDocs(tagQuery);
        const tagList = [];
        tagSnapshot.forEach((doc) => {
          tagList.push({ ...doc.data(), id: doc.id });
        });

        setBrands(brandList);
        setTags(tagList);
        setData(productList);
        setLoader(false);
      }
    }
    getData();
  }, []);

  const fetchData = async () => {
    const querySnapshot = await getDocs(buildQuery());
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    querySnapshot.forEach((doc) => {
      if (doc.exists()) {
        setData((prev) => [...prev, { ...doc.data(), id: doc.id }]);
      } else {
        setHasMore(false);
      }
    });
  };

  return (
    <AdminLayout>
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-2xl font-semibold text-gray-900">{`Products (${data.length})`}</h1>
        </div>
        <div className="mt-4">
          <button
            type="button"
            className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
            onClick={() => setOpen(!open)}
          >
            New
          </button>
        </div>
      </div>

      <InfiniteScroll
        className="overflow-x-auto -mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 rounded-lg"
        dataLength={data.length}
        next={fetchData}
        hasMore={hasMore}
        loader={loader && <h4 className="text-center">Loading...</h4>}
        endMessage={
          <p className="block text-center">
            <b>Yay! You have seen it all</b>
          </p>
        }
      >
        <History
          brands={brands}
          data={data}
          tags={tags}
          setModel={setModel}
          setOpen={setOpen}
        />
      </InfiniteScroll>

      {open && (
        <Modal
          model={model}
          setModel={setModel}
          brands={brands}
          tags={tags}
          data={data}
          setData={setData}
          setOpen={setOpen}
          open={open}
        />
      )}
    </AdminLayout>
  );
};

export default Products;
