import React, { Fragment, useState, useEffect, useRef } from "react";
import AppLayout from "../../layouts/AppLayout";
import {
  collection,
  query,
  doc,
  getDoc,
  getDocs,
  orderBy,
  where,
  addDoc,
  limit,
} from "firebase/firestore";
import { db } from "../../firebase";
import Product from "../../components/Cards/Product/Product";
import { useParams } from "react-router-dom";
import Player from "../../components/StoryPlayer/Player";
import EventCard from "../../components/EventCard/EventCard";

export default function BrandDetail() {
  const { brandId } = useParams();
  const [brand, setBrand] = useState(null);
  const [products, setProducts] = useState([]);
  const [events, setEvents] = useState([]);
  const [creatorVideos, setCreatorVideos] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [playerOpen, setPlayerOpen] = useState(false);
  const [currentPlay, setCurrentPlay] = useState(0);
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const brandRef = doc(db, "brands", brandId);
        const brandSnap = await getDoc(brandRef);

        if (brandSnap.exists()) {
          const brandItem = brandSnap.data();
          setBrand(brandItem);

          const productQuery = query(
            collection(db, "products"),
            where("visible", "==", true),
            where("brandId", "==", brandId)
          );
          const productSnapshot = await getDocs(productQuery);
          const productList = [];
          productSnapshot.forEach((doc) => {
            productList.push({ ...doc.data(), id: doc.id });
          });
          setProducts(productList);

          const eventList = [];
          const eventQuery = query(
            collection(db, "events"),
            where("active", "==", true)
          );
          const eventSnapshot = await getDocs(eventQuery);
          eventSnapshot.forEach((doc) => {
            eventList.push({ ...doc.data(), id: doc.id });
          });

          const videoList = [];
          const usedEvents = [];

          await Promise.all(
            productList.map(async (product) => {
              const videoQuery = query(
                collection(db, "videos"),
                where("is_confirmed", "==", true),
                where("productId", "==", product.id)
              );
              const videoSnapshot = await getDocs(videoQuery);
              videoSnapshot.forEach((doc) => {
                videoList.push({ ...doc.data(), id: doc.id });
              });

              eventList.map((event) => {
                event.products.map((buyProduct) => {
                  if (
                    buyProduct.productId === product.id &&
                    !usedEvents.includes(event)
                  ) {
                    usedEvents.push(event);
                  }
                });
              });
            })
          );

          setEvents(usedEvents);
          setCreatorVideos(videoList);
        }
      }
    }
    getData();
  }, []);

  const handleVideoPlay = (item, playList) => {
    setCurrentPlay(item);
    setPlayerList(playList);
    setPlayerOpen(true);
  };

  const CreatorVideos = () => {
    return (
      creatorVideos.length > 0 && (
        <div>
          <h2 className="text-4xl font-bold tracking-tight mb-10">İçerikler</h2>

          <div className="flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {creatorVideos.map((item, key) => (
                    <div
                      key={key}
                      className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto hover:cursor-pointer"
                      onClick={() => handleVideoPlay(item, creatorVideos)}
                    >
                      <span aria-hidden="true" className="absolute inset-0">
                        <img
                          src={item.media.image.url}
                          className="w-full h-full object-center object-cover"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    brandId &&
    brand && (
      <AppLayout>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
            <div className="sm:flex sm:items-baseline sm:justify-between">
              <h2 className="text-5xl font-bold text-black tracking-tight">
                {brand.name}
              </h2>
              <a
                href="/brands"
                className="text-sm font-semibold text-indigo-600 hover:text-indigo-500 sm:block"
              >
                Tüm Markaları Gör<span aria-hidden="true"> &rarr;</span>
              </a>
            </div>
            <div className="flex justify-center mb-0">
              <img src={brand.media.logo.url} />
            </div>

            <div className="mt-20">
              <div>
                <h2 className="text-5xl font-bold text-black tracking-tight">
                  Ürünler
                </h2>

                <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
                  {products.map((product, key) => (
                    <Product brand={brand} product={product} key={key} />
                  ))}
                </div>
              </div>

              <div className="mt-20"></div>
              <CreatorVideos />
              <Player
                open={playerOpen}
                setOpen={setPlayerOpen}
                playerList={playerList}
                setPlayerList={setPlayerList}
                currentPlay={currentPlay}
                setCurrentPlay={setCurrentPlay}
              />
            </div>

            {events.length > 0 && (
              <div className="mt-20">
                <h2 className="text-4xl font-bold tracking-tight mb-10">
                  Etkinlikler
                </h2>

                <div className="mt-6 gap-4 space-y-5 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0">
                  {events.map((event, key) => (
                    <EventCard event={event} key={key} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </AppLayout>
    )
  );
}
