import React, { useState } from "react";
import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import { friendlyInMB } from "../../../../utils";

const CoverImage = ({ ...props }) => {
  const { coverImage, setCoverImage, coverImagePreview, setCoverImagePreview } =
    props;
  const { previousStep, nextStep, isLastStep } = useWizard();

  const handleCoverImageChange = (e) => {
    setCoverImagePreview(URL.createObjectURL(e.target.files[0]));
    setCoverImage(e.target.files[0]);
  };

  const children = () => {
    return (
      <div className="border p-2 rounded-lg">
        <div className="flex items-center justify-center">
          <div>
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => document.getElementById("photo").click()}
            >
              Gözat
            </button>
            <input
              id="photo"
              type="file"
              className="sr-only"
              accept="image/*"
              onChange={(e) => handleCoverImageChange(e)}
            />
          </div>
        </div>
        {coverImagePreview && (
          <div className="p-4 mt-2">
            <div className="flex items-center justify-center">
              <img className="rounded-lg" src={coverImagePreview} />
            </div>

            <span className="block mt-1 text-gray-600 text-xs font-bold">
              {"Boyut: "} {friendlyInMB(coverImage.size)} {"MB"}{" "}
            </span>
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Kapak Fotoğrafı 📸"}
        subTitle={
          "Dikkat çekici bir kapak fotoğrafına sahip videolar her zaman trend olmuştur!"
        }
        previousStep={previousStep}
        nextStep={nextStep}
        children={children()}
        isLastStep={isLastStep}
        disableNextStep={!coverImagePreview}
      />
    </>
  );
};

export default CoverImage;
