import React, { Fragment, useState, useEffect, useRef } from "react";
import AppLayout from "../../layouts/AppLayout";
import {
  collection,
  query,
  doc,
  getDoc,
  getDocs,
  orderBy,
  where,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebase";

import { useNavigate, useParams } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { classNames } from "../../utils";
import Player from "../../components/StoryPlayer/Player";
import EventCard from "../../components/EventCard/EventCard";

export default function ProductDetail() {
  const { productId } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [brand, setBrand] = useState(null);
  const [events, setEvents] = useState([]);
  const [creatorVideos, setCreatorVideos] = useState([]);
  const [playerList, setPlayerList] = useState([]);
  const [playerOpen, setPlayerOpen] = useState(false);
  const [currentPlay, setCurrentPlay] = useState(0);
  const pending = useRef(true);

  useEffect(() => {
    async function getData() {
      if (pending.current) {
        const productRef = doc(db, "products", productId);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) {
          const productItem = productSnap.data();
          setProduct(productItem);

          const brandRef = doc(db, "brands", productItem.brandId);
          const brandSnap = await getDoc(brandRef);

          if (brandSnap.exists()) {
            setBrand({ ...brandSnap.data(), id: brandSnap.id });
          }

          const videoQuery = query(
            collection(db, "videos"),
            where("is_confirmed", "==", true),
            where("productId", "==", productId)
          );
          const videoSnapshot = await getDocs(videoQuery);
          const videoList = [];
          videoSnapshot.forEach((doc) => {
            videoList.push({ ...doc.data(), id: doc.id });
          });
          setCreatorVideos(videoList);

          const eventQuery = query(
            collection(db, "events"),
            where("active", "==", true)
          );
          const eventSnapshot = await getDocs(eventQuery);
          const eventList = [];
          eventSnapshot.forEach((doc) => {
            eventList.push({ ...doc.data(), id: doc.id });
          });
          eventList.map((x) => {
            x.products.map((product) => {
              if (product.productId === productId) {
                setEvents((prev) => [...prev, x]);
              }
            });
          });
        }
      }
    }
    getData();
  }, []);

  const handleVideoPlay = (item, playList) => {
    setCurrentPlay(item);
    setPlayerList(playList);
    setPlayerOpen(true);
  };

  const CreatorVideos = () => {
    return (
      creatorVideos.length > 0 && (
        <div>
          <h2 className="text-4xl font-bold tracking-tight mb-10">İçerikler</h2>

          <div className="flow-root">
            <div className="-my-2">
              <div className="box-content py-2 relative h-80 overflow-x-auto">
                <div className="absolute min-w-screen-xl px-4 flex space-x-8 sm:px-6 lg:px-8 xl:relative xl:px-0 xl:space-x-0 xl:grid xl:grid-cols-5 xl:gap-x-8">
                  {creatorVideos.map((item, key) => (
                    <div
                      key={key}
                      className="relative w-56 h-80 rounded-lg p-6 flex flex-col overflow-hidden hover:opacity-75 xl:w-auto"
                      onClick={() => handleVideoPlay(item, creatorVideos)}
                    >
                      <span aria-hidden="true" className="absolute inset-0">
                        <img
                          src={item.media.image.url}
                          className="w-full h-full object-center object-cover"
                        />
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      )
    );
  };

  return (
    product &&
    brand && (
      <AppLayout>
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-2xl py-16 lg:max-w-none">
            {/* Product */}
            <div className="lg:grid lg:grid-cols-2 lg:gap-x-8 lg:items-start">
              {/* Image gallery */}
              <Tab.Group as="div" className="flex flex-col-reverse ml-10">
                {/* Image selector */}
                <div className="mt-6 w-full max-w-2xl mx-auto sm:block lg:max-w-none">
                  <Tab.List className="grid grid-cols-4 gap-6">
                    {product.media.images.map((image, key) => (
                      <Tab
                        key={key}
                        className="relative h-24 bg-white rounded-md flex items-center justify-center text-sm font-medium uppercase text-gray-900 cursor-pointer hover:bg-gray-50 focus:outline-none focus:ring focus:ring-offset-4 focus:ring-opacity-50"
                      >
                        {({ selected }) => (
                          <>
                            {/* <span className="sr-only">{image.name}</span> */}
                            <span className="absolute inset-0 rounded-md overflow-hidden">
                              <img
                                src={image.url}
                                alt=""
                                className="w-full h-full object-center object-cover"
                              />
                            </span>
                            <span
                              className={classNames(
                                selected
                                  ? "ring-indigo-500"
                                  : "ring-transparent",
                                "absolute inset-0 rounded-md ring-2 ring-offset-2 pointer-events-none"
                              )}
                              aria-hidden="true"
                            />
                          </>
                        )}
                      </Tab>
                    ))}
                  </Tab.List>
                </div>

                <Tab.Panels className="w-full aspect-w-1 aspect-h-1 flex items-center justify-center">
                  {product.media.images.map((image, key) => (
                    <Tab.Panel key={key}>
                      <img
                        src={image.url}
                        // alt={image.alt}
                        className="h-full w-full object-center object-cover sm:rounded-lg"
                      />
                    </Tab.Panel>
                  ))}
                </Tab.Panels>
              </Tab.Group>

              {/* Product info */}
              <div className="mt-10 px-4 sm:px-0 sm:mt-16 lg:mt-0">
                <h1 className="text-3xl font-extrabold tracking-tight text-gray-900">
                  {product.name}
                </h1>

                <div className="mt-3">
                  <p
                    className="text-3xl text-gray-900 cursor-pointer underline underline-offset-4"
                    onClick={() => navigate(`/brand/${brand.id}`)}
                  >
                    {brand.name}
                  </p>
                </div>

                {/* Reviews */}
                {/* <div className="mt-3">
                  <h3 className="sr-only">Reviews</h3>
                  <div className="flex items-center">
                    <div className="flex items-center">
                      {[0, 1, 2, 3, 4].map((rating) => (
                        <StarIcon
                          key={rating}
                          className={classNames(
                            product.rating > rating
                              ? "text-indigo-500"
                              : "text-gray-300",
                            "h-5 w-5 flex-shrink-0"
                          )}
                          aria-hidden="true"
                        />
                      ))}
                    </div>
                    <p className="sr-only">{product.rating} out of 5 stars</p>
                  </div>
                </div> */}

                <div className="mt-6">
                  <h3 className="sr-only">Description</h3>

                  <div
                    className="text-base text-gray-700 space-y-6"
                    dangerouslySetInnerHTML={{ __html: product.description }}
                  />
                </div>

                {product.links.brand && (
                  <div className="mt-10 flex sm:flex-col1">
                    <button
                      type="submit"
                      className="max-w-xs flex-1 bg-indigo-600 border border-transparent rounded-md py-3 px-8 flex items-center justify-center text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500 sm:w-full"
                      onClick={() =>
                        (window.location.href =
                          product.links.brand || product.links.permanent)
                      }
                    >
                      Ürün Sayfasına Git
                    </button>
                  </div>
                )}

                {/* <section aria-labelledby="details-heading" className="mt-12">
                  <h2 id="details-heading" className="sr-only">
                    Additional details
                  </h2>

                  <div className="border-t divide-y divide-gray-200" />
                </section> */}
              </div>
            </div>

            <div className="mt-20">
              <CreatorVideos />

              <Player
                open={playerOpen}
                setOpen={setPlayerOpen}
                playerList={playerList}
                setPlayerList={setPlayerList}
                currentPlay={currentPlay}
                setCurrentPlay={setCurrentPlay}
              />
            </div>

            {events.length > 0 && (
              <div className="mt-10">
                <h2 className="text-4xl font-bold tracking-tight mb-10">
                  Etkinlikler
                </h2>

                <div className="mt-6 gap-4 space-y-5 lg:grid lg:grid-cols-3 lg:gap-6 lg:space-y-0">
                  {events.map((event, key) => (
                    <EventCard event={event} key={key} />
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </AppLayout>
    )
  );
}
