import { Fragment, useState } from "react";
import { Switch, Dialog, Transition, Listbox } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import ReactPlayer from "react-player";
import { db } from "../../../firebase";
import { classNames, sortArrayByColumn } from "../../../utils";
import {
  query,
  collection,
  where,
  doc,
  setDoc,
  getDoc,
  getDocs,
  deleteDoc,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  deleteObject,
  getDownloadURL,
} from "firebase/storage";
import axios from "axios";

const calculateScore = async (user, userRef) => {
  // *******************************************************
  // Calculating the User Score
  // *******************************************************

  const eventJoinList = [];
  const eventList = [];
  const videoList = [];
  const scoreHistory = [];
  const referralList = [];

  const eventJoinQuery = query(
    collection(db, "event_joins"),
    where("userId", "==", user.uid)
  );
  const eventJoinQuerySnapshot = await getDocs(eventJoinQuery);
  eventJoinQuerySnapshot.forEach((doc) => {
    eventJoinList.push({ ...doc.data(), id: doc.id });
  });

  const eventsQuery = query(collection(db, "events"));
  const eventsQuerySnapshot = await getDocs(eventsQuery);
  eventsQuerySnapshot.forEach((doc) => {
    eventList.push({ ...doc.data(), id: doc.id });
  });

  const videoQuery = query(
    collection(db, "videos"),
    where("uid", "==", user.uid)
  );
  const videoQuerySnapshot = await getDocs(videoQuery);
  videoQuerySnapshot.forEach((doc) => {
    videoList.push({ ...doc.data(), id: doc.id });
  });

  let score = 0;
  const contents = videoList.filter(
    (content) => content.uid === user.uid && content.is_confirmed
  );

  contents.map((item) => {
    const event = eventList.find((el) => el.id === item.eventId);

    if (event) {
      if (event.earningType === "SCORE") {
        score += parseInt(event.earningValue);
        scoreHistory.push({
          title: event.title,
          score: parseInt(event.earningValue),
        });
      }

      if (event.earningType === "MONEY") {
        // Etkinliklere içerik yükleme
        score += 350;
        scoreHistory.push({
          title: event.title,
          score: 350,
        });
      }
    } else {
      // Ürüne bağlı içerik yükleme
      score += 250;
      scoreHistory.push({
        title: "Ürüne bağlı içerik yükleme",
        score: 250,
      });
    }
  });

  const referrals = query(
    collection(db, "users"),
    where("referral", "==", user.username)
  );
  const referralsSnapshot = await getDocs(referrals);
  referralsSnapshot.forEach((doc) => {
    referralList.push({ ...doc.data(), id: doc.id });
  });

  // Arkadaşını davet etme
  referralList.map((x) => {
    score += 350;
    scoreHistory.push({
      title: "Arkadaşını Davet Et",
      score: 350,
    });
  });

  if (user.extra_coin) {
    score += parseInt(user.extra_coin);
    scoreHistory.push({
      title: "Extra Coin",
      score: parseInt(user.extra_coin),
    });
  }

  let level = 1,
    group = "";
  if (score >= 0 && score <= 499) {
    (level = 1), (group = "Community");
  }
  if (score >= 500 && score <= 999) {
    (level = 2), (group = "Community");
  }
  if (score >= 1000 && score <= 1999) {
    (level = 3), (group = "Community");
  }
  if (score >= 2000 && score <= 3999) {
    (level = 4), (group = "Supeerliker");
  }
  if (score >= 4000 && score <= 7499) {
    (level = 5), (group = "Supeerliker");
  }
  if (score >= 7500 && score <= 11999) {
    (level = 6), (group = "Supeerliker");
  }
  if (score >= 12000 && score <= 17999) {
    (level = 7), (group = "Supeerliker");
  }
  if (score >= 18000 && score <= 23999) {
    (level = 8), (group = "Supeerstar");
  }
  if (score >= 24000 && score <= 31999) {
    (level = 9), (group = "Supeerstar");
  }
  if (score >= 32000 && score <= 49999) {
    (level = 10), (group = "Supeerstar");
  }
  if (score >= 50000 && score <= 61999) {
    (level = 11), (group = "Master of Content");
  }
  if (score >= 62000 && score <= 79999) {
    (level = 12), (group = "Master of Content");
  }
  if (score >= 80000 && score <= 99999) {
    (level = 13), (group = "Master of Content");
  }
  if (score >= 100000 && score <= 249999) {
    (level = 14), (group = "Primadonna");
  }
  if (score >= 250000) {
    (level = 15), (group = "Primadonna");
  }

  const scoring = { score, scoreHistory, level, group };
  await setDoc(userRef, { scoring }, { merge: true });
};

export default function Modal({ ...props }) {
  const { products, data, setData, users, model, open, setOpen } = props;

  const [selectedProduct, setSelectedProduct] = useState(
    products.find((x) => x.id === model.productId)
  );
  const userData = users.find((x) => x.uid === model.uid);
  const [confirm, setConfirm] = useState(model.is_confirmed);
  const [landingOrder, setLandingOrder] = useState(0);
  const [is_rejected, setIsRejected] = useState(model.is_rejected || false);
  const [rejectedReason, setRejectReason] = useState(
    model.rejectedReason || ""
  );
  const storage = getStorage();

  const deleteVideo = async () => {
    await deleteDoc(doc(db, "videos", model.id));
    setData(data.filter((x) => x.id !== model.id));
    const videoRef = ref(
      storage,
      `${model.media.video.folderName}/${model.media.video.objectId}`
    );
    // Delete the file
    await deleteObject(videoRef)
      .then(() => {
        // File deleted successfully
      })
      .catch((error) => {
        // Uh-oh, an error occurred!
      });

    // Score Calculation
    const userRef = doc(db, "users", model.username);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const user = userSnap.data();

      await calculateScore(user, userRef);
    }

    setOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const docRef = doc(db, "videos", model.id);
      const changedModel = {
        is_confirmed: confirm,
        is_rejected,
        rejectedReason,
        productId: selectedProduct.id || 0,
        landingOrder,
      };
      await setDoc(docRef, changedModel, { merge: true });
      const sortedVideos = sortArrayByColumn(
        data.filter((x) => x.id !== model.id),
        "created_at"
      );
      setData(sortedVideos);
      setOpen(!open);
    } catch (e) {
      console.error("Error adding document: ", e);
    }

    const userRef = doc(db, "users", model.username);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists()) {
      const user = userSnap.data();

      // Score Calculation
      await calculateScore(user, userRef);

      if (confirm || is_rejected) {
        await axios
          .post(
            // Content Confirmation Bot
            "https://discord.com/api/webhooks/1031706482049306724/SefFmyOAamkNScPj9HjDVhArDI59uVqlVnVCAeNfjDcQB_j4oIj--iCFdSJDtaQPfi8H",
            // Log Channel
            // "https://discord.com/api/webhooks/1031664996368785459/CbOvnB5csXWiLATG0pBLaDrvNYfbNK0Xo9SXCWcRxF5XJV83RdkrWERYiBYIWgk_RlpL",
            {
              embeds: [
                {
                  color: 10181046,
                  author: {
                    name: `@${model.username}`,
                    url: `https://superlike.com.tr/creators/${model.username}`,
                    icon_url: user.media.image.url,
                  },
                  title: is_rejected
                    ? "İçeriğin Güncellendi"
                    : "İçeriğin Onaylandı 💜",
                  url: `https://superlike.com.tr/creators/${model.username}`,
                  image: {
                    url: model.media.image.url,
                  },
                  footer: {
                    text: "Superlike Community 🚀",
                  },
                },
              ],
            },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            console.log(response);
          })
          .catch((error) => {
            console.error(error);
          });
      }
    }
  };

  const handleDownload = () => {
    const storage = getStorage();

    getDownloadURL(ref(storage, `videos/${model.media.video.objectId}`))
      .then((url) => {
        fetch(url)
          .then((res) => {
            return res.blob();
          })
          .then((blob) => {
            const href = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.download = `${model.media.video.objectId}.${model.media.video.extension}`;
            a.href = href;
            a.click();
            a.href = "";
          })
          .catch((err) => console.error(err));
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-hidden"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-hidden">
          <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                  <div className="flex-1">
                    {/* Header */}
                    <div className="bg-gray-50 px-4 py-6 sm:px-6">
                      <div className="flex items-start justify-between space-x-3">
                        <div className="space-y-1">
                          <Dialog.Title className="text-lg font-medium text-gray-900">
                            Video{" "}
                            <span className="text-gray-500 text-sm">
                              {model ? model.id : ""}
                            </span>
                          </Dialog.Title>
                        </div>
                        <div className="flex h-7 items-center">
                          <button
                            type="button"
                            className="text-gray-400 hover:text-gray-500"
                            onClick={() => setOpen(false)}
                          >
                            <span className="sr-only">Close panel</span>
                            <XIcon className="h-6 w-6" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>

                    {/* Divider container */}

                    <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                      <div className="space-y-6 px-4 py-5 sm:p-6">
                        <div>
                          <a
                            className="block text-purple-700"
                            href={`/creators/${userData.username}`}
                            rel={"noreferrer"}
                            target={"_blank"}
                          >
                            {"Visit @"}
                            {userData.username}
                          </a>
                          <a
                            className="block text-blue-600"
                            href={
                              userData.social.instagram
                                ? `https://www.instagram.com/${userData.social.instagram}`
                                : "#"
                            }
                            rel={"noreferrer"}
                            target={"_blank"}
                          >
                            {"Visit Instagram Account"}
                          </a>
                        </div>
                        {/* Product Selection */}
                        <Listbox
                          value={selectedProduct}
                          onChange={setSelectedProduct}
                        >
                          {({ open }) => (
                            <>
                              <Listbox.Label className="block text-sm font-medium text-gray-700">
                                Product
                              </Listbox.Label>
                              <div className="mt-1 relative">
                                <Listbox.Button className="bg-white relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm">
                                  <span className="block truncate">
                                    {selectedProduct
                                      ? selectedProduct.name
                                      : "Select"}
                                  </span>
                                  <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                    <SelectorIcon
                                      className="h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                </Listbox.Button>

                                <Transition
                                  show={open}
                                  as={Fragment}
                                  leave="transition ease-in duration-100"
                                  leaveFrom="opacity-100"
                                  leaveTo="opacity-0"
                                >
                                  <Listbox.Options className="absolute z-10 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                                    {products
                                      .sort((a, b) =>
                                        a.name.localeCompare(b.name)
                                      )
                                      .map((item) => (
                                        <Listbox.Option
                                          key={item.id}
                                          className={({ active }) =>
                                            classNames(
                                              active
                                                ? "text-white bg-indigo-600"
                                                : "text-gray-900",
                                              "cursor-default select-none relative py-2 pl-3 pr-9"
                                            )
                                          }
                                          value={item}
                                        >
                                          {({ selected, active }) => (
                                            <>
                                              <span
                                                className={classNames(
                                                  selected
                                                    ? "font-semibold"
                                                    : "font-normal",
                                                  "block truncate"
                                                )}
                                              >
                                                {item.name}
                                              </span>

                                              {selected ? (
                                                <span
                                                  className={classNames(
                                                    active
                                                      ? "text-white"
                                                      : "text-indigo-600",
                                                    "absolute inset-y-0 right-0 flex items-center pr-4"
                                                  )}
                                                >
                                                  <CheckIcon
                                                    className="h-5 w-5"
                                                    aria-hidden="true"
                                                  />
                                                </span>
                                              ) : null}
                                            </>
                                          )}
                                        </Listbox.Option>
                                      ))}
                                  </Listbox.Options>
                                </Transition>
                              </div>
                            </>
                          )}
                        </Listbox>
                        {/* Product Selection */}

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            {"Brand (from User)"}
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={model.brand}
                              disabled
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            {"Product Description (from User)"}
                          </label>
                          <div className="mt-1">
                            <textarea
                              disabled
                              rows={3}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={model.productDescription}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Link
                          </label>
                          <div className="mt-1 relative rounded-md shadow-sm">
                            <input
                              type="text"
                              disabled
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={model.productLink}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Video Description
                          </label>
                          <div className="mt-1">
                            <textarea
                              disabled
                              rows={3}
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              defaultValue={model.videoDescription}
                            />
                          </div>
                        </div>

                        <div className="p-4 mt-2">
                          <div className="flex items-center justify-center">
                            <img
                              className="rounded-lg"
                              src={model.media.image.url}
                            />
                          </div>
                        </div>

                        <div className="p-4 mt-2">
                          <div className="flex items-center justify-center">
                            <ReactPlayer
                              className="m-auto"
                              // width={290}
                              // height={380}
                              // playing
                              controls
                              loop
                              url={model.media.video.url}
                            />
                          </div>
                        </div>

                        <div className="border border-gray-400 rounded-lg p-4">
                          <div className="relative flex items-start">
                            <div className="flex items-center h-5">
                              <input
                                id="comments"
                                aria-describedby="comments-description"
                                name="comments"
                                type="checkbox"
                                defaultChecked={model.is_rejected || false}
                                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                onChange={(e) => {
                                  setIsRejected(e.target.checked);
                                  if (e.target.checked) {
                                    setConfirm(false);
                                  }
                                }}
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="comments"
                                className="font-medium text-gray-700"
                              >
                                Is Rejected?
                              </label>
                            </div>
                          </div>
                          <div className="mt-2">
                            <input
                              type="text"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Reject Reason"
                              defaultValue={model.rejectedReason}
                              onChange={(e) => setRejectReason(e.target.value)}
                            />
                          </div>
                        </div>

                        {!is_rejected && (
                          <div>
                            <div>
                              <legend className="text-sm font-medium text-gray-900">
                                {"Pending / Confirm"}
                              </legend>
                            </div>

                            <div className="space-y-5">
                              <div className="space-y-5 sm:mt-0">
                                <div className="flex">
                                  <Switch.Group
                                    as="div"
                                    className="flex items-center"
                                  >
                                    <Switch
                                      defaultChecked={confirm}
                                      onChange={() => setConfirm(!confirm)}
                                      className={classNames(
                                        confirm
                                          ? "bg-indigo-600"
                                          : "bg-gray-200",
                                        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                      )}
                                    >
                                      <span
                                        aria-hidden="true"
                                        className={classNames(
                                          confirm
                                            ? "translate-x-5"
                                            : "translate-x-0",
                                          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                        )}
                                      />
                                    </Switch>
                                  </Switch.Group>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Landing Order
                          </label>
                          <div className="mt-1">
                            <input
                              type="number"
                              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                              defaultValue={model.landingOrder}
                              onChange={(e) => setLandingOrder(e.target.value)}
                            />
                          </div>
                        </div>

                        <div className="flex items-center justify-start gap-3 text-sm">
                          <div>
                            <p className="font-semibold text-red-600">
                              This operation can not be rollback!
                            </p>
                          </div>
                          <button
                            type="button"
                            className="bg-red-600 text-white p-1 rounded font-bold"
                            onClick={() => deleteVideo()}
                          >
                            Delete
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* Action buttons */}
                  <div className="flex-shrink-0 border-t border-gray-200 px-4 py-5 sm:px-6">
                    <div className="flex justify-end space-x-3">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        className="rounded-md border border-green-300 bg-green py-2 px-4 text-sm font-medium text-green-700 shadow-sm hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                        onClick={() => handleDownload()}
                      >
                        Download
                      </button>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        disabled={!selectedProduct}
                        onClick={() => handleSubmit()}
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
