import { useWizard } from "react-use-wizard";

const Header = () => {
  const { activeStep } = useWizard();
  const steps = [
    // { name: "Referans", percentage: "5%", icon: "" },
    // { name: "Referans Önizleme", percentage: "5%", icon: "" },
    { name: "Kullanıcı Adı", percentage: "15%", icon: "" },
    { name: "Kullanıcı Bilgileri", percentage: "45%", icon: "" },
    { name: "Linkler", percentage: "65.5%", icon: "" },
    { name: "Parola", percentage: "98.5%", icon: "" },
    { name: "Final", percentage: "100%", icon: "" },
  ];

  return (
    <div className="mb-5">
      <div>
        <div className="bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-1 bg-indigo-600 rounded-full"
            style={{ width: steps[activeStep].percentage }}
          />
        </div>
      </div>
    </div>
  );
};
export default Header;
