import React from "react";
import ReactPlayer from "react-player";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  EffectCoverflow,
  EffectCube,
  Autoplay,
} from "swiper";
SwiperCore.use([Navigation, Pagination, EffectCoverflow, EffectCube, Autoplay]);
// Import Swiper styles
import "swiper/css";
import "swiper/css/bundle";
import Typewriter from "typewriter-effect";

import video1 from "../../assets/videos/landing/static_landing_1.mp4";
import video2 from "../../assets/videos/landing/static_landing_2.mp4";
import video3 from "../../assets/videos/landing/static_landing_3.mp4";
import video4 from "../../assets/videos/landing/static_landing_4.mp4";
import video5 from "../../assets/videos/landing/static_landing_5.mp4";
import video6 from "../../assets/videos/landing/static_landing_6.mp4";

const videos = [video1, video2, video3, video4, video5, video6];

import "./style.css";

const params = {
  effect: "cube",
  grabCursor: true,
  slidesPerView: "auto",
  centeredSlides: true,
  speed: 1500,
  cubeEffect: {
    shadow: false,
    slideShadows: true,
    shadowOffset: 20,
    shadowScale: 0.94,
  },
  autoplay: {
    delay: 10000,
  },
};

const Hero = () => {
  return (
    <div className="max-w-7xl m-auto p-5">
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2">
        <div className="px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8 m-auto">
          <h1 className="text-left text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
            <span className="block text-transparent bg-clip-text bg-gradient-to-r from-purple-700 to-pink-600 mix-blend-multiply">
              <Typewriter
                options={{
                  strings: ["#nano", "#micro", "#skin"],
                  autoStart: true,
                  loop: true,
                  cursor: "",
                }}
              />
            </span>
          </h1>
          <h1 className="text-left text-6xl font-extrabold tracking-tight sm:text-7xl lg:text-8xl">
            <span className="block text-purple-800">Influencer</span>
          </h1>
          <p className="text-left ml-1 mt-2 font-medium text-md text-slate-600 tracking-tight">
            {
              "Markalar ile nano ve micro influencerları bir araya getiren platform"
            }
          </p>

          <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
            <div className="space-y-4 sm:space-y-0 sm:mx-auto sm:inline-grid sm:grid-cols-1 sm:gap-5">
              <a
                href="/register"
                className="flex items-center justify-center bg-gradient-to-r from-purple-600 to-indigo-600 bg-origin-border px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white hover:from-purple-700 hover:to-indigo-700"
              >
                Kayıt ol!
              </a>
              {/* <a
                href="/brand-request"
                className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-purple-800 bg-purple-200 hover:bg-purple-300"
              >
                Marka
              </a> */}
            </div>
          </div>
        </div>
        <div className="relative mb-10">
          <Swiper {...params}>
            {videos.map((item, key) => (
              <SwiperSlide key={key}>
                <video
                  className="top-0 left-0 rounded-xl overflow-hidden m-auto"
                  key={key}
                  width="auto"
                  height="auto"
                  muted
                  autoPlay
                  // controls
                  playsInline
                  loop
                >
                  <source src={item} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
                {/* <ReactPlayer
                  className="top-0 left-0 rounded-xl overflow-hidden m-auto"
                  width="300"
                  height="500"
                  playsinline
                  playing
                  muted
                  controls
                  loop
                  url={item}
                /> */}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Hero;
