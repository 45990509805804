import { useWizard } from "react-use-wizard";

const Header = () => {
  const { activeStep } = useWizard();
  const steps = [
    { name: "ContentType", percentage: "15%", icon: "" },
    { name: "Video", percentage: "45.5%", icon: "" },
    { name: "CoverImage", percentage: "65.5%", icon: "" },
    { name: "ContentOptions", percentage: "95%", icon: "", show: true },
    { name: "Final", percentage: "100%", icon: "" },
  ];

  return (
    <div className="mb-5">
      <div>
        <div className="bg-gray-200 rounded-full overflow-hidden">
          <div
            className="h-1 bg-indigo-600 rounded-full"
            style={{ width: steps[activeStep].percentage }}
          />
        </div>
      </div>

      {steps[activeStep]?.show && (
        <p className="text-green-900 font-semibold text-center mt-2">Neredeyse bitti!</p>
      )}
    </div>
  );
};
export default Header;
