import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import useWindowSize from "react-use/lib/useWindowSize";
import Confetti from "react-confetti";
import phoneRuner from "../../../../lotties/phoneRuner";
import Lottie from "react-lottie";

const Final = ({ ...props }) => {
  const { loader } = props;
  const { previousStep, isLastStep } = useWizard();
  const { width, height } = useWindowSize();

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: phoneRuner,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const children = () => {
    return (
      <div className="text-center">
        <h4 className="text-lg font-bold">Harika! İçeriğin yükleniyor.</h4>
        <h6 className="font-semibold mt-5">
          Yükleme bittikten sonra, kendi sayfana yönlendireceğiz.
        </h6>

        <div className="mt-5">
          <Lottie options={defaultOptions} height={300} width={300} />
        </div>
        <Confetti width={width} height={height} />
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Neredeyse bitti"}
        subTitle={""}
        children={children()}
        isLastStep={isLastStep}
      />
    </>
  );
};

export default Final;
