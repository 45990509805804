import React, { useState } from "react";
import { db } from "../../../firebase";
import { query, collection, orderBy, getDocs, where } from "firebase/firestore";
import { classNames, friendlyDateTime } from "../../../utils";

export default function History({ ...props }) {
  const { data, setModel, setOpen, activity, setActivity } = props;

  const getMessageActivity = async (item) => {
    const messageQuery = query(
      collection(db, "ticket_messages"),
      where("ticket_id", "==", item.id),
      orderBy("created_at", "asc")
    );
    const messageSnapshot = await getDocs(messageQuery);
    const messageList = [];
    messageSnapshot.forEach((doc) => {
      messageList.push({ ...doc.data(), id: doc.id });
    });
    setActivity(messageList);
  };

  const handleOpenModel = async (item) => {
    await getMessageActivity(item);
    setModel(item);
    setOpen(true);
  };

  const statusDescription = {
    1: { description: "Yeni", color: "indigo" },
    2: { description: "İşlemde", color: "yellow" },
    3: { description: "Tamamlandı", color: "green" },
  };

  return (
    <div className="overflow-x-auto -mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              #
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Created At
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Username
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Subject
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, key) => (
            <tr
              key={key}
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => handleOpenModel(item)}
            >
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.rowNumber}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {friendlyDateTime(item.created_at)}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {`@${item.username}`}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.subject}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                <span
                  className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium bg-${
                    statusDescription[item.status].color
                  }-100 text-${statusDescription[item.status].color}-800`}
                >
                  {statusDescription[item.status].description}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
