import React from "react";
import Header from "../components/Header/Header";
import Footer from "../components/Footer/Footer";
import { motion } from "framer-motion";

export default function AppLayout({ children, bgColor="white" }) {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{
        delay: 0.4,
        x: { duration: 1 },
        default: { ease: "linear" },
      }}
    >
      <div className={`bg-[${bgColor}]`}>
        <div className="flex flex-col h-screen justify-between">
          <div>
            <Header bgColor={bgColor} />
            {children}
          </div>
          <Footer />
        </div>
      </div>
    </motion.div>
  );
}
