import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";

const ContentOptions = ({ ...props }) => {
  const { payload, setPayload, handleSubmit } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();

  const handleNext = () => {
    handleSubmit();
    nextStep();
  };

  const isFormValid = () => {
    let result = false;

    if (payload.brand && payload.productDescription) {
      result = true;
    }

    return result;
  };

  const productInfo = () => {
    return (
      <div>
        <div className="mt-5">
          <label className="block text-sm font-medium text-gray-700">
            {"Marka *"}
            <span className="block text-xs text-gray-500">
              {"İçerikte kullandığınız ürünün markasını yazınız"}
            </span>
          </label>
          <div className="mt-1">
            <input
              type="text"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={payload.brand}
              onChange={(e) =>
                setPayload({ ...payload, brand: e.target.value })
              }
            />
          </div>
        </div>

        <div className="mt-5">
          <label className="block text-sm font-medium text-gray-700">
            {"Ürün Bilgisi *"}
            <span className="block text-xs text-gray-500">
              {"İçerikte kullandığınız ürünün adını yazınız"}
            </span>
          </label>
          <div className="mt-1">
            <textarea
              rows={3}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              defaultValue={payload.productDescription}
              onChange={(e) =>
                setPayload({ ...payload, productDescription: e.target.value })
              }
            />
          </div>
        </div>
      </div>
    );
  };

  const children = () => {
    return productInfo();
  };

  return (
    <>
      <StepBase
        title={"İçerik Detayı"}
        subTitle={""}
        previousStep={previousStep}
        nextStep={handleNext}
        children={children()}
        isLastStep={isLastStep}
        disableNextStep={!isFormValid()}
      />
    </>
  );
};

export default ContentOptions;
