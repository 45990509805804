import { useState } from "react";
import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import { db } from "../../../firebase";
import { doc, getDoc } from "firebase/firestore";
import { normalizedText } from "../../../utils";

const Username = ({ ...props }) => {
  const { payload, setPayload } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();
  const [loader, setLoader] = useState(true);
  const [userExist, setUserExist] = useState(false);

  const checkUsernameExist = async () => {
    if (payload.username) {
      const docRef = doc(db, "users", payload.username);
      const docSnap = await getDoc(docRef);
      const exist = docSnap.exists();
      setUserExist(exist);
      setLoader(false);
      if (!exist) {
        nextStep();
      }
      return exist;
    }
  };

  const toLower = (e) => {
    e.target.value = normalizedText(e.target.value);
  };
  const handleInputChange = (e) => {
    setLoader(true);
    setPayload({ ...payload, username: String(e.target.value).trim() });
  };

  const isUsernameValid = () => {
    return /^[a-zA-Z0-9_]{1,15}$/.test(payload.username);
  };

  const handleNext = async () => {
    await checkUsernameExist();

    if (!userExist && isUsernameValid() && !loader) {
      nextStep();
    }
  };

  const children = () => {
    return (
      <div className="max-w-xs mx-auto">
        <div className="mt-1 border-b border-gray-300 focus-within:border-indigo-600 text-5xl">
          <input
            type="text"
            className="font-medium block w-full px-6 py-6 text-xl border-0 border-b border-transparent bg-gray-50 focus:border-indigo-600 focus:ring-0"
            placeholder="Kullanıcı adı giriniz"
            defaultValue={payload.username}
            // onBlur={checkUsernameExist}
            onInput={toLower}
            onChange={(e) => handleInputChange(e)}
          />
        </div>
        {userExist && (
          <p className="mt-2 text-sm text-red-600">
            Kullanıcı adı alınmış, lütfen farklı bir kullanıcı adı giriniz.
          </p>
        )}
        {!isUsernameValid() && (
          <p className="mt-2 text-xs text-gray-600">
            Kullanıcı adınız yalnızca alfa numerik olmalıdır.
          </p>
        )}
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Kullanıcı Adı"}
        subTitle={""}
        previousStep={previousStep}
        disablePreviousStep
        nextStep={handleNext}
        children={children()}
        isLastStep={isLastStep}
      />
    </>
  );
};

export default Username;
