import React, { useState } from "react";
import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import { friendlyInMB } from "../../../../utils";
import ReactPlayer from "react-player";

const Video = ({ ...props }) => {
  const { video, setVideo, videoPreview, setVideoPreview } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();

  const handleVideoChange = (e) => {
    setVideoPreview(URL.createObjectURL(e.target.files[0]));
    setVideo(e.target.files[0]);
  };

  const children = () => {
    return (
      <div className="border p-2 rounded-lg">
        <div className="flex items-center justify-center">
          <div>
            <button
              type="button"
              className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              onClick={() => document.getElementById("video").click()}
            >
              Gözat
            </button>
            <input
              id="video"
              type="file"
              className="sr-only"
              accept="video/*"
              onChange={(e) => handleVideoChange(e)}
            />
          </div>
        </div>
        {videoPreview && (
          <div className="p-4 mt-2">
            <div className="flex items-center justify-center">
              <ReactPlayer
                className="m-auto"
                width={290}
                // height={380}
                playing
                muted
                controls
                loop
                url={videoPreview}
              />
            </div>

            {video && (
              <span className="block mt-1 text-gray-600 text-xs font-bold">
                {"Boyut: "} {friendlyInMB(video.size)} {"MB"}{" "}
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Video 📹"}
        subTitle={"Sabırsızlıkla beklenen işte o video"}
        previousStep={previousStep}
        nextStep={nextStep}
        children={children()}
        isLastStep={isLastStep}
        disableNextStep={!videoPreview}
      />
    </>
  );
};

export default Video;
