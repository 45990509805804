import settings from "./config";

const FAQList = {
  shared: [
    {
      question: "Influencer için Superlike nedir?",
      answer:
        "İçerik ürettikçe gelir elde edebilecekleri, markalarla iletişime geçebilecekleri ve kendilerini geliştirebilecekleri bir sosyal topluluktur. Bu toplulukta influencerlar gelir elde etme fırsatının yanı sıra markalarla çalışma fırsatı, sosyal medya hesaplarını geliştirme ve sosyal bir ortam olmasıyla birlikte network genişletebilme imkanı sağlamaktadır.",
    },
    {
      question: "Influencer olarak Superlike’a nasıl kayıt olabilirim?",
      answer:
        "https://superlike.com.tr/register sayfasından hızlıca bilgilerini girerek kayıt olabilirsin.",
    },
    {
      question: "Marka olarak neden etkinlik yapmalıyım?",
      answer:
        "İnfluencerlar aracılığıyla ürün tanıtımları veya markalarının reklamını yapmak isteyen tüm markalar için içerikler olmazsa olmazıdır. Instagram üzerinden en çok erişim ve dönüşüm alan içerik türü olan reels üretmek, TikTok’ta da yer almak istiyorsanız sizin de etkinlik yapmanız gerekir. İstediğiniz içeriklerin ilham veren videolarını Supeerlike ekibine iletiyorsunuz ve yapılan etkinliklerde aynı konseptte içeriklere sahip oluyorsunuz. Bu içerikler aynı zamanda marka isterse influencer ile birlikte collaboration da yapılıp influencer ile birlikte paylaşım sağlamış olabiliyorsunuz.",
    },
  ],
  influencer: [
    {
      question: "Influencer için Superlike nedir?",
      answer:
        "İçerik ürettikçe gelir elde edebilecekleri, markalarla iletişime geçebilecekleri ve kendilerini geliştirebilecekleri bir sosyal topluluktur. Bu toplulukta influencerlar gelir elde etme fırsatının yanı sıra markalarla çalışma fırsatı, sosyal medya hesaplarını geliştirme ve sosyal bir ortam olmasıyla birlikte network genişletebilme imkanı sağlamaktadır.",
    },
    {
      question: "Influencer olarak Superlike’a nasıl kayıt olabilirim?",
      answer:
        "https://superlike.com.tr/register sayfasından hızlıca bilgilerini girerek kayıt olabilirsin.",
    },
    {
      question: "Etkinlikler nedir?",
      answer:
        "Etkinlikler ödüllü içerik üretmek anlamına geliyor. Etkinlikler marka ile işbirlikleri anlamına gelmektedir. Etkinlik görevleri her etkinlik açıklamasında yer almaktadır. Etkinlik görevlerini yerine getirenlerin ödülleri hakedişe tabidir. Etkinlik tipine göre etkinlikler oluşup ilham veren videolardaki örnekler nezdinde içerikler çekilmeli. Bu etkinlikler reels, story, post, canlı yayın vb. içerikler olabilir. Etkinliğe katılan kişiler gerekli görevleri yerine getirdiği takdirde ödüle tabi olur.",
    },
    {
      question: "Etkinliklere nasıl katılabilirim?",
      answer:
        "Etkinliklere katılabilmek için Superliker olman gerekiyor. Üye olan herkes öncelikle community ünvanını alır. Supeerliker olmak için gerekli görevleri yerine getirmen gerekiyor. Görevlerini yerine getirdiğin zaman Supeerliker unvanını alırsın. Supeerliker olman için mutlaka görevleri yerine getirmen gerekiyor.",
    },
    {
      question: "Etkinliklere içerik nasıl çekmem gerekir?",
      answer:
        "Etkinlik içerikleri her etkinlikte yer alan “İlham Veren Videolar” a göre çekilmeli. İlham veren videolardaki konsept, içerik tarzı ve video süreci nasıl işliyor ise hemen hemen benzer bir konsept olmalı. Aksi halde içerikler onaylanmamaktadır. Çok özel içerikler istisnaya tabi olmaktadır.",
    },
    {
      question: "Etkinliklerdeki içerikleri ne zamana kadar çekmem gerekiyor?",
      answer:
        "Etkinliklerde bitiş tarihleri yer almaktadır. Etkinlik ürün satın almalı ise ‘Ürünlerin Satışa Kapanma Tarihi’, sadece video yüklemek için ise ‘Video Bitiş Tarihi’ veya farklı bir etkinlik ise direkt ‘Etkinlik Bitiş Tarihi’ baz alınabilir. Katılımcı sayısı ve diğer detaylar etkinlik açıklamasında yer almaktadır.",
    },
    {
      question: "Etkinliklere içerik yüklemede geç kalırsam ne olur?",
      answer:
        "Her etkinlikte belirli görevler yer almaktadır. Etkinlik görevlerini başarılı olarak tamamlamayan kişiler hakedişe tabi olmayacaklar.",
    },
    {
      question: "Nasıl gelir elde edebilirim?",
      answer:
        "Hakediş bazlı etkinliklere katılıp gerekli görevleri yerine getirirsen sen de pasif gelir edebilirsin. Düzenli olarak Supeerlike’ı takip etmen ve sosyal medya’da olman takip açısından önemli.",
    },
    {
      question: "Hakedişler ne zaman hesaplarımıza geçmektedir?",
      answer:
        "Hakedişler her ayın 1’i ile 5’i arasında profil bilgilerinin olduğu sayfada yer alan banka hesap bilgilerinize göre ödemeler gerçekleşmektedir.",
    },
    {
      question: "Profil bilgilerimi değiştirebilir miyim?",
      answer:
        "Evet değiştirebilirsin. Profilinde yer alan (...) üç noktaya tıklayarak profil bilgilerini istediğin gibi güncelleyebilirsin.",
    },
    // {
    //   question: "Puan sistemi nedir?",
    //   answer: "",
    // },
    {
      question: "Ürüne bağlı içerik nasıl yükleyebilirim?",
      answer:
        "Profilinde yer alan (...) üç noktadan ‘Video Yükle’ alanına tıklayarak ürün bilgisi ve link bilgini paylaşıyorsun. Ekiplerimiz ürün kartına bağlayarak videonu gözden geçiriyor. Onaylanan videolar profilinde onaylandı statüsüne düşüyor ve videon yayınlanıyor. Artık herşey hazır!",
    },
    {
      question:
        "Üretilen içerikler hangi sosyal medya hesaplarında paylaşılıyor?",
      answer:
        "İstediğin tüm sosyal medya hesaplarında paylaşabilirsin fakat önemli olan İnstagram’da paylaşman. Etkinliklerde ise etkinlik açıklamasında yer alan görevlerde hangi sosyal medya hesabı yazıyorsa orada paylaşman gerekiyor.",
    },
    {
      question: "İçerik yükleme kriterleri nelerdir?",
      answer:
        "Etkinlikler için etkinlik görevleri veya açıklamasında yer almaktadır. Etkinlik dışı içerik yüklemelerinde ise ürün yeterince görünüyor mü, anlatım yeterince açık mı veya aykırı herhangi bir durum var mı şeklinde gerekli kontroller yapılıp onay sürecine geçilmektedir.",
    },
    {
      question: "Ürünlere bağlı yüklediğim içeriklere link nasıl verebilirim?",
      answer:
        "İçerik yüklenirken influencerdan link alınıyor. Link affilate linki olabilir veya ürünle ilgili herhangi bir link olabilir. Bu linkler Supeerlike moderatörlerinin onayından geçip kontrol ediliyor. Bu arada Supeerlike profil linkini sosyal medyada paylaşırsan daha çok içeriğin görülür ve alışverişe dönüşümün daha hızlı olabilir. Unutma burası senin platformun :)",
    },
  ],
  brand: [
    {
      question: "Markalar için Superlike nedir?",
      answer:
        "Superlike, markalar için bir content fabrikasıdır. Nano ve mikro influencer aracılığıyla markalara istenilen detaylarda reels, story, post, canlı yayın vb. içerikler üretilmektedir. spesifik içerikler üretilebilmesi (kutu açılışı, ürün deneyimi, ürün özelliklerinin gösterilmesi vs), üretilen içeriklerin yönetilebilmesi (revize verebilme), düşük maliyetlerle içerik sahibi olma, influencerın erişiminden faydalanarak marka bilinirliğini arttırma",
    },
    {
      question: "Marka olarak neden etkinlik yapmalıyım?",
      answer:
        "İnfluencerlar aracılığıyla ürün tanıtımları veya markalarının reklamını yapmak isteyen tüm markalar için içerikler olmazsa olmazıdır. Instagram üzerinden en çok erişim ve dönüşüm alan içerik türü olan reels üretmek, TikTok’ta da yer almak istiyorsanız sizin de etkinlik yapmanız gerekir. İstediğiniz içeriklerin ilham veren videolarını Supeerlike ekibine iletiyorsunuz ve yapılan etkinliklerde aynı konseptte içeriklere sahip oluyorsunuz. Bu içerikler aynı zamanda marka isterse influencer ile birlikte collaboration da yapılıp influencer ile birlikte paylaşım sağlamış olabiliyorsunuz. ",
    },
    {
      question: "Üretilen içeriklerde revize verebiliyor muyuz?",
      answer:
        "Tabii ki revize verebilirsiniz. Önemli olan markanın istediği contenti oluşturmak. Burada ilham veren videolara göre belirlenen içeriklerde revizeler vererek asıl istenilen içeriğe sahip olabilirsiniz.",
    },
    {
      question: "Üretilen içerikleri Superlike’tan alabiliyor muyuz?",
      answer: "Yapılan etkinliklerde üretilen içerikler markalara aittir.",
    },
    {
      question: "Üretilen içerikler sadece reels mı?",
      answer:
        "Hangi etkinliğinizi yaptığınız ile alakalı. Reels, story, post hatta canlı yayın etkinlikleri bile düzenleyebiliyorsunuz.",
    },
    {
      question: "Influencerlar link paylaşabiliyor mu?",
      answer:
        "Etkinliklerin içerisinde story varsa her storye markanın ileteceği link paylaşılabilir.",
    },
    {
      question:
        "Üretilen içerikler hangi sosyal medya hesaplarında paylaşılıyor?",
      answer:
        "Üretilen içerikler İnstagram hesabında paylaşılmaktadır. TikTok hesaplarında paylaşılabilmesi için etkinlik yapılmadan önce Supeerlike ekibiyle iletişime geçmeniz gerekiyor.",
    },
    {
      question:
        "Superlike sitesinde ürünler ve linkler ne kadar süre ile kalıyor?",
      answer:
        "Ürünler sınırsız sürede web sitesinde kalmaktadır. Ürün veya hizmet marka tarafından kaldırıldıysa veya artık üretilmiyorsa o ürün marka tarafından Supeerlike ekibine iletilir. Web sitesinden kaldırılması gerçekleşir",
    },
    {
      question:
        "Herhangi bir sorunuz olduğunda Superliker’lar ile nasıl iletişime geçebiliriz?",
      answer:
        "hello@supeerlike.com adresinden mail yolu ile iletişime geçebilirsiniz.",
    },
  ],
};

export default FAQList;
