import { motion } from "framer-motion";
import logo from "../../assets/logo/supeerlike_new_logo.png";

const Loader = () => {
  return (
    <div className="flex h-screen justify-center items-center">
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{
          delay: 0.4,
          x: { duration: 1 },
          default: { ease: "linear" },
        }}
      >
        {/* <span className="text-5xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-700 to-pink-600 mix-blend-multiply">
          supeerlike
        </span> */}
        <img className="h-16 w-auto" src={logo} alt="" />
      </motion.div>
    </div>
  );
};

export default Loader;
