import { useState, useEffect, useRef } from "react";
import ReactPlayer from "react-player";
import {
    ChevronUpIcon,
    ChevronDownIcon,
    HeartIcon,
    PlayIcon,
    XIcon,
} from "@heroicons/react/outline";
import { db } from "../../firebase";
import {
    collection,
    query,
    getDocs,
    doc,
    where,
    limit,
    getDoc,
} from "firebase/firestore";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { classNames } from "../../utils";

export default function VideoPlayer() {
    const { videoId } = useParams();

    const [video, setVideo] = useState();
    const [user, setUser] = useState()
    const [product, setProduct] = useState();
    const [loader, setLoader] = useState(true);
    const [playing, setPlaying] = useState(false);
    const [muted, setMuted] = useState(false);
    const pending = useRef(true);
    const navigate = useNavigate();

    const handleGetUser = async (userId) => {
        const userQuery = query(
            collection(db, "users"),
            where("uid", "==", userId),
            limit(1)
        );
        let user = {};
        const userSnapshot = await getDocs(userQuery);
        userSnapshot.forEach((doc) => {
            user = { ...doc.data(), id: doc.id };
            setUser(user);
        });
    };

    const handleGetProduct = async (productId) => {
        if (productId) {
            const productRef = doc(db, "products", productId);
            const productSnap = await getDoc(productRef);

            if (productSnap.exists()) {
                const productInfo = { ...productSnap.data(), id: productSnap.id };
                setProduct(productInfo);
            }
        } else {
            setProduct(null);
        }
    };

    useEffect(() => {
        async function getData() {
            if (pending.current) {

                const videoRef = doc(db, "videos", videoId);
                const videoSnap = await getDoc(videoRef);

                if (videoSnap.exists()) {
                    const _video = { ...videoSnap.data(), id: videoSnap.id }
                    setVideo(_video)
                    await handleGetUser(_video.uid);
                    await handleGetProduct(_video.productId);
                    setLoader(false)
                }
            }
        }
        getData();
    }, []);

    const actionButtonClass =
        "mb-2 p-1 border border-transparent rounded-full text-white bg-[#1a1a1a] hover:bg-white hover:text-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500";
    const actionButtonIconSize = "w-5 h-5";

    return !loader && (
        <div className="relative z-50">
            <div className="fixed inset-0 h-screen flex mb-10 flex-col bg-[#1a1a1a]" />
            <div className="fixed inset-0 flex items-center justify-center" >
                <div className="relative w-screen">
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{
                            delay: 0.1,
                            x: { duration: 1 },
                            default: { ease: "linear" },
                        }}
                    >
                        <div onClick={() => setPlaying(!playing)}>
                            <ReactPlayer
                                className="top-0 left-0 rounded-xl overflow-hidden"
                                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                // Disable right click
                                onContextMenu={e => e.preventDefault()}
                                controls
                                width="100%"
                                height={"90vh"}
                                url={video.media.video.url}
                            />
                        </div>

                        <div className="absolute inset-y-0 left-5 top-5">
                            {user && (
                                <div
                                    className="cursor-pointer"
                                    onClick={() => navigate(`/creators/${user.username}`)}
                                >
                                    <img
                                        className="inline-block h-12 w-12 rounded-full object-cover border-white border-2"
                                        src={user.media.image.url}
                                        alt={user.personal.fullname}
                                    />
                                    <span className="ml-2 text-white font-extrabold">
                                        {user.username}
                                    </span>
                                </div>
                            )}
                        </div>

                        <div className="hidden absolute inset-x-0 bottom-5 bg-[#0000006b]">
                            <div className="flex justify-start items-center">
                                {product && product.media && product.media.images && product.media.images.length > 0 && (
                                    <div
                                        className="cursor-pointer"
                                        onClick={() => navigate(`/product/${product.id}`)}
                                    >
                                        <img
                                            className="inline-block h-24 w-24 rounded-lg object-cover "
                                            src={product.media.images[0].url}
                                            alt={product.name}
                                        />
                                    </div>
                                )}
                                <span className="text-white text-sm font-semibold ml-2">
                                    {product.name}
                                </span>
                            </div>
                        </div>

                    </motion.div>
                </div>
            </div>
        </div>
    );
}
