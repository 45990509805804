import React from "react";
import { classNames } from "../../../utils";

export default function History({ ...props }) {
  const { data, setModel, setOpen } = props;

  const handleOpenModel = (item) => {
    setModel(item);
    setOpen(true);
  };

  const friendlyTime = (timestamp) => {
    const date = new Date(timestamp * 1000).toLocaleDateString("tr-TR");
    const time = new Date(timestamp * 1000).toLocaleTimeString("tr-TR", {
      hour: "2-digit",
      minute: "2-digit",
    });
    return `${date} ${time}`;
  };

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              #
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Username
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              XP
            </th>
            <th
              scope="col"
              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
            >
              Level
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Fullname
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Email
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Phone
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Created At
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, key) => (
            <tr
              key={key}
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => handleOpenModel(item)}
            >
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {key + 1}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {"@"}
                {item.username}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.scoring ? item.scoring.score : ""}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.scoring ? item.scoring.group : ""}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.personal.fullname}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.email}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.phone}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {friendlyTime(item.created_at)}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                <span
                  className={classNames(
                    "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                    !item.is_blocked
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                  )}
                >
                  {!item.is_blocked ? "Active" : "Blocked"}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
