import React from "react";
import AppLayout from "../../layouts/AppLayout";

const Membership = () => {
  return (
    <AppLayout>
      <div className="max-w-6xl m-auto mb-10">
        <h1 className="text-black text-2xl text-center">
          SUPEERLİKE PLATFORM ÜYELİK SÖZLEŞMESİ
        </h1>
        <div>
          <p className="mt-10 text-sm font-bold">1. TARAFLAR</p>
          <p className="mt-5">
            İşbu Platform Üyelik Sözleşmesi <b>(&quot;Sözleşme&quot;)</b>; şirket merkezi
            Aksaray Mah. Gazi Mustafa Kemalpaşa Cad. Ülkü Han Apt. No: 39 İç Kapı No: 3 Fatih/ İstanbul
            adresinde bulunan Super Pazarlama ve Teknoloji Limited Şirketi <b>(&quot;Superlike&quot;)</b> ile işbu Sözleşme’yi onaylayarak kabul etmiş
            olan üye / influencer / içerik üreticisi <b>(&quot;İçerik Üreticisi&quot;)</b> arasında aşağıdaki hüküm ve şartlar dairesinde akdedilmiştir.
            İşbu Sözleşme kapsamında Superlike ve İçerik Üreticisi ayrı
            ayrı <b>(&quot;Taraf&quot;)</b>, birlikte <b>(&quot;Taraflar&quot;)</b> olarak anılacaktır.

          </p>
          <p className="mt-10 text-sm font-bold">2. SÖZLEŞME’NİN KONUSU</p>
          <p className="mt-5">
            İçerik Üreticisi, her türlü ticari ve fikri mülkiyet hakkı Superlike’a ait
            olan Superlike Platformu’na <b>(&quot;Platform&quot;)</b> üye olarak kendisine ait sosyal medya hesaplarında,
            Platform’da ve Platform’a bağlı olan diğer uygulama/platformlarda Superlike
            tarafından belirlenen kurallar çerçevesinde, Superlike uygulaması <b>(&quot;Uygulama&quot;)</b>{" "}
            üzerinden satılan ürünler ve/veya Uygulama ile ilgili içerik üretimi ve tanıtım yapabilecektir.
          </p>
          <p className="mt-10 text-sm font-bold">
            3. TARAFLARIN HAK VE YÜKÜMLÜLÜKLERİ
          </p>
          <div>
            <p className="mt-5">
              <b className="mr-5">3.1.</b>
              İçerik Üreticisi, Platform’a üye olmak için Superlike tarafından talep edilen başvuru formundaki bilgileri tam, doğru ve güncel bir şekilde sağlayarak bu Sözleşme’yi onaylayacaktır. Superlike, İçerik Üreticisi’nin üyelik başvurusunu kendi takdirine bağlı olarak reddedebilecektir. İçerik Üreticisi’nin üyelik başvurusunun Superlike tarafından herhangi bir nedenle reddedilmesi halinde bu Sözleşme kendiliğinden sona erecektir. İçerik Üreticisi, Superlike platformuna yüklediği içerikler var ise, bu içeriklerin de kullanım hakkını Superlike’a devretmiş olup, üyeliği sonlansa bile bu içeriğin kullanım hakkı Superlike platformunda kalmış olacaktır.
            </p>
            <p className="mt-5">
              <b className="mr-5">3.2.</b>
              İçerik Üreticisi üyelik başvurusu yaparken ön başvuru formunda veya sonraki süreçlerde sağladığı bilgilerde herhangi bir değişiklik olması halinde söz konusu bilgileri derhal güncelleyecektir. İçerik Üreticisi’ne ilişkin bilgilerin eksik veya gerçeğe aykırı olarak verilmesi ya da güncel olmaması nedeniyle Platform’dan ve ilgili süreçlerden faydalanılamamasından Şirket sorumlu değildir. Ayrıca, içerik üreticisinin yanlış bir bilgi vermesi sebebiyle oluşacak maddi ve manevi zararlardan İçerik Üreticisi sorumlu olacaktır.
            </p>
            <p className="mt-5">
              <b className="mr-5">3.3.</b>
              İçerik Üreticisi Platform üyeliği kapsamındaki faaliyetleri gerçekleştirmek için Superlike tarafından belirlenerek davet edileceği iletişim kanallarına katılıp bunları takip etmekle yükümlüdür. İçerik Üreticisi, Platform’un ve iletişim kanallarının kullanımına imkan veren hesaplar, kullanıcı adı ve şifre de dahil olmak üzere tüm bilgilerin kullanım ve yönetiminden bizzat sorumludur. İçerik Üreticisi’ne ait hesap, kullanıcı adı ve şifre ile gerçekleştirilen her işlem bizzat İçerik Üreticisi tarafından gerçekleştirilmiş addedilecek ve bu bilgilerinin İçerik Üreticisi dışında bir kişi tarafından kullanılması, kaybolması veya el değiştirmesi nedeniyle İçerik Üreticisi ve/veya üçüncü kişilerin uğradığı zararlardan münhasıran İçerik Üreticisi sorumlu olacaktır. İçerik Üreticisi, şifresinin yetkisiz kullanımı veya güvenliğin başka şekilde ihlalinden haberdar olduğunda bu durumu derhal Şirket’e bildirecektir.
            </p>
            <p className="mt-5">
              <b className="mr-5">3.4.</b>
              Etkinlik ve görevlere katılım için duyurular Superlike tarafından belirlenen iletişim kanalları üzerinden yapılacaktır ve bunlara ilişkin şartlar ve koşullar Superlike tarafından belirlenerek duyurulacaktır.
            </p>
            <p className="mt-5">
              <b className="mr-5">3.5.</b>
              İçerik Üreticisi, Superlike tarafından verilen görevler kapsamında paylaşılanlar da dahil olmak üzere sosyal medya hesaplarında paylaştığı her türlü içerikten, Platform ile ilişkili olarak yürüttüğü her türlü faaliyetten münhasıran sorumlu olduğunu, söz konusu içerik ve faaliyetlerle ilgili yapılması gereken bildirimlerin yapılmasından, mevzuat kullanılması gereken ifadelerin kullanılmasından veya ilgili mevzuatı ihlal eden içerik ve ifadelerin kullanılmamasından, varsa alınması gereken izinlerin temin edilmesinden münhasıran sorumlu olduğunu, Superlike’ın söz konusu içerik ve faaliyetleri kontrol etme yükümlülüğü bulunmadığını, bunlara ilişkin olarak marka sahipleri, idari otoriteler veya sair üçüncü kişilerce iletilebilecek her türlü talebin muhatabının kendisi olduğunu kabul, beyan ve taahhüt eder.
            </p>
            <p className="mt-5">
              <b className="mr-5">3.6.</b>
              İçerik Üreticisi tarafından üretilip paylaşılan içerikleri Superlike kendi sosyal medya hesaplarında Uygulama’da ve sair mecralarda dilediği kapsamda kullanabilecektir. İçeriklerin Superlike tarafından kullanımına İçerik Üreticisi bu Sözleşme ile muvafakat vermektedir.
            </p>
            <p className="mt-5">
              <b className="mr-5">3.7.</b>
              Superlike’ın, İçerik Üreticisi tarafından paylaşılan içerikleri uygunsuz bulması durumunda (paylaşımların Superlike kurum kimliğiyle uyuşmaması, hakaret, argo vb. içerikler barındırması, gizli bilgilerin paylaşılması, Superlike’ın veya iş ortaklarının marka imajına zarar vermesi nedenleri dahil ancak bunlarla sınırlı olmamak üzere) ilgili durum İçerik Üreticisi’ne bildirilecek ve uygunsuz görülen içerik İçerik Üreticisi tarafından derhal ilgili sosyal medya hesabından kaldırılacaktır.
            </p>
            <p className="mt-5">
              <b className="mr-5">3.8.</b>
              Superlike, önceden haber vermeksizin Platform’da değişiklikler yapma, Platform’un yeni sürüm, yayın, genişletilmiş biçimlerini vb. çıkarma, Platform’un içeriğini ve kullanım alanını değiştirme hakkına sahiptir.
            </p>
            <p className="mt-5">
              <b className="mr-5">3.9.</b>
              İçerik Üreticisi, Platform üyeliği kapsamında gerçekleştireceği tüm işlemlerde, işbu Sözleşme ve ekleri ile Superlike tarafından belirlenecek sair koşullara, kanuna ve dürüstlük ilkelerine uymayı, Superlike’ın ve iş ortaklarının ticari imajını ve marka değerini düşürücü, zedeleyici kasıtlı davranış ve tutumlarda bulunmamayı, Superlike’ın ve iş ortaklarının marka hakkına ve ticari itibarına zarar vermemeyi taahhüt eder. Platform üyeliği ve içerik paylaşımı ile ilişkili her türlü eylemden doğan her türlü yasal, idari ve cezai sorumluluk İçerik Üreticisi’ne aittir. Superlike, İçerik Üreticisi’nin Platform üyeliği kapsamında gerçekleştirdiği faaliyetler, paylaştığı içerikler ve/veya işbu Sözleşme ve yasaya aykırı eylemleri neticesinde İçerik Üreticisi’nin veya üçüncü kişilerin uğrayabilecekleri zararlardan doğrudan ve/veya dolaylı olarak hiçbir şekilde sorumlu tutulamaz. Üçüncü kişilerden veya idari otoritelerden bu kapsamda gelecek her türlü talep ile İçerik Üreticisi’nin eylemlerinden kaynaklanan zararlar İçerik Üreticisi’ne rücu edilecektir.
            </p>
          </div>
          <p className="mt-10 text-sm font-bold">4. FİKRİ MÜLKİYET HAKLARI</p>
          <div>
            <p className="mt-5">
              <b className="mr-5">4.1.</b>
              İçerik Üreticisi, işbu Sözleşme kapsamında üretilmiş,
              üretilecek ve/veya geliştirilmiş tüm içerik, materyal, çalışma, görsel, ilan,
              grafik yapımlar, fotoğraf ve videolar dâhil ve fakat bunlarla sınırlı
              olmaksızın her türlü yaratıcı iş ve malzeme üzerindeki 5846 sayılı
              Fikir ve Sanat Eserleri Kanunu&apos;ndan doğan tüm mali haklar
              (işleme, çoğaltma, yayma, temsil ve işaret, ses ve/veya görüntü nakline yarayan araçlarla umuma iletim hakkı)
              ile manevi hakları (umuma arz salahiyeti, adın belirtilmesi salahiyeti, eserde değişiklik yapılmasını menetme yetkisi)
              kullanma yetkisi dâhil ve fakat bunlarla sınırlı olmaksızın ve yer, sayı, süre, mecra da
              dahil herhangi bir kısıtlamaya konu olmaksızın her türlü telif, kullanım ve mülkiyet hakkını
              Superlike&apos;a devrettiğini, Superlike&apos;ın bu haklarını bizzat veya üçüncü kişiler aracılığı
              ile kullanmasına muvafakat ettiğini, bu nedenle Superlike&apos;dan her ne ad altında olursa olsun
              başkaca bir hak ve alacak talebi olmadığını ve olmayacağını, bu hususlara açık bir şekilde rıza gösterdiğini
              gayrikabili rücu beyan ve kabul eder. Telif hakları Superlike&apos;a devredilir ve Superlike, İçerik
              Üreticisinin platforma yüklediği içeriği, İçerik Üreticisine bildirmesine gerek duymadan istediği şirket,
              kurum, kuruluş, banka, müşteri, tedarikçi, kamu ve kamu kuruluşlarına vb. gibi
              herhangi bir sınırlandırma olmaksızın bu hakkı ortak kullanıma açabilir.
              Bu haklar, yukarıda belirtilen telif, kullanım ve mülkiyet haklarını içerir ve Superlike tarafından rıza gösterilerek kullanılabilir.
            </p>
            <p className="mt-5">
              <b className="mr-5">4.2.</b>
              İçerik Üreticisi, Sözleşme kapsamında ürettiği içeriklerde yer alan kendisine ait görüntülerin hareketli ve/veya hareketsiz her türlü görüntünün Superlike tarafından başta Superlike’a ait sosyal medya hesaplarında olmak üzere madde 4.1. kapsamında kullanılmasına muvafakat etmektedir.
            </p>
            <p className="mt-5">
              <b className="mr-5">4.3.</b>
              İçerik Üreticisi, işbu Sözleşme kapsamında gerçekleştirilen/taahhüt edilen hak devirlerini gerçekleştirmeye münhasıran yetkili olduğunu, gerekmesi halinde üçüncü kişilerden devre ilişkin her türlü onay ve kabulü almış olduğunu, bu hak devirlerinin herhangi bir üçüncü kişinin haklarına halel getirmediğini ve Sözleşme kapsamında ürettiği içeriklerin herhangi bir üçüncü kişinin fikri mülkiyet ya da kişilik haklarını ihlal etmediğini taahhüt eder.
            </p>
            <p className="mt-5">
              <b className="mr-5">4.4.</b>
              Superlike markası ve logosu, Platform’un ve Uygulama’nın tasarımı, yazılımı, alan adı ve bunlara ilişkili olarak Superlike tarafından oluşturulan her türlü marka, tasarım, logo, şekil, slogan ve diğer tüm içeriğin her türlü fikri mülkiyet hakkı veya bunları kullanma yetkisi Superlike’a aittir. Platform’un ve Uygulama’nın tasarımında, içeriğinde ve veri tabanı oluşturulmasında kullanılan görsel, bilgi, yazılım ve sair içeriklerin kopyalanması ve/veya Platform’dan/Uygulama’dan faydalanmanın ötesinde kullanılması, Platform/Uygulama dahilinde bulunan her türlü video resim, metin, imge, dosya vb. verilerin kopyalanması, dağıtılması, işlenmesi ve sair şekillerde kullanılması kesinlikle yasaktır.
            </p>
          </div>

          <p className="mt-10 text-sm font-bold">
            5. SORUMLULUĞUN SINIRLANDIRILMASI
          </p>
          <div>
            <p className="mt-5">
              <b className="mr-5">5.1.</b>
              Superlike, Platform’u ve hizmetleri &quot;olduğu gibi&quot; sağlamakta olup Platform’un hatasız olduğu veya Platform üyeliğinin İçerik Üreticisi’nin kendine özel ihtiyaçlarını tamamen karşılayacağı şeklinde bir iddia veya taahhütte bulunmamaktadır. İşbu Sözleşme’nin herhangi bir hükmü, Superlike tarafından belirli sayıda etkinlik/işbirliği düzenleneceği, İçerik Üreticisi’nin bunlardan bir gelir elde edeceğine dair ya da sair herhangi bir taahhüt teşkil etmez.
            </p>
            <p className="mt-5">
              <b className="mr-5">5.2.</b>
              Superlike, İçerik Üreticisi’nin (i) Platform’a üye olarak Superlike tarafından düzenlenen etkinlik ve görevlere katılması, bunlara katılmaması veya Sözleşme’de belirtilen taahhüt ve yükümlülüklerini yerine getirmemesi sebebiyle ortaya çıkacak doğrudan veya dolaylı zararlardan, her türlü kar kaybı, veri kaybı, iş kaybı, itibar kaybı, beklenen tasarrufun kaybı, işin kesintiye uğraması gibi her türlü maddi, manevi ve mali zararlardan, üçüncü bir tarafça yapılabilecek tazminat taleplerinden doğacak zararlardan sorumlu değildir.
            </p>
            <p className="mt-5">
              <b className="mr-5">5.3.</b>
              Superlike, uygulanacak hukukun izin verdiği ölçüde, kar kaybı, şerefiye ve itibar kaybı dahil ancak bunlarla sınırlı olmaksızın Platform ve hizmetlerin kullanımı neticesinde meydana gelen hiçbir dolaylı, özel, arızi, cezai zarardan sorumlu olmayacaktır. Superlike, işbu Sözleşme kapsamında ticari elverişlilik, belli bir amaca veya kullanıma uygunluk veya ihlalin söz konusu olmamasına ilişkin olarak açık veya zımni herhangi bir taahhütte bulunmamaktadır. Superlike’ın işbu Sözleşme kapsamında İçerik Üreticisi nezdinde doğabilecek her türlü zarardan sorumluluğu her halükarda en fazla [30.000] TL ile sınırlı olacaktır.
            </p>
            <p className="mt-5">
              <b className="mr-5">5.4.</b>
              İçerik Üreticisi, Platform veya sair iletişim kanalları üzerinden sunulan içeriklere erişim ve bunların kalitesinin büyük ölçüde ilgili internet servis sağlayıcısından temin edilen hizmetin kalitesine dayandığını ve söz konusu hizmet kalitesinden kaynaklı sorunlarda Superlike’ın herhangi bir sorumluluğunun bulunmadığını, Platform’un ve sair iletişim kanallarının işleyişinin kusurdan ari olmadığını ve zaman zaman teknik aksaklıklar veya erişim engelleriyle karşılaşabileceğini bildiğini kabul ve beyan
            </p>
          </div>

          <p className="mt-10 text-sm font-bold">
            6. SÖZLEŞMENİN SÜRESİ VE FESHİ
          </p>
          <div>
            <p className="mt-5">
              <b className="mr-5">6.1.</b>
              Bu sözleşme İçerik Üreticisi tarafından kabulü ile birlikte yürürlüğe girer ve işbu Sözleşme’de belirtilen yöntemlerden biri kapsamında feshedilmediği sürece yürürlükte kalır.
            </p>
            <p className="mt-5">
              <b className="mr-5">6.2.</b>
              Taraflar, işbu Sözleşme’yi dilediği zaman yapacağı bildirimle herhangi bir gerekçe göstermeksizin tazminatsız olarak feshedebilir.
            </p>
            <p className="mt-5">
              <b className="mr-5">6.3.</b>
              Taraflar’dan birinin sözleşmesel yükümlülüklerini ihlal etmesi ve ihlalin düzeltilmesine ilişkin olarak karşı Tarafça iletilen talebe rağmen ilgili ihlalin 1 (bir) hafta içerisinde düzeltilmemesi halinde, işbu Sözleşme ihtarı gönderen Tarafça yazılı olarak iletilecek bir ihbarla derhal tazminat ödemeksizin feshedebilir.
            </p>
            <p className="mt-5">
              <b className="mr-5">6.4.</b>
              Sözleşme süresinin sona ermesi veya Sözleşme’nin herhangi bir nedenle feshi halinde İçerik Üreticisi’nin Platform’u kullanımına derhal son verilecektir.
            </p>
            <p className="mt-5">
              <b className="mr-5">6.5.</b>
              Sözleşme süresinin sona ermesi veya Sözleşme’nin herhangi bir nedenle feshi halinde Superlike İçerik Üreticisi tarafından üretilen tüm içerikleri kullanmaya devam edebilecektir.
            </p>
            <p className="mt-5">
              <b className="mr-5">6.6.</b>
              İçerik Üreticisi, işbu Sözleşme koşullarına aykırı davranması halinde Superlike’ın Platform üyeliğini ilgili aykırılık giderilene kadar askıya alabileceğini, bu kapsamda Superlike’dan herhangi bir talepte bulunamayacağını kabul ve beyan eder.
            </p>
          </div>

          <p className="mt-10 text-sm font-bold">7. SÖZLEŞME DEĞİŞİKLİKLERİ</p>
          <div>
            <p className="mt-5">
              <b className="mr-5">7.1.</b>
              {`Superlike, tamamen kendi takdirine bağlı olmak üzere, işbu Sözleşme’yi ve Platform üyeliği ile ilgili kural ve koşulları uygun gördüğü herhangi bir zamanda tek taraflı olarak değiştirebilir. İşbu Sözleşme'nin değişen hükümleri, değişikliğin yapıldığı tarihte geçerlilik kazanacak, geri kalan hükümler aynen yürürlükte kalarak hüküm ve sonuçlarını doğurmaya devam edecektir. Superlike’ın Platform’u veya Uygulama’yı yeniden organize etme, konu, kapsam ve içeriğini değiştirme, sağlanan herhangi bir hizmeti değiştirme ya da sona erdirme yayını durdurma hakkı saklıdır.`}
            </p>
          </div>

          <p className="mt-10 text-sm font-bold">8. MUHTELİF HÜKÜMLER</p>
          <div>
            <p className="mt-5">
              <b className="mr-5">8.1.</b>
              İşbu Sözleşme’nin akdedildiği tarihte var olmayan ve öngörülemeyen, Taraflar’ın veya tek bir Taraf’ın çalışma imkânlarını kısmen veya tamamen, geçici veya daimi olarak durduracak şekilde ve derecede meydana gelen beşeri ve doğal afetler, harp, seferberlik, yangın, grev, lokavt vb. gibi Taraflar’ın kontrolü haricinde zuhur eden haller mücbir sebep sayılır. Mücbir sebebe maruz kalan Taraf durumu derhal diğer Taraf’a yazılı olarak bildirir ve mücbir sebep süresi boyunca Taraflar’ın edimleri askıya alınır. Mücbir sebepler ortadan kalkınca Sözleşme kaldığı yerden devam eder. Mücbir sebep süresi boyunca hakları ifa edilmeyen Taraf’ın yükümlülükleri de askıya alınacaktır. Mücbir sebep halinin 30 (otuz) günden fazla sürmesi halinde, hakları ihlal edilen Taraf işbu Sözleşme’yi tazminatsız olarak feshedebilir.
            </p>
            <p className="mt-5">
              <b className="mr-5">8.2.</b>
              İçerik Üreticisi, işbu Sözleşme’yi ve Sözleşme’den doğan hak ve yükümlülüklerini Superlike’ın ön yazılı onayı olmaksızın üçüncü kişilere devir ve temlik edemez.
            </p>
            <p className="mt-5">
              <b className="mr-5">8.3.</b>
              Superlike, İçerik Üreticisi ile Platform üzerinden, Superlike’ın belirlediği iletişim kanalları üzerinden veya İçerik Üreticisi’nin üye olurken bildirdiği elektronik posta adresi veya telefon numarası vasıtasıyla iletişim kuracaktır. İçerik Üreticisi, elektronik posta adresini ve telefon numarasını güncel tutmakla ve Superlike tarafından bildirilen iletişim kanalları üzerinden yapılan bildirim ve duyuruları takip etmekle yükümlüdür.
            </p>
            <p className="mt-5">
              <b className="mr-5">8.4.</b>
              İşbu Sözleşme’nin esaslı olmayan hükümlerinden biri yahut birkaçının kısmen veya tamamen geçersiz addedilmesi, Sözleşme’nin kalan hükümlerinin geçerliliğine etki etmeyecektir.
            </p>
            <p className="mt-5">
              <b className="mr-5">8.5.</b>
              Taraflar arasındaki ihtilaflar Türk Hukukuna tabi olup, işbu Sözleşme’den doğabilecek ihtilafların hallinde İstanbul Çağlayan (Merkez) Mahkemeleri ve İcra Müdürlükleri yetkili olacaktır.
            </p>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};

export default Membership;
