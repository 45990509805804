import React from "react";
import { useWizard } from "react-use-wizard";

import StepBase from "../StepBase";

const Links = ({ ...props }) => {
  const { payload, setPayload } = props;
  const { previousStep, nextStep, isLastStep } = useWizard();

  // const isValidUrl = (url) => {
  //   try {
  //     return Boolean(new URL(url));
  //   } catch (e) {
  //     return false;
  //   }
  // };

  // const isUsernameValid = (text) => {
  //   if (text) {
  //     const isValidUsername = /^[a-zA-Z0-9._]{1,15}$/.test(text);
  //     const isUrl = isValidUrl(text);
  //     return isValidUsername && !isUrl;
  //   }
  //   return true;
  // };

  const children = () => {
    return (
      <div className="max-w-xs mx-auto">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Instagram
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg
                fill="currentColor"
                viewBox="0 0 24 24"
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
            <input
              type="text"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
              placeholder="Kullanıcı adını giriniz (örnek: superlike)"
              defaultValue={payload.social.instagram}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  social: {
                    ...payload.social,
                    instagram: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>

        <div className="mt-3">
          <label className="block text-sm font-medium text-gray-700">
            Tiktok
          </label>
          <div className="mt-1 relative rounded-md shadow-sm">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg
                fill="currentColor"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                className="h-6 w-6 text-gray-400"
                aria-hidden="true"
              >
                <path d="M 6 3 C 4.3550302 3 3 4.3550302 3 6 L 3 18 C 3 19.64497 4.3550302 21 6 21 L 18 21 C 19.64497 21 21 19.64497 21 18 L 21 6 C 21 4.3550302 19.64497 3 18 3 L 6 3 z M 6 5 L 18 5 C 18.56503 5 19 5.4349698 19 6 L 19 18 C 19 18.56503 18.56503 19 18 19 L 6 19 C 5.4349698 19 5 18.56503 5 18 L 5 6 C 5 5.4349698 5.4349698 5 6 5 z M 12 7 L 12 14 C 12 14.56503 11.56503 15 11 15 C 10.43497 15 10 14.56503 10 14 C 10 13.43497 10.43497 13 11 13 L 11 11 C 9.3550302 11 8 12.35503 8 14 C 8 15.64497 9.3550302 17 11 17 C 12.64497 17 14 15.64497 14 14 L 14 10.232422 C 14.616148 10.671342 15.259118 11 16 11 L 16 9 C 15.952667 9 15.262674 8.7809373 14.78125 8.3613281 C 14.299826 7.941719 14 7.4149911 14 7 L 12 7 z"></path>
              </svg>
            </div>
            <input
              type="text"
              className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
              placeholder="Kullanıcı adını giriniz (örnek: superlike)"
              defaultValue={payload.social.tiktok}
              onChange={(e) =>
                setPayload({
                  ...payload,
                  social: {
                    ...payload.social,
                    tiktok: e.target.value,
                  },
                })
              }
            />
          </div>
        </div>

        {/* {(!isUsernameValid(payload.social.instagram) ||
          !isUsernameValid(payload.social.tiktok)) && (
          <p className="mt-5 bg-red-100 text-red-900 p-3 rounded-xl text-sm">
            Kullanıcı adınız yalnızca alfa numerik olmalıdır.
          </p>
        )} */}

        <p className="mt-5 bg-yellow-100 text-yellow-800 p-3 rounded-xl text-sm">
          Kullanıcı adı bölümünde, <b>link</b> veya <b>{"@"}</b> ile başlamayan
          yalnızca kullanıcı adınızı giriniz.
        </p>
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Sosyal Medya Hesapları"}
        subTitle={""}
        previousStep={previousStep}
        nextStep={nextStep}
        children={children()}
        disableNextStep={
          // !isUsernameValid(payload.social.instagram) ||
          // !isUsernameValid(payload.social.tiktok) ||
          !payload.social.instagram
        }
        isLastStep={isLastStep}
      />
    </>
  );
};

export default Links;
