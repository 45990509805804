import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Supeerlike from "../Supeerlike/Supeerlike";
import { useAuth } from "../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";

export default function Login({ ...props }) {
  const { open, setOpen } = props;
  const { login } = useAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const handleLogin = async () => {
    setLoader(true)
    const result = await login(email, password);

    if (result.succeed) {
      const user = JSON.parse(localStorage.getItem("user"));
      setEmail("");
      setPassword("");
      setErrorMessage()
      setOpen(!open);
      setLoader(false)

      if (user) {
        navigate(`/creators/${user.username}`);
      }
    } else {
      setErrorMessage(result.message);
      setLoader(false)
    }
  };

  const handleCloseModal = () => {
    setErrorMessage()
    setOpen(!open)
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleCloseModal}
      >
        <div className="flex items-start justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-60 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle w-full lg:max-w-md sm:p-6">
              <div>
                <span className="flex justify-center">{Supeerlike()}</span>

                <div className="mt-3 sm:mt-5">
                  <Dialog.Title
                    as="h3"
                    className="text-2xl text-center leading-6 font-bold text-gray-900"
                  >
                    {"Hesabına Giriş Yap"}
                  </Dialog.Title>
                  <div className="mt-5">
                    <div>
                      <div>
                        <label
                          htmlFor="email"
                          className="ml-px pl-4 block text-sm font-medium text-gray-700"
                        >
                          Email
                        </label>
                        <div className="mt-1">
                          <input
                            type="email"
                            name="email"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="password"
                          className="mt-3 ml-px pl-4 block text-sm font-medium text-gray-700"
                        >
                          Parola
                        </label>
                        <div className="mt-1">
                          <input
                            type="password"
                            name="password"
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 px-4 rounded-full"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="mt-2 flex items-center justify-between">
                        <div className="text-sm">
                          <a
                            href="#"
                            className="font-medium text-indigo-600 hover:text-indigo-500"
                          >
                            Parolamı unuttum?
                          </a>
                        </div>
                      </div>
                    </div>

                    <div className="mt-5">
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        onClick={() => handleLogin()}
                      >
                        {loader ? "Giriş Yapılıyor.." : "Giriş Yap"}
                      </button>
                      {errorMessage && <p className="text-gray-600 mt-2 text-center">{errorMessage} </p>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
