import React from "react";
import Sidebar from "../components/Sidebar/index";

export default function AdminLayout({ children }) {
  return (
    <Sidebar>
      <div className="py-8 px-4 sm:px-6 md:px-8">
        <div className="px-4 sm:px-6 lg:px-8">{children}</div>
      </div>
    </Sidebar>
  );
}
