import { Fragment, useState, useEffect } from "react";
import {
  VideoCameraIcon,
  CubeTransparentIcon,
  UserIcon,
  PencilIcon,
  SearchIcon,
  SparklesIcon,
  ChatIcon,
  FlagIcon,
  LightBulbIcon,
} from "@heroicons/react/solid";
import { Menu, Dialog, Popover, Tab, Transition } from "@headlessui/react";
import { MenuIcon, XIcon, ArrowLeftIcon } from "@heroicons/react/outline";
import { useNavigate } from "react-router-dom";
import Login from "../Login/Login";
import { useAuth } from "../../contexts/AuthContext";
import logo from "../../assets/logo/supeerlike_new_logo.png";
import settings from "../../config";
import Search from "../Search/Search";
import { classNames } from "../../utils";

const category = {
  id: "women",
  name: "Women",
  sections: [
    [
      {
        id: "shoes",
        name: "",
        items: [
          { name: "Markalar", href: "/brands", color: "purple" },
          { name: "İçerikler", href: "/videos", color: "yellow" },
          { name: "Ürünler", href: "/products", color: "green" },
          { name: "Etkinlikler", href: "/events", color: "purple" },
          { name: "Sıkça Sorulan Sorular", href: "/faq", color: "gray" },
        ],
      },
      // {
      //   id: "collection",
      //   name: "Shop Collection",
      //   items: [
      //     { name: "Everything", href: "#" },
      //     { name: "Core", href: "#" },
      //     { name: "New Arrivals", href: "#" },
      //     { name: "Sale", href: "#" },
      //     { name: "Accessories", href: "#" },
      //   ],
      // },
    ],
    [
      {
        id: "clothing",
        name: "",
        items: [
          { name: "Kozmetik", href: "#" },
          { name: "Moda", href: "#" },
          { name: "Aksesuar", href: "#" },
        ],
      },
      // {
      //   id: "accessories",
      //   name: "All Accessories",
      //   items: [
      //     { name: "Watches", href: "#" },
      //     { name: "Wallets", href: "#" },
      //     { name: "Bags", href: "#" },
      //     { name: "Sunglasses", href: "#" },
      //     { name: "Hats", href: "#" },
      //     { name: "Belts", href: "#" },
      //   ],
      // },
    ],
    [
      {
        id: "brands",
        name: "",
        items: [
          {
            name: "Superlike’ta nano, micro, makro ve skinfluencer gibi bir çok influencer yer alıyor. Eğer sende içerik üretmek ve ürettiğin içerikten gelir elde etmek istiyorsan sende hemen kaydol, kazanmaya başla!",
            href: "#",
          },
          { name: "Instagram", href: "https://instagram.com/supeerlike" },
          { name: "Tiktok", href: "https://tiktok.com/@supeerlike" },
          {
            name: "Linkedin",
            href: "https://www.linkedin.com/company/supeerlike/",
          },
          { name: "hello@supeerlike.com", href: "mailto:hello@supeerlike.com" },
        ],
      },
    ],
  ],
};

export default function Header({ bgColor = "white" }) {
  const [open, setOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const navigate = useNavigate();
  const { currentUser, userInfo } = useAuth();
  const handleSignUpButton = () => {
    setOpenLogin(!openLogin);
  };
  const [userData, setUserData] = useState();

  useEffect(() => {
    const localData = localStorage.getItem("user");
    const cachedData =
      localData != null && localData !== "undefined"
        ? JSON.parse(localData)
        : null;
    const data = userInfo || cachedData;
    setUserData(data);
  }, []);

  return (
    <div className={`bg-[${bgColor}]`}>
      {/* Mobile menu */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative w-full bg-white shadow-xl pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 pt-5 pb-2 flex">
                <button
                  type="button"
                  className="-m-2 p-2 rounded-md inline-flex items-center justify-center text-gray-400"
                  onClick={() => setOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Links */}
              <Tab.Group as="div" className="mt-2">
                <div className="border-b border-gray-200">
                  <Tab.List className="-mb-px flex px-4 space-x-8">
                    {/* {navigation.categories.map((category) => (
                      <Tab
                        key={category.name}
                        className={({ selected }) =>
                          classNames(
                            selected
                              ? "text-indigo-600 border-indigo-600"
                              : "text-gray-900 border-transparent",
                            "flex-1 whitespace-nowrap py-4 px-1 border-b-2 text-base font-medium"
                          )
                        }
                      >
                        {category.name}
                      </Tab>
                    ))} */}
                  </Tab.List>
                </div>
                <Tab.Panels as={Fragment}>
                  <Tab.Panel
                    key={category.name}
                    className="pt-10 pb-8 px-4 space-y-10"
                  >
                    {/* <div className="space-y-4">
                      {category.featured.map((item, itemIdx) => (
                        <div
                          key={itemIdx}
                          className="group relative aspect-w-1 aspect-h-1 rounded-md bg-gray-100 overflow-hidden"
                        >
                          <div className="flex flex-col justify-end">
                            <div className="p-4 bg-white bg-opacity-60 text-base sm:text-sm">
                              <a
                                href={item.href}
                                className="font-medium text-gray-900"
                              >
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                />
                                {item.name}
                              </a>
                              <p
                                aria-hidden="true"
                                className="mt-0.5 text-gray-700 sm:mt-1"
                              >
                                Shop now
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div> */}
                    {category.sections.map((column, columnIdx) => (
                      <div key={columnIdx} className="space-y-10">
                        {column.map((section) => (
                          <div key={section.name}>
                            <p
                              id={`${category.id}-${section.id}-heading-mobile`}
                              className="font-medium text-gray-900"
                            >
                              {section.name}
                            </p>
                            <ul
                              role="list"
                              aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                              className="mt-6 flex flex-col space-y-6"
                            >
                              {section.items.map((item) => (
                                <li key={item.name} className="flow-root">
                                  <a
                                    href={item.href}
                                    className="-m-2 p-2 block text-gray-500"
                                  >
                                    {item.color ? (
                                      <span
                                        className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-${item.color}-100 text-${item.color}-800`}
                                      >
                                        {item.name}
                                      </span>
                                    ) : (
                                      item.name
                                    )}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    ))}
                  </Tab.Panel>
                </Tab.Panels>
              </Tab.Group>
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <header className={`relative bg-[${bgColor}]`}>
        <nav
          aria-label="Top"
          className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8"
        >
          {/* border-b border-gray-200 */}
          <div>
            <div className="h-16 flex items-center justify-between">
              <div className="flex-1 flex items-center lg:hidden">
                <button
                  type="button"
                  className="-ml-2 bg-white p-2 rounded-md text-gray-400"
                  onClick={() => setOpen(true)}
                >
                  <span className="sr-only">Open menu</span>
                  <MenuIcon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* <a
                  href="#"
                  className="ml-2 p-2 text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">Search</span>
                  <SearchIcon className="w-6 h-6" aria-hidden="true" />
                </a> */}
              </div>

              {/* Flyout menus */}
              <Popover.Group className="hidden lg:flex-1 z-40 lg:block lg:self-stretch">
                <div className="h-full flex space-x-8">
                  <Popover className="flex">
                    {({ open }) => (
                      <>
                        <div className="relative flex">
                          <Popover.Button
                            className={classNames(
                              // open
                              //   ? "text-indigo-600"
                              //   : "text-gray-700 hover:text-gray-800",
                              "relative z-10 flex items-center justify-center transition-colors ease-out duration-200 text-sm font-medium"
                            )}
                          >
                            <a
                              href="#"
                              // type="button"
                              className="ml-2 p-2 text-gray-400 hover:text-gray-500"
                            >
                              <span className="sr-only">Open menu</span>
                              <MenuIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </a>
                          </Popover.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Popover.Panel className="absolute top-full inset-x-0">
                            {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                            <div
                              className="absolute inset-0 top-1/2 bg-white shadow"
                              aria-hidden="true"
                            />

                            <div className="relative bg-gray-50">
                              <div className="max-w-7xl mx-auto px-8">
                                <div className="gap-y-10 gap-x-8 py-16">
                                  <div className="grid grid-cols-3 gap-y-10 gap-x-8 text-sm text-gray-500">
                                    {category.sections.map(
                                      (column, columnIdx) => (
                                        <div
                                          key={columnIdx}
                                          className="space-y-10"
                                        >
                                          {column.map((section) => (
                                            <div key={section.name}>
                                              <p
                                                id={`${category.id}-${section.id}-heading`}
                                                className="font-medium text-gray-900"
                                              >
                                                {section.name}
                                              </p>
                                              <ul
                                                role="list"
                                                aria-labelledby={`${category.id}-${section.id}-heading`}
                                                className="mt-4 space-y-4"
                                              >
                                                {section.items.map((item) => (
                                                  <li
                                                    key={item.name}
                                                    className="flex"
                                                  >
                                                    <a
                                                      href={item.href}
                                                      className="hover:text-gray-800"
                                                    >
                                                      {item.color ? (
                                                        <span
                                                          className={`inline-flex items-center px-3 py-0.5 rounded-full text-sm font-medium bg-${item.color}-100 text-${item.color}-800`}
                                                        >
                                                          {item.name}
                                                        </span>
                                                      ) : (
                                                        item.name
                                                      )}
                                                    </a>
                                                  </li>
                                                ))}
                                              </ul>
                                            </div>
                                          ))}
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
              </Popover.Group>

              {/* logo */}

              <a href="/" className="flex">
                <span className="sr-only">Superlike</span>
                <img className="h-10 sm:h-14 w-auto" src={logo} alt="" />
              </a>

              {/* <span className="text-2xl font-extrabold text-transparent bg-clip-text bg-gradient-to-r from-purple-700 to-pink-600 mix-blend-multiply">
                SUPEERLIKE
              </span> */}

              {/* <span className="text-2xl font-extrabold">SUPEERLIKE</span> */}

              <div className="flex-1 flex items-center justify-end">
                {/* Search */}

                <a
                  href="#"
                  onClick={() => setSearchOpen(!searchOpen)}
                  className="p-1 text-gray-400 hover:text-gray-500 lg:block"
                >
                  <span className="sr-only">Search</span>
                  <SearchIcon className="w-6 h-6" aria-hidden="true" />
                </a>

                {!currentUser ? (
                  <a
                    href="#"
                    onClick={() => handleSignUpButton()}
                    className="p-1 text-gray-400 hover:text-gray-500 lg:block"
                  >
                    {/* <span className="sr-only">Search</span> */}
                    {/* <UserIcon className="w-6 h-6" aria-hidden="true" /> */}
                    <span>Giriş</span>
                  </a>
                ) : (
                  <div className="p-2 text-gray-400 hover:text-gray-500 lg:ml-4">
                    {userData ? (
                      <>
                        <Menu
                          as="div"
                          className="relative inline-block text-left z-40"
                        >
                          <Menu.Button className="rounded-full float-right text-gray-400 hover:text-gray-600">
                            <span className="sr-only">Open options</span>
                            <img
                              className="inline-block h-10 w-10 rounded-full object-cover"
                              src={userData.media.image.url}
                              alt={userData.username}
                            />
                          </Menu.Button>

                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div className="py-1">
                                {userInfo && userInfo.role === "admin" && (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <a
                                        className={classNames(
                                          active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                          "group flex items-center px-4 py-2 text-sm cursor-pointer"
                                        )}
                                        onClick={() =>
                                          navigate("/admin/dashboard")
                                        }
                                      >
                                        <CubeTransparentIcon
                                          className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                          aria-hidden="true"
                                        />
                                        Admin Dashboard
                                      </a>
                                    )}
                                  </Menu.Item>
                                )}
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                                      )}
                                      onClick={() =>
                                        navigate("/update-profile")
                                      }
                                    >
                                      <PencilIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Hesap Bilgileri
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                                      )}
                                      onClick={() =>
                                        navigate(`/creators/${userData.username}`)
                                      }
                                    >
                                      <UserIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Profili Gör
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                                      )}
                                      onClick={() =>
                                        navigate("/content-wizard")
                                      }
                                    >
                                      <VideoCameraIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      İçerik Yükle
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                                      )}
                                      onClick={() => navigate("/leaderboard")}
                                    >
                                      <SparklesIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Leaderboard
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                                      )}
                                      onClick={() => navigate("/support")}
                                    >
                                      <LightBulbIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Destek
                                    </a>
                                  )}
                                </Menu.Item>
                                <Menu.Item>
                                  {({ active }) => (
                                    <a
                                      href="/logout"
                                      className={classNames(
                                        active
                                          ? "bg-gray-100 text-gray-900"
                                          : "text-gray-700",
                                        "group flex items-center px-4 py-2 text-sm cursor-pointer"
                                      )}
                                    >
                                      <ArrowLeftIcon
                                        className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                        aria-hidden="true"
                                      />
                                      Çıkış Yap
                                    </a>
                                  )}
                                </Menu.Item>
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                      </>
                    ) : (
                      <a
                        href="#"
                        onClick={() => handleSignUpButton()}
                        className="p-1 text-gray-400 hover:text-gray-500 lg:block"
                      >
                        <span>Giriş</span>
                      </a>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>

      <Login open={openLogin} setOpen={setOpenLogin} />
      <Search open={searchOpen} setOpen={setSearchOpen} />
    </div>
  );
}
