import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";

const RejectReason = ({ ...props }) => {
  const { payload } = props;
  const { previousStep, isLastStep, nextStep } = useWizard();

  const children = () => {
    return (
      <div className="text-center">
        <p>{payload.rejectedReason}</p>
      </div>
    );
  };

  return (
    <>
      <StepBase
        title={"Revize Nedeni"}
        subTitle={"Bazı geri bildirimlerimiz var 😇"}
        disablePreviousStep={true}
        previousStep={previousStep}
        nextStep={nextStep}
        children={children()}
        isLastStep={isLastStep}
      />
    </>
  );
};

export default RejectReason;
