import React, { useState, useEffect, useRef } from "react";
import { Wizard } from "react-use-wizard";
import AppLayout from "../../../layouts/AppLayout";
import Header from "./Header";
import { useAuth } from "../../../contexts/AuthContext";
import { db } from "../../../firebase";
import { doc, setDoc } from "firebase/firestore";
import RejectReason from "./Steps/RejectReason";
import CoverImage from "./Steps/CoverImage";
import Video from "./Steps/Video";
import DescriptionAndLink from "./Steps/DescriptionAndLink";
import Final from "./Steps/Final";
import { storageUpload, normalizedText } from "../../../utils";
import { useNavigate } from "react-router-dom";

export default function UpdateContentWizard({ model, setRevisedOpen }) {
  const { userInfo } = useAuth();
  const navigate = useNavigate();
  const [coverImage, setCoverImage] = useState(null);
  const [coverImagePreview, setCoverImagePreview] = useState(
    model.media.image.url
  );
  const [video, setVideo] = useState(null);
  const [videoPreview, setVideoPreview] = useState(model.media.video.url);
  const [loader, setLoader] = useState(false);
  const [payload, setPayload] = useState(model);

  const handleSubmit = async () => {
    setLoader(!loader);

    try {
      const docRef = doc(db, "videos", model.id);

      payload.modified_at = Math.floor(Date.now() / 1000);
      payload.media = {
        image: coverImage
          ? await storageUpload(coverImage, "cover-images")
          : payload.media.image,
        video: video
          ? await storageUpload(video, "videos")
          : payload.media.video,
      };

      const search = {
        brand: normalizedText(payload.brand),
        productDescription: normalizedText(payload.productDescription),
        username: normalizedText(payload.username),
        videoDescription: normalizedText(payload.videoDescription),
      };
      search.all = Object.values(search).join(" ");
      payload.search = search;

      await setDoc(docRef, payload, { merge: true });

      setLoader(!loader);
      setRevisedOpen(false);
      navigate(`/creators/${userInfo.username}`);
    } catch (e) {
      console.error("Error adding document: ", e);
      setLoader(!loader);
    }
  };

  return (
    userInfo && (
      <div>
        <div className="mt-5 mb-10 px-4 py-5 sm:p-6">
          <Wizard header={<Header />}>
            <RejectReason payload={payload} />
            <CoverImage
              coverImage={coverImage}
              setCoverImage={setCoverImage}
              coverImagePreview={coverImagePreview}
              setCoverImagePreview={setCoverImagePreview}
            />
            <Video
              video={video}
              setVideo={setVideo}
              videoPreview={videoPreview}
              setVideoPreview={setVideoPreview}
            />
            <DescriptionAndLink
              payload={payload}
              setPayload={setPayload}
              loader={loader}
              handleSubmit={handleSubmit}
            />
            <Final loader={loader} />
          </Wizard>
        </div>
      </div>
    )
  );
}
