import React from "react";
import { classNames } from "../../../utils";

export default function History({ ...props }) {
  const { tags, brands, data, setModel, setOpen } = props;

  const handleOpenModel = (item) => {
    setModel(item);
    setOpen(true);
  };

  const getBrandName = (brandId) => {
    const brand = brands.find((x) => x.id === brandId);
    return brand ? brand.name : "";
  };

  const getTagNames = (tagIds) => {
    const names = [];
    tagIds.map((tag) => {
      const entity = tags.find((x) => x.id === tag);
      if (entity) {
        names.push(entity.name);
      }
    });
    return names.join(", ");
  };

  return (
    <table className="min-w-full divide-y divide-gray-300">
      <thead>
        <tr>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
          >
            #
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
          >
            Uploaded Images
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
          >
            Brand
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
          >
            Tags
          </th>
          <th
            scope="col"
            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
          >
            Name
          </th>
          <th
            scope="col"
            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
          >
            Visible
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((item, key) => (
          <tr
            key={key}
            className="cursor-pointer hover:bg-gray-50"
            onClick={() => handleOpenModel(item)}
          >
            <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
              {key + 1}
            </td>
            <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
              {item.media && item.media.images ? item.media.images.length : 0}
            </td>
            <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
              {getBrandName(item.brandId)}
            </td>
            <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
              {getTagNames(item.tags)}
            </td>
            <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
              {item.name}
            </td>
            <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
              <span
                className={classNames(
                  "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                  item.visible
                    ? "bg-green-100 text-green-800"
                    : "bg-red-100 text-red-800"
                )}
              >
                {item.visible ? "Active" : "Inactive"}
              </span>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
