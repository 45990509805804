import { Fragment, useState } from "react";
import { SearchIcon } from "@heroicons/react/solid";
import {
  CloudIcon,
  EmojiSadIcon,
  GlobeIcon,
  PencilAltIcon,
  SearchCircleIcon,
} from "@heroicons/react/outline";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { classNames } from "../../utils";
import {
  collection,
  query,
  getDocs,
  where,
  orderBy,
  limit,
  startAt,
  endAt,
} from "firebase/firestore";
import { db } from "../../firebase";
import { normalizedText } from "../../utils";
import { useNavigate } from "react-router-dom";

export default function Search({ open, setOpen }) {
  const [searchKey, setSearchKey] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();

  const handleSearch = async () => {
    setSearchResult([]);
    let keyword = normalizedText(searchKey);

    if (searchKey.length >= 3) {
      setLoader(true);

      const eventResult = [];
      const creatorResult = [];
      const brandResult = [];
      const productResult = [];

      // Events
      const eventsQuery = query(
        collection(db, "events"),
        where("active", "==", true),
        orderBy("search.all"),
        startAt(keyword),
        endAt(keyword + "\uf8ff"),
        limit(3)
      );
      const eventsSnapshot = await getDocs(eventsQuery);
      eventsSnapshot.forEach((doc) => {
        const event = { ...doc.data(), id: doc.id };

        if (event.constraints.startAt <= Math.floor(Date.now() / 1000)) {
          eventResult.push({
            title: event.title,
            subTitle: event.short,
            imageUrl: event.media.image.url,
            url: `/event/${event.id}`,
          });
        }
      });

      // Influencers
      const creatorsByUsernameQuery = query(
        collection(db, "users"),
        orderBy("search.all"),
        startAt(keyword),
        endAt(keyword + "\uf8ff"),
        limit(3)
      );
      const creatorsByUsernameSnapshot = await getDocs(creatorsByUsernameQuery);
      creatorsByUsernameSnapshot.forEach((doc) => {
        const creator = { ...doc.data(), id: doc.id };
        creatorResult.push({
          title: `@${creator.username}`,
          subTitle: creator.personal.fullname,
          imageUrl: creator.media.image.url,
          url: `/creators/${creator.username}`,
        });
      });

      // Brands
      const brandQuery = query(
        collection(db, "brands"),
        orderBy("search.name"),
        startAt(keyword),
        endAt(keyword + "\uf8ff"),
        limit(3)
      );
      const brandSnapshot = await getDocs(brandQuery);
      brandSnapshot.forEach((doc) => {
        const brand = { ...doc.data(), id: doc.id };
        brandResult.push({
          title: brand.name,
          subTitle: brand.description,
          imageUrl: brand.media.logo.url,
          url: `/brand/${brand.id}`,
        });
      });

      // Products
      const productQuery = query(
        collection(db, "products"),
        orderBy("search.name"),
        startAt(keyword),
        endAt(keyword + "\uf8ff"),
        limit(3)
      );
      const productSnapshot = await getDocs(productQuery);
      productSnapshot.forEach((doc) => {
        const product = { ...doc.data(), id: doc.id };
        productResult.push({
          title: product.name,
          subTitle: product.description,
          imageUrl:
            product.media &&
            product.media.images &&
            product.media.images.length > 0
              ? product.media.images[0].url
              : null,
          url: `/product/${product.id}`,
        });
      });

      if (eventResult.length > 0) {
        setSearchResult((prev) => [
          ...prev,
          { category: "Etkinlikler", items: eventResult },
        ]);
      }

      if (creatorResult.length > 0) {
        setSearchResult((prev) => [
          ...prev,
          { category: "Influencer", items: creatorResult },
        ]);
      }

      if (brandResult.length > 0) {
        setSearchResult((prev) => [
          ...prev,
          { category: "Markalar", items: brandResult },
        ]);
      }

      if (productResult.length > 0) {
        setSearchResult((prev) => [
          ...prev,
          { category: "Ürünler", items: productResult },
        ]);
      }
      setLoader(false);
    }
  };

  const handleClear = () => {
    setSearchKey("");
    setSearchResult([]);
  };

  return (
    <Transition.Root show={open} as={Fragment} afterLeave={handleClear}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 top-10 overflow-y-auto p-4 sm:p-6 md:p-20"
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0 bg-gray-700 bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Combobox
            as="div"
            className="mx-auto max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all"
            // onChange={(item) => (window.location = item.url)}
          >
            <div className="relative">
              <SearchIcon
                className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <Combobox.Input
                className="h-12 w-full border-0 bg-transparent pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:ring-0 sm:text-sm"
                placeholder={
                  searchKey || "Ne arıyorsun? Senin için hemen bulalım!"
                }
                onChange={(event) => setSearchKey(event.target.value)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
            </div>

            {searchKey === "" && (
              <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                <GlobeIcon
                  className="mx-auto h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <p className="mt-4 font-semibold text-gray-900">
                  Influencer, marka, ürün, blog ve daha fazlası
                </p>
                <p className="mt-2 text-gray-500">
                  Hızlıca erişmek istediğin ne varsa kolayca arama yapabilirsin!
                </p>
              </div>
            )}

            {searchResult.length > 0 && (
              <Combobox.Options
                static
                className="max-h-80 scroll-pt-11 scroll-pb-2 space-y-2 overflow-y-auto pb-2"
              >
                {searchResult.map((result, key) => (
                  <li key={key}>
                    <h2 className="bg-gray-100 py-2.5 px-4 text-xs font-semibold text-gray-900">
                      {result.category}
                    </h2>
                    <ul className="mt-2 text-sm text-gray-800">
                      {result.items.map((item, itemKey) => (
                        <Combobox.Option
                          key={itemKey}
                          value={item}
                          className={({ active }) =>
                            classNames(
                              "flex select-none rounded-xl p-3 cursor-pointer",
                              active && "bg-gray-100"
                            )
                          }
                          onClick={() => (window.location.href = item.url)}
                        >
                          {({ active }) => (
                            <div className="flex justify-center items-center">
                              <img
                                src={item.imageUrl}
                                className="w-20 h-20 object-cover rounded-full"
                              />

                              <div className="ml-4 flex-auto">
                                <p
                                  className={classNames(
                                    "text-sm font-medium",
                                    active ? "text-gray-900" : "text-gray-700"
                                  )}
                                >
                                  {item.title}
                                </p>
                                <p
                                  className={classNames(
                                    "text-sm",
                                    active ? "text-gray-700" : "text-gray-500"
                                  )}
                                >
                                  {item.subTitle}
                                </p>
                              </div>
                            </div>
                          )}
                        </Combobox.Option>
                      ))}
                    </ul>
                  </li>
                ))}
              </Combobox.Options>
            )}

            {searchKey !== "" && searchResult.length === 0 && !loader && (
              <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                <EmojiSadIcon
                  className="mx-auto h-6 w-6 text-gray-400"
                  aria-hidden="true"
                />
                <p className="mt-4 font-semibold text-gray-900">
                  Sonuç bulunamadı 🥺
                </p>
                <p className="mt-2 text-gray-500">
                  Üzgünüz, aradığınızı bulamadık, Lütfen tekrar deneyin.
                </p>
              </div>
            )}

            {searchKey !== "" && loader && (
              <div className="border-t border-gray-100 py-14 px-6 text-center text-sm sm:px-14">
                <CloudIcon
                  className="mx-auto h-10 w-10 text-purple-400"
                  aria-hidden="true"
                />
                <p className="mt-4 font-semibold text-gray-900">
                  Lütfen bekleyin, sonuçları getiriyoruz 👀
                </p>
              </div>
            )}
          </Combobox>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
  );
}

// <Combobox.Option
//   key={itemKey}
//   value={item}
//   className={({ active }) =>
//     classNames(
//       "cursor-default select-none px-4 py-2",
//       active && "bg-indigo-600 text-white"
//     )
//   }
// >
//   {item.name}
// </Combobox.Option>
