import { useWizard } from "react-use-wizard";
import StepBase from "../StepBase";
import { useNavigate } from "react-router-dom";
import settings from "../../../config";
import videoCamera from "../../../lotties/videoCamera";
import Lottie from "react-lottie";

const Final = ({ ...props }) => {
  const { payload } = props;
  const { isLastStep } = useWizard();
  const navigate = useNavigate();

  const defaultOptions = {
    loop: true,
    // autoplay: true,
    animationData: videoCamera,
    // rendererSettings: {
    //   preserveAspectRatio: "xMidYMid",
    // },
  };

  const children = () => {
    return (
      <>
        <div className="bg-gradient-to-r from-[#9a6bff] to-[#7f50f0] m-auto p-8 rounded-xl">
          <div className="max-w-2xl m-auto">
            <h2 className="text-white text-4xl font-semibold text-center">
              {"Artık sende bir Superlikersın!"}
            </h2>
            <h4 className="text-white text-sm font-medium text-center mt-1">
              {
                "Hemen daha önce ki ürettiğin içerikleri yükle, marka beğendiğinde kazanmaya başla!"
              }
            </h4>
          </div>
          <div className="mt-10 flex justify-center items-center gap-4">
            <a
              href="#"
              onClick={() => navigate("/events")}
              className="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-indigo-800 bg-indigo-50 hover:bg-indigo-100"
            >
              <span className="ml-1">Hemen İçerik Yükle</span>
            </a>
          </div>

          <div className="w-full">
            <Lottie options={defaultOptions} height={300} width={300} />
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <StepBase
        // title={"Harika! 🎉🎉"}
        // previousStep={previousStep}
        // nextStep={handleSubmit}
        children={children()}
        isLastStep={isLastStep}
        disableNextStep={true}
      />
    </>
  );
};

export default Final;
