import React from "react";
import { classNames, friendlyDateTime } from "../../../utils";

export default function History({ ...props }) {
  const { events, products, users, brands, data, setModel, setOpen } = props;
  const handleOpenModel = (item) => {
    setModel(item);
    setOpen(true);
  };

  const getBrandName = (brandId) => {
    const brand = brands.find((x) => x.id === brandId);
    return brand ? brand.name : "";
  };

  const getUserScore = (userId) => {
    const user = users.find((x) => x.uid === userId);
    if (user) {
      return user.scoring ? user.scoring.score : 0;
    }

    return 0;
  };

  const getProductName = (productId) => {
    const product = products.find((x) => x.id === productId);
    return product ? product.name : "";
  };

  const getEventName = (eventId) => {
    const event = events.find((x) => x.id === eventId);
    return event ? `${event.earningType} - ${event.title}` : "";
  };

  return (
    <div className="overflow-x-auto -mx-4 mt-10 ring-1 ring-gray-300 sm:-mx-6 md:mx-0 rounded-lg">
      <table className="min-w-full divide-y divide-gray-300">
        <thead>
          <tr>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              #
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              User
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              XP
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Confirmed
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Upload At
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Event
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Product
            </th>
            <th
              scope="col"
              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
            >
              Landing Order
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, key) => (
            <tr
              key={key}
              className="cursor-pointer hover:bg-gray-50"
              onClick={() => handleOpenModel(item)}
            >
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.rowNumber}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {"@"}
                {item.username}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {getUserScore(item.uid)}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                <span
                  className={classNames(
                    "inline-flex rounded-full px-2 text-xs font-semibold leading-5",
                    item.is_confirmed
                      ? "bg-green-100 text-green-800"
                      : "bg-red-100 text-red-800"
                  )}
                >
                  {item.is_confirmed ? "Yes" : "No"}
                </span>
                {item.is_rejected ? (
                  <span className="ml-1 inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-red-100 text-red-800">
                    Rejected
                  </span>
                ) : null}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {friendlyDateTime(item.created_at)}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {getEventName(item.eventId)}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {getProductName(item.productId)}
              </td>
              <td className="border-t border-gray-200 whitespace-nowrap px-3 py-3.5 text-sm text-gray-500 lg:table-cell">
                {item.landingOrder && item.landingOrder > 0 ? item.landingOrder : null}
              </td>

            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
